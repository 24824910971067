import { ChangeDetectionStrategy, Component, effect, Input, ViewEncapsulation } from '@angular/core'
import { ThemeService } from '../../../core/services/theme.service'
import { ScrollerComponent, ScrollerItem } from '../scroller/scroller.component';
import { RouterLink } from '@angular/router';

@Component({
    selector: 'app-hero',
    templateUrl: './hero.component.html',
    styleUrl: './hero.component.scss',
    encapsulation: ViewEncapsulation.None,
    standalone: true,
    imports: [ScrollerComponent, RouterLink],
})
export class HeroComponent {
    @Input()
    page = '';

    currentTheme: string = 'dark'

    partnerLogosTop : ScrollerItem[] = [
        {
            src: 'assets/img/partner-logos/ko-logo.webp',
            alt: 'Autohaus König'
        },
        {
            src: 'assets/img/partner-logos/ef-logo.webp',
            alt: 'Emil Frey'
        },
        {
            src: 'assets/img/partner-logos/ro-logo.webp',
            alt: 'Rosier'
        },
        {
            src: 'assets/img/partner-logos/ha-logo.webp',
            alt: 'Hackerott'
        },
        {
            src: 'assets/img/partner-logos/jg-logo.webp',
            alt: 'Jacobs Gruppe'
        },
        {
            src: 'assets/img/partner-logos/fg-logo.webp',
            alt: 'Feser Graf'
        }
    ]

    partnerLogosBottom : ScrollerItem[] = [
        {
            src: 'assets/img/partner-logos/gi-logo.webp',
            alt: 'Giga'
        },
        {
            src: 'assets/img/partner-logos/bi-logo.webp',
            alt: 'Business Insider'
        },
        {
            src: 'assets/img/partner-logos/gl-logo.webp',
            alt: 'GoLeasy'
        },
        {
            src: 'assets/img/partner-logos/bu-logo.webp',
            alt: 'Burda'
        },
        {
            src: 'assets/img/partner-logos/st-logo.webp',
            alt: 'Ströer'
        },
        {
            src: 'assets/img/partner-logos/fo-logo.webp',
            alt: 'Focus'
        },
        {
            src: 'assets/img/partner-logos/to-logo.webp',
            alt: 't-online'
        }
    ]

    constructor(private themeService: ThemeService) {
        effect(() => {
            this.currentTheme = this.themeService.themeSignal()
        })
    }
}
