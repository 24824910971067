<header [class.active]="isMenuOpen">
    <nav class="navbar">
        <!-- <div class="navbar__fill"></div> -->
        <div class="navbar__logo">
            <a
                [routerLink]="['/']"
                (click)="closeMenu()"
                aria-label="Mobility Ads">
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 747.74 98.2"
                    class="header__logo">
                    <defs>
                        <style>
                            .d {
                                fill: var(--moads-logo-text);
                            }

                            .e {
                                fill: #fb8c15;
                            }

                            .f {
                                fill: #e56e03;
                            }

                            .g {
                                fill: #677ff9;
                            }

                            .h {
                                fill: #4d69e2;
                            }
                        </style>
                    </defs>
                    <g id="a" />
                    <g id="b">
                        <g id="c">
                            <g>
                                <g>
                                    <polygon
                                        class="h"
                                        points="184.24 92.95 213.92 92.95 170.7 0 141.02 0 184.24 92.95" />
                                    <polygon
                                        class="f"
                                        points="87.75 31.92 72.91 0 43.22 0 72.91 63.84 87.75 31.92" />
                                    <polygon
                                        class="f"
                                        points="136.65 31.92 121.8 0 92.12 0 121.8 63.84 136.65 31.92" />
                                    <polygon
                                        class="e"
                                        points="29.69 92.95 0 92.95 43.22 0 72.9 0 29.69 92.95" />
                                    <polygon
                                        class="e"
                                        points="78.58 92.95 48.9 92.95 92.12 0 121.8 0 78.58 92.95" />
                                    <polygon
                                        class="g"
                                        points="127.48 92.95 97.8 92.95 141.02 0 170.7 0 127.48 92.95" />
                                    <polygon
                                        class="g"
                                        points="155.86 69.71 155.86 92.95 213.92 92.95 173.43 69.71 155.86 69.71" />
                                </g>
                                <g>
                                    <path
                                        class="d"
                                        d="M298.7,29.53l-19.1,48.75h-6.3l-19.1-48.75v48.75h-14.42V10.54h20.21l16.45,42.25,16.46-42.25h20.31V78.29h-14.52V29.53Z" />
                                    <path
                                        class="d"
                                        d="M318.1,53.71c0-13.92,9.75-25.7,25.9-25.7s26,11.78,26,25.7-9.75,25.8-26,25.8-25.9-11.78-25.9-25.8Zm38.5,0c0-7.62-4.57-14.22-12.6-14.22s-12.49,6.6-12.49,14.22,4.47,14.32,12.49,14.32,12.6-6.6,12.6-14.32Z" />
                                    <path
                                        class="d"
                                        d="M374.47,10.54h12.9v24.99c3.86-4.98,9.34-7.52,15.13-7.52,12.49,0,21.74,9.75,21.74,25.8s-9.34,25.7-21.74,25.7c-5.89,0-11.27-2.64-15.13-7.52v6.3h-12.9V10.54Zm23.97,57.49c7.52,0,12.49-5.69,12.49-14.22s-4.98-14.32-12.49-14.32c-4.16,0-8.94,2.44-11.07,5.69v17.37c2.13,3.15,6.91,5.48,11.07,5.48Z" />
                                    <path
                                        class="d"
                                        d="M427.59,14.3c0-4.27,3.56-7.72,7.72-7.72s7.72,3.45,7.72,7.72-3.45,7.72-7.72,7.72-7.72-3.45-7.72-7.72Zm1.32,14.93h12.9v49.06h-12.9V29.23Z" />
                                    <path
                                        class="d"
                                        d="M449.53,10.54h12.9V78.29h-12.9V10.54Z" />
                                    <path
                                        class="d"
                                        d="M468.83,14.3c0-4.27,3.56-7.72,7.72-7.72s7.72,3.45,7.72,7.72-3.45,7.72-7.72,7.72-7.72-3.45-7.72-7.72Zm1.32,14.93h12.9v49.06h-12.9V29.23Z" />
                                    <path
                                        class="d"
                                        d="M495.34,66.1v-25.6h-8.13v-11.28h8.13V15.82h13v13.41h9.95v11.28h-9.95v22.14c0,3.05,1.63,5.38,4.47,5.38,1.93,0,3.76-.71,4.47-1.42l2.74,9.75c-1.93,1.73-5.38,3.15-10.77,3.15-9.04,0-13.92-4.67-13.92-13.41Z" />
                                    <path
                                        class="d"
                                        d="M532.41,86.72c3.35,0,5.59-.91,6.81-3.56l1.83-4.16-19.91-49.77h13.81l12.8,34.13,12.8-34.13h13.92l-23.06,57.08c-3.66,9.24-10.16,11.68-18.59,11.88-1.52,0-4.77-.3-6.4-.71l1.93-11.58c1.12,.51,2.84,.81,4.06,.81Z" />
                                    <path
                                        class="d"
                                        d="M629.41,66.61h-30.27l-4.27,11.68h-15.85l26.21-67.75h18.08l26.1,67.75h-15.74l-4.27-11.68Zm-26.41-12.49h22.45l-11.17-31.08-11.27,31.08Z" />
                                    <path
                                        class="d"
                                        d="M686.09,71.99c-3.86,4.88-9.24,7.52-15.13,7.52-12.39,0-21.74-9.35-21.74-25.7s9.24-25.8,21.74-25.8c5.79,0,11.27,2.54,15.13,7.52V10.54h13V78.29h-13v-6.3Zm0-26.92c-2.13-3.25-6.81-5.59-11.17-5.59-7.31,0-12.39,5.79-12.39,14.32s5.08,14.22,12.39,14.22c4.37,0,9.04-2.34,11.17-5.59v-17.37Z" />
                                    <path
                                        class="d"
                                        d="M710.16,62.85c3.55,3.35,10.87,6.7,16.96,6.7,5.59,0,8.23-1.93,8.23-4.98,0-3.45-4.27-4.67-9.85-5.79-8.43-1.63-19.3-3.66-19.3-15.34,0-8.23,7.21-15.44,20.11-15.44,8.33,0,14.93,2.84,19.81,6.7l-5.18,8.84c-2.95-3.15-8.53-5.69-14.53-5.69-4.67,0-7.72,1.73-7.72,4.57,0,3.05,3.86,4.16,9.35,5.28,8.43,1.63,19.71,3.86,19.71,16.05,0,8.94-7.62,15.74-21.23,15.74-8.53,0-16.76-2.84-21.94-7.62l5.59-9.04Z" />
                                </g>
                            </g>
                        </g>
                    </g>
                </svg>
            </a>
        </div>

        <ul class="navbar__menu">
            <li>
                <a
                    [routerLink]="['/autohandler']"
                    routerLinkActive="active"
                    (click)="closeMenu()">
                    Autohändler
                </a>
            </li>
            <li>
                <a
                    [routerLink]="['/publisher']"
                    routerLinkActive="active"
                    (click)="closeMenu()">
                    Publisher
                </a>
            </li>
            <li>
                <a
                    [routerLink]="['/uber-uns']"
                    routerLinkActive="active"
                    (click)="closeMenu()">
                    Über Uns
                </a>
            </li>
            <li>
                <a
                    [routerLink]="['/kontakt']"
                    routerLinkActive="active"
                    (click)="closeMenu()">
                    Kontakt
                </a>
            </li>
            <li class="dropdown-toggler">
                <a (click)="toggleDropdown()" class="caret">Login</a>
                <div class="dropdown" [class.open]="isDropdownOpen">
                    <ul>
                        <li>
                            <a
                                href="https://partner.mobility-ads.de/"
                                target="_blank"
                                (click)="onLinkClick()">
                                Publisher
                            </a>
                        </li>
                        <li>
                            <a
                                href="https://backend.mobility-ads.de/modules/affili/merchantAccess/de/login.html"
                                target="_blank"
                                (click)="onLinkClick()">
                                Advertiser
                            </a>
                        </li>
                    </ul>
                </div>
            </li>
            <li>
                <span (click)="toggleTheme()" class="theme-toggle">
                    @if (isDarkMode) {
                        <svg viewBox="0 0 20 20" width="17">
                            <use href="assets/img/icons/sun.svg#sun" />
                        </svg>
                    }
                    @if (!isDarkMode) {
                        <svg viewBox="0 0 20 20" width="17">
                            <use href="assets/img/icons/moon.svg#moon" />
                        </svg>
                    }
                </span>
            </li>
        </ul>
        <span (click)="toggleMenu()" class="navbar__toggler">
            <span></span>
            <span></span>
        </span>
    </nav>
</header>
