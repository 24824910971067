import {
    Component,
    ElementRef,
    AfterViewInit,
    Renderer2,
    Inject,
    PLATFORM_ID,
    Input,
} from '@angular/core'
import { isPlatformBrowser } from '@angular/common'
import { AnimationUtils } from '../../utils/animation.utils'
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'

@Component({
    selector: 'app-bento',
    standalone: true,
    imports: [],
    templateUrl: './bento.component.html',
    styleUrl: './bento.component.scss',
})
export class BentoComponent {
    @Input()
    items: BentoItem[] = []

    constructor(
        private el: ElementRef,
        private renderer: Renderer2,
        @Inject(PLATFORM_ID) private platformId: Object,
        private animationUtils: AnimationUtils,
    ) {}

    ngAfterViewInit(): void {
        if (isPlatformBrowser(this.platformId)) {
            //gsap.registerPlugin(ScrollTrigger)
            // const observer = new IntersectionObserver((entries) => {
            //     entries.forEach((entry) => {
            //         if (entry.isIntersecting) {
            //             this.startAnimations(entry.target)
            //         } else {
            //             this.endAnimations(entry.target)
            //         }
            //     })
            // })

            // observer.observe(this.el.nativeElement)
        }
    }

    startAnimations(container: Element): void {
        const bentoItems = container.querySelectorAll('app-bento-item')
        bentoItems.forEach((item) => {
            this.renderer.setStyle(
                item,
                'animation',
                'bento-fade 0.6s linear forwards',
            )
        })
    }

    endAnimations(container: Element): void {
        const bentoItems = container.querySelectorAll('app-bento-item')
        bentoItems.forEach((item) => {
            this.renderer.setStyle(item, 'animation', 'none')
        })
    }
}

export interface BentoItem {
    direction: 'left' | 'right'
    col: string
    icon: string
    heading: string
    content: string
}
