<!-- <div class="speedometer__wrapper">
    <span class="speedometer">
        <img src="assets/img/shape/speedometer.png" class="speedometer__base" alt=""/>
        <img src="assets/img/shape/speedometer-arrow.png" class="speedometer__arrow" alt=""/>
    </span>
</div> -->

<app-hero [page]="'home'"></app-hero>

<section class="quote" #preFooter>
    <div class="container">
        <app-bento #bento>
            <app-bento-item col="12" row="2" direction="middle" class="quote">
                <div class="quote__ceo">
                    <div class="quote__ceo-img">
                        <span class="quote__ceo-img-overlay">
                            <img
                                src="assets/img/team/fatih-pp.webp"
                                alt="Fatih Özcan" />
                        </span>
                    </div>
                    <div class="quote__ceo-content">
                        <div class="quote__ceo-text">
                            <p>
                                Unsere Mission ist es, Autohäusern und
                                Publishern zu helfen, den digitalen Wandel der
                                Automobilindustrie zu nutzen. Wir bieten
                                effektive Lösungen, die den Verkauf und die
                                Monetarisierung von Traffic maximieren.
                            </p>
                        </div>
                        <div class="quote__ceo-author">
                            <span>Fatih Özcan</span>
                            <small>Geschäftsführer, Mobility Ads</small>
                        </div>
                    </div>
                </div>
            </app-bento-item>
        </app-bento>
    </div>
</section>

<section class="summary">
    <div class="container">
        <div class="d-flex">
            <div class="col-6">
                <span class="subtitle">UNSER UNTERNEHMEN</span>
                <h2 #reveal>Mobility Ads</h2>
                <p>
                    Mobility Ads ist die Antwort auf den digitalen Wandel im
                    Automobilsektor. Kundenbedürfnisse werden mittlerweile vom
                    Online-Angebot aufgefangen, noch bevor sie im stationären
                    Handel landen. Autohändler & Autohausbesitzer sollten Ihr
                    Kuchenstück abbekommen. Auch Publisher reagieren auf den
                    Fahrtwind im Online-Sektor. Dabei gilt es den neuen Traffic
                    mit exklusiven Automotive-Angeboten zu monetarisieren.
                </p>
                <span class="emphasized">
                    Fahren Sie sicher durch den digitalen Online-Dschungel!
                </span>
            </div>
            <div class="col-6">
                <img
                    src="assets/img/cars/channelizer-ss-mono.webp"
                    class="channelizer-ss"
                    alt="Channelizer Screenshots" />
            </div>
        </div>
    </div>
</section>

<section class="trust trust-badges">
    <div class="container">
        <div class="trust__grid">
            <div class="trust__grid-left">
                <img
                    class="invest"
                    src="assets/img/badges/invest-wagniskapital.webp"
                    alt="Invest Zuschuss für Wagniskapital" />
                <img
                    class="only-on-light"
                    src="assets/img/badges/iab-europe.webp"
                    alt="IAB Registered Vendor" />
                <img
                    class="only-on-dark"
                    src="assets/img/badges/iab-europe-light.webp"
                    alt="IAB Registered Vendor" />
            </div>
            <div class="trust__grid-right">
                <img
                    class="only-on-dark"
                    src="assets/img/badges/badge-climate.svg"
                    alt="We take Climate Action" />
                <img
                    class="only-on-light"
                    src="assets/img/badges/badge-climate-light.svg"
                    alt="We take Climate Action" />
                <img
                    src="assets/img/badges/badge-bvdw.webp"
                    alt="BVDW Member" />
            </div>
            <div class="trust__grid-left">
                <img
                    class="only-on-dark"
                    src="assets/img/badges/badge-zukunftswerkstatt.webp"
                    alt="Zukunfts Werkstatt 4.0" />
                <img
                    class="only-on-light"
                    src="assets/img/badges/badge-zukunftswerkstatt-light.webp"
                    alt="Zukunfts Werkstatt 4.0" />
            </div>
        </div>
    </div>
</section>

<section class="why">
    <div class="container">
        <span class="subtitle">ERFOLGSWEG</span>
        <h2 #reveal>Warum Mobility Ads die Lösung für Sie ist?</h2>
        <app-bento #bento>
            <app-bento-item
                col="6"
                row="1"
                direction="left"
                [routerLink]="['/autohandler']"
                class="bento-autohandler">
                <div class="bento__item-icon-wrapper autohandler">
                    <span class="icon">
                        <img
                            src="assets/img/icons/autohandler.svg"
                            alt="Autohändler" />
                    </span>
                </div>
                <!-- <span class="subtitle">AUTOHÃNDLER</span> -->
                <h3>Alle Autohändler aufgepasst!</h3>
                <p>
                    Mehr Verkäufe, mehr Kontrolle – Mobility Ads bringt Ihr
                    Autohaus auch online zum Erfolg! Als verlässlicher Partner
                    im digitalen Dschungel!
                </p>
                <p class="brighter">
                    Kämpfen Sie mit sinkenden Verkaufszahlen? Mit Mobility Ads
                    generieren Sie qualifizierte Leads und steigern Ihre
                    Online-Präsenz.
                </p>
                <a [routerLink]="['/autohandler']" class="link">
                    <span class="gradient autohandler">
                        Jetzt mehr erfahren
                    </span>
                    <span class="arrow"></span>
                </a>
            </app-bento-item>
            <app-bento-item
                col="6"
                row="1"
                direction="right"
                [routerLink]="['/autohandler']"
                class="bento-publisher">
                <div class="bento__item-icon-wrapper publisher">
                    <span class="icon">
                        <img
                            src="assets/img/icons/publisher.svg"
                            alt="Publisher" />
                    </span>
                </div>
                <!-- <span class="subtitle">PUBLISHER</span> -->
                <h3>Für Publisher only!</h3>
                <p>
                    Monetarisieren Sie Ihren Traffic – Mit Mobility Ads zu
                    exklusiven Lead-Magnet-Inhalten und lukrativen
                    Automotive-Angeboten!
                </p>
                <p class="brighter">
                    Sie haben hohen Traffic, aber keine Einnahmen? Mobility Ads
                    bietet Ihnen maßgeschneiderte Lösungen, um Ihre Reichweite
                    profitabel zu nutzen.
                </p>
                <a [routerLink]="['/publisher']" class="link secondary">
                    <span class="gradient publisher">Jetzt mehr erfahren</span>
                    <span class="arrow"></span>
                </a>
            </app-bento-item>
        </app-bento>
    </div>
</section>

<section class="reviews">
    <div class="container">
        <div class="reviews__grid">
            <div class="reviews__grid-left">
                <a
                    href="https://www.google.com/search?q=mobility+ads&oq=mobility+ads&gs_lcrp=EgZjaHJvbWUqBggAEEUYOzIGCAAQRRg7MgsIARAAGAoYExiABDIKCAIQABgTGBYYHjIKCAMQABgTGBYYHjIKCAQQABgTGBYYHjIGCAUQRRg8MgYIBhBFGDwyBggHEEUYQdIBCDIzNDJqMGo0qAIAsAIB&sourceid=chrome&ie=UTF-8#lrd=0x47a8537170f967eb:0xf5e5945228c2eb11,1,,,,"
                    target="_blank">
                    <img
                        class="only-on-dark"
                        src="assets/img/badges/google-reviews-dark.svg"
                        alt="Google Reviews" />
                    <img
                        class="only-on-light"
                        src="assets/img/badges/google-reviews.svg"
                        alt="Google Reviews" />
                </a>
            </div>
            <div class="reviews__grid-right">
                <a
                    href="https://www.trustpilot.com/review/mobility-ads.de"
                    target="_blank">
                    <img
                        class="only-on-dark"
                        src="assets/img/badges/trustpilot-reviews-dark.svg"
                        alt="Trustpilot Reviews" />
                    <img
                        class="only-on-light"
                        src="assets/img/badges/trustpilot-reviews.svg"
                        alt="Trustpilot Reviews" />
                </a>
            </div>
        </div>
    </div>
</section>

<section id="testimonials" class="quote customers">
    <div class="container">
        <span class="subtitle">FEEDBACK</span>
        <h2 #reveal>Was sagen Kunden über uns?</h2>
        <app-bento #bento>
            <app-bento-item col="4" row="1" direction="left">
                <div class="quote__customers">
                    <div class="quote__customers-img">
                        <img
                            src="assets/img/customers/benjamin-weiland.webp"
                            alt="Benjamin Weiland" />
                        <div class="quote__customers-author">
                            <p>Benjamin Weiland</p>
                            <small>Geschäftsführer, Trimexa GmbH</small>
                        </div>
                    </div>
                    <div class="quote__customers-text">
                        <p>
                            "Durch die Zusammenarbeit mit Mobility Ads konnten
                            wir die Anzahl an attraktiven Angeboten aus dem
                            Bereich Leasing auf unseren Portalen deutlich
                            erhöhen."
                        </p>
                        <br />
                    </div>
                </div>
            </app-bento-item>
            <app-bento-item col="4" row="1" direction="middle">
                <div class="quote__customers">
                    <div class="quote__customers-img">
                        <img
                            src="assets/img/customers/morten-ohle.webp"
                            alt="Morten Ohle" />
                        <div class="quote__customers-author">
                            <p>Morten Ohle</p>
                            <small>Geschäftsführer, Mivodo GmbH</small>
                        </div>
                    </div>
                    <div class="quote__customers-text">
                        <p>
                            "Mobility Ads schafft es Bewegung in die sonst träge
                            Branche des Automobilvertriebs zu bringen. Sie
                            bringen regelmäßig neue Partnerprogramme online und
                            bauen im Zweifel sogar eigene Lösungen für die
                            Advertiser, sodass diese überhaupt online zu
                            bewerben sind. Daneben ist die Zusammenarbeit mit
                            Fatih und Oguz sehr angenehm.“
                        </p>
                        <br />
                    </div>
                </div>
            </app-bento-item>
            <app-bento-item col="4" row="1" direction="right">
                <div class="quote__customers">
                    <div class="quote__customers-img">
                        <img
                            src="assets/img/customers/michael-wamhof.webp"
                            alt="Michael Wamhof" />
                        <div class="quote__customers-author">
                            <p>Michael Wamhof</p>
                            <small>Geschäftsführer, WakeUp Media OHG</small>
                        </div>
                    </div>
                    <div class="quote__customers-text">
                        <p>
                            “Die Angebote der Mobility Ads Partnerprogramme sind
                            für unsere Nutzer von großem Wert und sorgen für
                            mehr Abwechslung und starke Monetarisierung.”
                        </p>
                        <br />
                    </div>
                </div>
            </app-bento-item>
        </app-bento>
    </div>
</section>

<section class="trust">
    <div class="container">
        <div class="trust__grid">
            <div class="trust__grid-left">
                <img
                    src="assets/img/badges/badge-dsgvo.svg"
                    alt="DSGVO Konform" />
                <img
                    src="assets/img/badges/badge-ssl.svg"
                    alt="Verschlüsselte SSL Sichereit" />
            </div>
            <div class="trust__grid-right">
                <img
                    src="assets/img/badges/badge-iso.svg"
                    alt="Sichere Daten & ISO-zert. Server" />
                <img
                    src="assets/img/badges/badge-germany.svg"
                    alt="Hosted in Germany" />
            </div>
        </div>
    </div>
</section>

<section class="summary">
    <div class="container">
        <div class="">
            <span class="subtitle">WOFÜR WIR STEHEN</span>
            <h2 #reveal>Mobility Ads Team</h2>
            <p>
                In unseren Adern fließen 1.000 PS und mehr. Denn unser Team
                besteht aus leidenschaftlichen Automobil-Experten & Spezialisten
                des digitalen Vertriebs. Die perfekte Mischung, um sich im
                Bereich der Online-Monetarisierung von Automobil-Interessenten
                zu positionieren.
            </p>
            <span class="emphasized">
                Unsere Werte leben wir gemeinsam mit unseren Kunden:
            </span>
            <hr />
            <app-bento #bento>
                <app-bento-item col="4" row="1" direction="left">
                    <div class="bento__item-icon-wrapper">
                        <span class="icon">
                            <img
                                src="assets/img/icons/transparency.svg"
                                alt="" />
                        </span>
                    </div>
                    <span class="emphasized">Transparenz</span>
                    <p>
                        Wir zeigen die einzelnen Ergebnisse auf Knopfdruck und
                        werden nur im Erfolgsfall entlohnt.
                    </p>
                </app-bento-item>
                <app-bento-item col="4" row="1" direction="middle">
                    <div class="bento__item-icon-wrapper">
                        <span class="icon">
                            <img src="assets/img/icons/team.svg" alt="" />
                        </span>
                    </div>
                    <span class="emphasized">Unabhängigkeit</span>
                    <p>
                        Wir profitieren von exklusiven
                        Leitmedien-Partnerschaften und führen eine unabhängige
                        Redaktion für alle Automobil-Artikel.
                    </p>
                </app-bento-item>
                <app-bento-item col="4" row="1" direction="right">
                    <div class="bento__item-icon-wrapper">
                        <span class="icon">
                            <img src="assets/img/icons/validation.svg" alt="" />
                        </span>
                    </div>
                    <span class="emphasized">Effizienz</span>
                    <p>
                        Ihre Fahrzeugbestände werden bei uns gelistet &
                        freigegeben. Ohne technisches Know-how zu regelmäßigen
                        Kundenanfragen. Das ist unser Anspruch!
                    </p>
                </app-bento-item>
            </app-bento>
            <hr />
            <p class="answer-i">
                Unsere Mission ist es, sowohl Autohäusern als auch
                Publishern dabei zu helfen, ihre Online-Potenziale
                voll auszuschöpfen. Wir bieten einfache, aber
                wirkungsvolle Lösungen, die Ihnen dabei helfen,
                online erfolgreich zu sein.
            </p>

            <button
                class="button-2 reverse"
                [routerLink]="['/uber-uns']">
                Mehr über uns
                <figure class="arrow"></figure>
            </button>
        </div>
    </div>
</section>

<section class="banner win-leads">
    <div class="container">
        <h1>
            <span class="gradient autohandler">Geklickt</span>
            -
            <span class="gradient publisher">gebucht!</span>
        </h1>
        <p>
            Leere Verkaufsräume sind ein gutes Zeichen! Potenzielle Kunden
            machen bereits die Probefahrt.
            <br />
            Jetzt vom digitalen Kuchen abbeißen und Leads gewinnen.
        </p>
        <div class="buttons">
            <button [routerLink]="['/autohandler']" class="button-2 primary">
                Für Autohändler → Hier entlang
            </button>
            <button [routerLink]="['/publisher']" class="button-2 secondary">
                Für Publisher → Hier entlang
            </button>
        </div>
    </div>
</section>
