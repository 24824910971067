<div class="bento">
    <ng-content></ng-content>
</div>

<!-- <div class="bento">
    @for (item of items; track $index) {
        <div class="bento__item" [attr.data-col]="item.col" [attr.data-direction]="item.direction">
            @if (item.icon) {
                <i class="icon-{{item.icon}}"></i>
            }
            @if (item.heading) {
                <h2>{{item.heading}}</h2>
            }
            @if (item.content) {
                <p>{{item.content}}</p>
            }
            <ng-content></ng-content>
        </div>
    }
</div> -->
