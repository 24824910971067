<section class="bar">
    <img src="assets/img/hero/autohandler-hero-bg-dark.webp" class="hero__image-bg only-on-dark" alt="" />
    <img src="assets/img/hero/autohandler-hero-bg.webp" class="hero__image-bg only-on-light" alt="" />
    <div class="container">
        <!-- <span class="subtitle">KONTAKT</span> -->
        <h1 class="success">Ihre Anfrage wurde erfolgreich übermittelt!</h1>
        <p class="mb-0">
            Vielen Dank für Ihre Nachricht. Wir überprüfen Ihr Anliegen und melden uns innerhalb von 24 Stunden bei
            Ihnen zurück.
        </p>
    </div>
</section>

<section class="contact-success">
    <div class="container">
        @if (contactRequest) {
            <h3>Folgende Informationen haben Sie uns zur Verfügung gestellt:</h3>
            <table>
                <tr>
                    <td>
                        <b>Name:&nbsp;</b>
                        {{ contactRequest.firstName }} {{ contactRequest.lastName }}
                    </td>
                    <td>
                        <b>Telefon:&nbsp;</b>
                        @if (contactRequest.phone) {
                            {{ contactRequest.phone }}
                        } @else {
                            -
                        }
                    </td>
                </tr>
                <tr>
                    <td>
                        <b>E-Mail:&nbsp;</b>
                        {{ contactRequest.email }}
                    </td>
                    @if (contactRequest.intent) {
                        <td>
                            <b>Thema:&nbsp;</b>
                            @if (contactRequest.intent === 'MERCHANT') {
                                Ich bin ein Händler und möchte Kontakt aufnehmen.
                            }
                            @if (contactRequest.intent === 'PUBLISHER') {
                                Ich bin ein Publisher und möchte Kontakt aufnehmen.
                            }
                            @if (contactRequest.intent === 'JOB') {
                                Ich suche eine neue berufliche Herausforderung.
                            }
                            @if (contactRequest.intent === 'SUPPORT') {
                                Ich benötige technischen Support.
                            }
                            @if (contactRequest.intent === 'OTHER') {
                                Ich habe andere Frage.
                            }
                        </td>
                    }
                </tr>
                @if (contactRequest.message) {
                    <tr>
                        <td>
                            <b>Nachricht:&nbsp;</b>
                            <br />
                            {{ contactRequest.message }}
                        </td>
                    </tr>
                }
            </table>
            <!--
            <p>
                <b>Name:&nbsp;</b>
                {{ contactRequest.firstName }} {{ contactRequest.lastName }}
                <br />
                <b>E-Mail-Adresse:&nbsp;</b>
                {{ contactRequest.email }}
                <br />

                @if (contactRequest.phone) {
                    <b>Telefonnummer:&nbsp;</b>
                    {{ contactRequest.phone }}
                    <br />
                }

                @if (contactRequest.intent) {
                    <br />
                    <b>Thema:&nbsp;</b>
                    @if (contactRequest.intent === 'MERCHANT') {
                        Ich bin ein Händler und möchte Kontakt aufnehmen.
                    }
                    @if (contactRequest.intent === 'PUBLISHER') {
                        Ich bin ein Publisher und möchte Kontakt aufnehmen.
                    }
                    @if (contactRequest.intent === 'JOB') {
                        Ich suche eine neue berufliche Herausforderung.
                    }
                    @if (contactRequest.intent === 'SUPPORT') {
                        Ich benötige technischen Support.
                    }
                    @if (contactRequest.intent === 'OTHER') {
                        Ich habe andere Frage.
                    }
                    <br />
                }
                @if (contactRequest.message) {
                    <b>Nachricht:&nbsp;</b>
                    <br />
                    {{ contactRequest.message }}
                    <br />
                    <br />
                }
            </p> -->

            <button class="button-2 primary" [routerLink]="'/'">
                Zurück zur Startseite
                <figure class="arrow"></figure>
            </button>
        }
        <br />
    </div>
</section>
