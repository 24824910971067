<section class="data-protection">
    <div class="container">
        <h1>Datenschutz</h1>
        <p>
            Jegliche Erhebung, Verwendung, Speicherung, Löschung oder sonstige
            Nutzung (nachfolgend „Verarbeitung“) von Daten dient ausschließlich
            der Erbringung unserer Dienstleistungen. Unsere Dienste sind mit dem
            Ziel gestaltet worden, so wenig personenbezogene Daten wie möglich
            zu verwenden. Dabei werden unter “personenbezogenen Daten”
            (nachfolgend auch „Daten“ genannt) alle Einzelangaben über
            persönliche oder sachliche Verhältnisse einer bestimmten oder
            bestimmbaren natürlichen Person (sog. „betroffene Person“)
            verstanden.
            <br />
        </p>
        <p>
            Die folgenden Ausführungen zum Datenschutz beschreiben, welche Arten
            von personenbezogenen Daten beim Aufruf unseres Internetangebotes
            verarbeitet werden, was mit diesen personenbezogenen Daten geschieht
            und wie Sie einer Datenverarbeitung gegebenenfalls widersprechen
            können.
        </p>

        <h3>1. Allgemeines zur Datenverarbeitung auf dieser Webseite</h3>
        <h4>1.1. Verantwortlicher</h4>
        <p>
            Verantwortlicher im Sinne der EU-Datenschutz-Grundverordnung (DSGVO)
            ist:
        </p>
        <p>
            <b>
                Mobility-Ads GmbH
                <br />
            </b>
        </p>
        <p>
            <b>Anschrift:</b>
            Stresemannstraße 23, 10963 Berlin
        </p>
        <p>
            <b>Telefon:</b>
            030 – 800987840
        </p>
        <p>
            <span>
                <b>E-Mail: </b>
                <a href="mailto:info@mobility-ads.de">
                    <span>info&#64;mobility-ads.de</span>
                </a>
                <br />
            </span>
        </p>
        <p>
            <span>
                <b>Webseite: </b>
                <a href="https://www.mobility-ads.de/">
                    <span>https://www.mobility-ads.de</span>
                </a>
                <br />
            </span>
        </p>
        <p>
            <span>
                <a href="https://www.partner.mobility-ads.de/">
                    https://www.partner.mobility-ads.de
                </a>
            </span>
            <br />
        </p>
        <p>
            <span>
                <a
                    href="https://backend.mobility-ads.de/modules/affili/merchantAccess/de/login.html">
                    https://backend.mobility-ads.de/modules/affili/merchantAccess/de/login.html
                </a>
            </span>
            <br />
        </p>

        <h4>1.2. Beauftragter für Datenschutz</h4>
        <p>
            Der Datenschutzbeauftragte ist Kemal Webersohn von der WS
            Datenschutz GmbH.
        </p>
        <p>
            Bei Fragen zum Datenschutz können Sie die WS Datenschutz GmbH unter
            folgender E-Mail-Adresse erreichen:
            <a href="mailto:mobility-ads@ws-datenschutz.de">
                <span>mobility-ads&#64;ws-datenschutz.de</span>
            </a>
        </p>
        <p>WS Datenschutz GmbH</p>
        <p>
            Dircksenstraße 51
            <br />
        </p>
        <p>D-10178 Berlin</p>
        <p>
            <span>
                <a href="https://webersohnundscholtz.de/">
                    Startseite – Webersohn &amp; Scholtz
                    (webersohnundscholtz.de)
                </a>
            </span>
        </p>

        <img
            decoding="async"
            class="wp-image-22782 aligncenter"
            src="assets/img/badges/badge-invest.webp"
            alt=""
            width="99"
            height="113" />
        <h4>1.3. Schutz Ihrer Daten</h4>
        <p>
            Wir haben technische und organisatorische Maßnahmen getroffen, die
            sicherstellen, dass die Vorschriften der DSGVO sowohl von uns als
            auch von externen Dienstleistern, die für uns arbeiten, beachtet
            werden.
        </p>
        <p>
            Wenn wir zur Erbringung unserer Dienstleistungen mit anderen
            Unternehmen, wie etwa E-Mail- und Server-Anbietern,
            zusammenarbeiten, geschieht dies nur nach einem umfangreichen
            Auswahlverfahren. Bei diesem Auswahlverfahren wird jeder einzelne
            Dienstleister auf seine Eignung in Zusammenhang mit technischen und
            organisatorischen Fähigkeiten im Datenschutz hin sorgfältig
            ausgewählt. Dieses Auswahlverfahren wird schriftlich dokumentiert
            und ein Vertrag gem. Art. 28 Abs. 3 DSGVO über die Verarbeitung von
            personenbezogenen Daten im Auftrag (ADV-Vertrag) nur dann
            geschlossen, wenn er den Anforderungen des Art. 28 DSGVO entspricht.
        </p>
        <p>
            Ihre Angaben werden auf besonders geschützten Servern gespeichert.
            Der Zugriff darauf ist nur wenigen besonders befugten Personen
            möglich.
            <br />
        </p>
        <p>
            Unsere Webseite ist SSL/TLS verschlüsselt, was Sie am „
            <span>https://</span>
            “ am Anfang der URL erkennen können. Sofern personenbezogene Daten
            bei der E-Mail-Kommunikation involviert sind, erfolgt der
            E-Mail-Versand von unserer Seite aus verschlüsselt. Wir nutzen
            hierfür ebenfalls das integrierte SSL-Zertifikat.
        </p>

        <h4>1.4. Löschung der personenbezogenen Daten</h4>
        <p>
            Wir verarbeiten die personenbezogenen Daten nur so lange, wie es
            notwendig ist. Sobald der Zweck der Datenverarbeitung erfüllt ist,
            findet nach den Maßstäben des hiesigen Löschkonzepts eine Sperrung
            und Löschung statt, es sei denn, dass gesetzliche Vorschriften einer
            Löschung entgegenstehen.
            <br />
        </p>

        <h3>
            2. Datenverarbeitung auf dieser Webseite und Erstellung von Logfiles
        </h3>
        <h4>2.1. Beschreibung und Umfang der Datenverarbeitung</h4>
        <p>
            Wenn Sie unsere Webseite besuchen, speichern unsere Webserver
            temporär jeden Zugriff in einer Protokolldatei. Dabei werden
            folgende personenbezogene Daten erfasst und bis zur automatisierten
            Löschung gespeichert:
        </p>

        <ul>
            <li>IP-Adresse des anfragenden Rechners</li>
            <li>Datum und Uhrzeit des Zugriffs</li>
            <li>Name und URL der abgerufenen Datei</li>
            <li>Übertragene Datenmenge</li>
            <li>Meldung, ob der Abruf erfolgreich war</li>
            <li>
                Erkennungsdaten des verwendeten Browser- und Betriebssystems
            </li>
            <li>Webseite, von der der Zugriff erfolgt</li>
            <li>Name Ihres Internet-Zugangs-Providers</li>
        </ul>
        <p>
            Zusätzlich zu diesen personenbezogenen Daten werden ggf. weitere
            personenbezogene Daten von uns und unseren Partnern erhoben, dazu
            unten mehr.
        </p>

        <h4>2.2. Rechtsgrundlage für die Datenverarbeitung</h4>
        <p>
            Die Verarbeitung dieser Daten erfolgt auf Grundlage des Art. 6 Abs.
            1 S.1 lit. f) DSGVO. Unser berechtigtes Interesse beruht darauf,
            Ihnen unsere Webseite zugänglich zu machen.
        </p>

        <h4>2.3. Zweck der Datenverarbeitung</h4>
        <p>
            Die Datenverarbeitung erfolgt zu dem Zweck, die Nutzung der Webseite
            zu ermöglichen (Verbindungsaufbau). Sie dient der Systemsicherheit,
            der technischen Administration der Netzinfrastruktur sowie der
            Optimierung des Internetangebotes. Die IP-Adresse wird nur bei
            Angriffen auf unsere Netzinfrastruktur bzw. die Netzinfrastruktur
            unseres Internetproviders ausgewertet.
        </p>

        <h4>2.4. Dauer der Datenspeicherung</h4>
        <p>
            Die personenbezogenen Daten werden gelöscht, sobald sie zu den oben
            genannten Zwecken nicht mehr erforderlich sind. Dies ist der Fall,
            wenn Sie die Webseite schließen. Unser Hosting-Anbieter verwendet
            die Daten gegebenenfalls für statistische Erhebungen. Hierfür werden
            die Daten jedoch anonymisiert.
            <br />
        </p>

        <h4>2.5. Beseitigungsmöglichkeit durch die betroffene Person</h4>
        <p>
            Die Erfassung der Daten zur Bereitstellung der Website und die
            Speicherung der Daten in Logfiles ist für den Betrieb der
            Internetseite zwingend erforderlich. Es besteht folglich seitens des
            Nutzers keine Widerspruchsmöglichkeit.
        </p>

        <h4>2.6. IONOS Hosting</h4>
        <b>2.6.1. Beschreibung und Umfang der Datenverarbeitung</b>
        <p>
            Unser Internetangebot nutzt die Dienste des Hosting-Providers
            1&amp;1. Die Datenverarbeitung wird durchgeführt von: 1&amp;1 IONOS
            SE, Elgendorfer Str. 57, 56410 Montabaur.
        </p>
        <p>
            Bei Ihrem Zugriff auf unser Internetangebot werden aus technischen
            Gründen Daten, insbesondere Ihre IP-Adresse, durch 1&amp;1
            gespeichert und verarbeitet.
            <br />
        </p>
        <p>
            Zusätzliche Informationen entnehmen Sie bitte der
            Datenschutzerklärung von 1&amp;1:
            <a href="https://www.ionos.de/terms-gtc/terms-privacy/">
                <span>https://www.ionos.de/terms-gtc/terms-privacy/</span>
            </a>
        </p>
        <b>2.6.2. Rechtsgrundlage der Datenverarbeitung</b>
        <p>
            Die Verarbeitung dieser Daten erfolgt auf Grundlage unseres
            berechtigten Interesses Ihnen unsere Webseite zur Verfügung zu
            stellen gemäß Art. 6 Abs. 1 S.1 lit. f) DSGVO.
        </p>
        <b>2.6.3. Zweck der Datenverarbeitung</b>
        <p>
            Die Datenverarbeitung erfolgt zu dem Zweck, die Nutzung der Webseite
            zu ermöglichen. Sie dient der Systemsicherheit, der technischen
            Administration der Netzinfrastruktur sowie der Optimierung des
            Internetangebotes. Interne Auswertungen und Analysen durch 1&amp;1
            dienen der Verbesserung deren Produkte und Services und werden nach
            deren Aussage ausschließlich im gesetzlich definierten Rahmen
            anonymisiert und pseudonymisiert vorgenommen.
        </p>
        <b>2.6.4. Dauer der Datenspeicherung</b>
        <p>
            Die Daten werden gelöscht, sobald der Zweck der Verarbeitung erfüllt
            wurde und keine gesetzlichen oder vertraglichen Regelungen einer
            Löschung entgegenstehen.
        </p>
        <b>2.6.5. Beseitigungsmöglichkeit durch die betroffene Person</b>
        <p>
            Neben dem Auskunftsrecht stehen Ihnen auch ein Berichtigungsrecht
            bezüglich der über Sie gespeicherten personenbezogenen Daten, ein
            Löschungsrecht, ein Sperrungsrecht und ein Übertragungsrecht Ihrer
            Daten zu. Darüber hinaus können Sie jederzeit gegen diese
            Verarbeitung bei uns oder bei dem Datenschutzbeauftragten von
            1&amp;1 Widerspruch einlegen. Möchten Sie eines dieser Rechte
            ausüben, können Sie sich bei dem Datenschutzbeauftragten von 1&amp;1
            unter der oben genannten Adresse melden oder eine E-Mail an
            <a href="mailto:datenschutz@ionos.de">
                <span>datenschutz&#64;ionos.de</span>
            </a>
            senden.
        </p>

        <h3>3. Einsatz von Cookies</h3>
        <h4>3.1. Beschreibung und Umfang der Datenverarbeitung</h4>
        <p>
            Unsere Webseite verwendet Cookies. Diese werden bei Ihrer Nutzung
            unserer Webseite auf Ihrem Rechner gespeichert. Cookies sind kleine
            Textdateien, die auf Ihrer Festplatte, dem von Ihnen verwendeten
            Browser zugeordnet, gespeichert werden und durch welche uns bzw. der
            Stelle, die das Cookie setzt, bestimmte Informationen zufließen.
            Cookies können keine Programme ausführen oder Viren auf Ihren
            Computer übertragen. Sie werden von uns dazu verwendet, um Ihnen ein
            Login zu ermöglichen sowie um in anonymisierter oder
            pseudonymisierter Form die Nutzung von unserer Webseite zu
            analysieren und Ihnen interessante Angebote auf dieser Webseite zu
            präsentieren. Auf diese Weise können verschiedene Daten übermittelt
            werden:
        </p>

        <ul>
            <li>Häufigkeit der Webseitenbesuche</li>
            <li>
                Welche Funktionen der Webseite von Ihnen in Anspruch genommen
                werden
            </li>
            <li>Verwendete Suchbegriffe</li>
            <li>Ihre Cookie-Einstellung</li>
        </ul>
        <p>
            Beim Aufruf der Webseite wird über ein Cookie-Banner über die
            Verwendung von Cookies informiert und auf die Datenschutzerklärung
            verwiesen.
            <br />
        </p>

        <h4>3.2. Rechtsgrundlage für die Datenverarbeitung</h4>
        <p>
            Die Rechtsgrundlage für die Verarbeitung von Daten durch Cookies,
            die nicht allein der Funktionalität unserer Webseite dienen, ist
            Art. 6 Abs. 1 S. 1 lit. a) DSGVO.
        </p>
        <p>
            Die Rechtsgrundlage für die Datenverarbeitung für Cookies, die
            allein der Funktionalität dieser Webseite dienen, ist Art. 6 Abs. 1
            S.1 lit. f) DSGVO.
        </p>

        <h4>3.3. Zweck der Datenverarbeitung</h4>
        <p>
            Unser berechtigtes Interesse ergibt sich aus der Gewährleistung
            eines reibungslosen Verbindungsaufbaus sowie einer komfortablen
            Nutzung unserer Webseite als auch aus Gründen der Auswertung der
            Systemsicherheit -und Stabilität. Die Datenverarbeitung findet zudem
            statt, um eine statistische Auswertung der Webseitennutzung zu
            ermöglichen.
            <br />
        </p>

        <h4>3.4. Dauer der Datenspeicherung</h4>
        <p>
            Es gibt zwei Arten von Cookies. Beide werden auf dieser Webseite
            eingesetzt:
            <br />
        </p>

        <ul>
            <li>Transiente Cookies (dazu a)</li>
            <li>Persistente Cookies (dazu b)</li>
        </ul>
        <p>
            a)
            <b>Transiente Cookies</b>, sie werden automatisiert gelöscht, wenn Sie den Browser schließen.
            Dazu zählen insbesondere um Session-Cookies. Diese speichern eine
            sogenannte Session-ID, mit welcher sich verschiedene Anfragen Ihres
            Browsers der gemeinsamen Sitzung zuordnen lassen. Dadurch kann Ihr
            Rechner wiedererkannt werden, wenn Sie auf unsere Webseite
            zurückkehren. Die Session-Cookies werden gelöscht, wenn Sie sich
            ausloggen oder den Browser schließen.
        </p>
        <p>
            b)
            <b>Persistente Cookies</b>, sie werden automatisiert nach einer vorgegebenen Dauer gelöscht,
            die sich je nach Cookie unterscheiden kann.
        </p>

        <h4>3.5. Beseitigungsmöglichkeit durch die betroffene Person</h4>
        <p>
            Sie haben jederzeit die Möglichkeit, die Einwilligung in die
            Datenverarbeitung durch Cookies, die nicht allein der Funktionalität
            der Webseite dienen, zu widerrufen. Zudem setzen wir Cookies erst,
            nachdem Sie beim Aufrufen der Seite dem Setzen von Cookies
            zugestimmt haben. Auf diese Weise können Sie die Datenverarbeitung
            über Cookies auf unserer Webseite verhindern. Sie können ebenfalls
            die Cookies in den Sicherheitseinstellungen Ihres Browsers jederzeit
            löschen. Wir weisen Sie darauf hin, dass Sie eventuell nicht alle
            Funktionen dieser Webseite nutzen können. Auch kann das Setzen von
            Cookies durch entsprechende Einstellungen in Ihrem Internetbrowser
            jederzeit verhindert werden.
        </p>

        <h3>
            4. Kontakt
            <br />
        </h3>
        <h4>4.1. Beschreibung und Umfang der Datenverarbeitung</h4>
        <p>
            Sie haben die Möglichkeit, über E-Mail an
            <a href="mailto:info@mobility-ads.de">
                <span>info&#64;mobility-ads.de</span>
            </a>, per Telefon oder über das auf unserer Webseite integriertes
            Kontaktformular, Kontakt zu uns aufzunehmen. Hierfür werden
            verschiedene Daten für die Beantwortung der Anfrage erforderlich,
            die zur Bearbeitung automatisch gespeichert werden. Folgende Daten
            werden im Rahmen des Kontaktformulars mindestens (gekennzeichnet als
            Pflichtfeld) erhoben:
        </p>

        <ul>
            <li>E-Mail-Adresse</li>
            <li>Vorname</li>
            <li>Nachname</li>
            <li>Telefonnummer</li>
            <li>Unternehmensname</li>
        </ul>
        <p>Freiwillig können Sie noch folgende Angaben machen:</p>

        <ul>
            <li>Nachricht</li>
        </ul>
        <p>
            Eine Weitergabe der Daten an Dritte erfolgt nicht.
            <br />
        </p>

        <h4>4.2. Rechtsgrundlage für die Datenverarbeitung</h4>
        <p>
            Die hier verwendete Rechtsgrundlage ist Art. 6 Abs. 1 S.1 lit. b)
            DSGVO.
        </p>

        <h4>4.3. Zweck der Datenverarbeitung</h4>
        <p>
            Wir verarbeiten Ihre Daten ausschließlich, um Ihre Kontaktanfrage zu
            bearbeiten.
        </p>

        <h4>4.4. Dauer der Datenspeicherung</h4>
        <p>
            Ihre Daten werden von uns gelöscht, sobald der Zweck der
            Datenverarbeitung erfüllt wurde. In seltenen Fällen kann es
            allerdings vorkommen, dass wir Ihre Daten für einen längeren
            Zeitraum aufbewahren. Dies kann sich aus gesetzlichen, behördlichen
            oder vertraglichen Pflichten ergeben.
        </p>

        <h4>4.5. Beseitigungsmöglichkeit durch die betroffene Person</h4>
        <p>
            Sie können jederzeit mit uns Kontakt aufnehmen und einer weiteren
            Verarbeitung Ihrer Daten widersprechen. In diesem Fall können wir
            die Kommunikation mit Ihnen leider nicht fortführen. Alle
            personenbezogenen Daten, die im Zuge der Kontaktaufnahme von uns
            verarbeitet wurden, werden in diesem Fall gelöscht, es sei denn, es
            stehen der Löschung gesetzliche Pflichten zur Aufbewahrung Ihrer
            Daten entgegen.
        </p>

        <h4>4.6. HubSpot</h4>
        <b>4.6.1. Beschreibung und Umfang der Datenverarbeitung</b>
        <p>
            Wir nutzend das Kontaktformular von „HubSpot“, einer
            Online-Marketing-Plattform. Die Datenverarbeitung wird durchgeführt
            von: HubSpot, Inc, 25 First Street Cambridge, MA 02141, USA.
        </p>
        <p>
            HubSpot erfasst die im Kontaktformular angegebenen Daten sowie den
            Tag und die Uhrzeit, an der die E-Mail geöffnet wurde und speichert
            diese in unserer Software. Ihre Daten werden dabei auf Servern in
            den USA gespeichert.
        </p>
        <p><b>Hinweis zur Datenverarbeitung in den USA:</b></p>
        <p>
            Wenn Sie das Kontaktformular nutzen, ist es möglich, dass Ihre Daten
            durch unseren HubSpot in den USA verarbeitet werden. Der
            Datenschutzstandard in den USA ist nach Ansicht des EuGHs
            unzureichend und es besteht die Gefahr, dass Ihre Daten durch die
            US-Behörden zu Kontroll- und Überwachungszwecken, möglicherweise
            auch ohne Rechtsbehelfsmöglichkeiten, verarbeitet werden. Sofern Sie
            uns per E-Mail kontaktieren, findet eine Datenübertragung in die USA
            durch HubSpot nicht statt.
        </p>
        <p>
            HubSpot nutzt die Daten nicht und gibt diese nicht an Dritte weiter.
            Wir vertrauen auf die Zuverlässigkeit und die IT- sowie
            Datensicherheit von HubSpot. Weitere Informationen zum Datenschutz
            bei HubSpot finden Sie unter
            <a href="https://legal.hubspot.com/de/terms-of-service">
                <span>https://legal.hubspot.com/de/terms-of-service</span>
            </a>
            <br />
        </p>
        <b>4.6.2. Rechtsgrundlage für die Datenverarbeitung</b>
        <p>
            Die Datenverarbeitung Ihrer im Kontaktformular angegebenen Daten
            durch HubSpot erfolgt gemäß Art. 6 Abs. 1 S. 1 lit. b) DSGVO. Die
            Datenverarbeitung über den Tag und die Uhrzeit der E-Mail-Öffnung
            erfolgt aufgrund unseres berechtigten Interesses an einer
            kundenfreundlichen Kommunikation und der Akquise.
        </p>
        <b>4.6.3. Zweck der Datenverarbeitung</b>
        <p>
            Wir nutzen HubSpot als unseren Dienstleister, um eine effektive
            Adressverwaltung zu gewährleisten und um mit Ihnen über ein
            Kontaktformular in Kontakt treten zu können.
        </p>
        <b>4.6.4. Dauer der Datenspeicherung</b>
        <p>
            Nach Angaben von HubSpot speichert HubSpot Ihre personenbezogenen
            Daten nur so lange, wie wir Ihre personenbezogenen Daten nutzen.
            HubSpot löscht Ihre Daten, wenn wir Sie aus unserer Adresskartei
            löschen oder unseren Account bei HubSpot löschen nach einer Frist
            von 30 Tagen.
        </p>
        <b>4.6.5. Beseitigungsmöglichkeit durch die betroffene Person</b>
        <p>
            Sie haben jederzeit die Möglichkeit der Datenverarbeitung zu
            widersprechen. Hierzu kontaktieren Sie bitte unseren
            Datenschutzbeauftragten. Das Speichern des Tages und der Uhrzeit der
            E-Mail-Öffnung können Sie zudem eigenständig verhindern, indem Sie
            die Bilddateien in der E-Mail nicht herunterladen.
        </p>

        <h3>5. Registrierung als Publisher</h3>
        <h4>5.1. Beschreibung und Umfang der Datenverarbeitung</h4>
        <p>
            Sie können sich auf unserer Webseite als Publisher registrieren.
            Hierfür ist erforderlich, dass der Sie personenbezogene Daten in die
            Registrierungsmaske eingeben. Folgende Daten werden hierfür
            mindestens erhoben:
        </p>

        <ul>
            <li>
                Zugangsdaten
                <ul>
                    <li>Benutzername</li>
                    <li>E-Mail-Adresse</li>
                    <li>Passwort</li>
                    <li>Passwort Wiederholung</li>
                </ul>
            </li>
            <li>
                Hauptdaten
                <ul>
                    <li>Anrede</li>
                    <li>Vorname</li>
                    <li>Nachname</li>
                    <li>Firma</li>
                    <li>Straße</li>
                    <li>Postleitzahl</li>
                    <li>Stadt</li>
                    <li>Land</li>
                    <li>
                        Telefon
                        <br />
                    </li>
                </ul>
            </li>
            <li>
                Webseiten (Mediadaten)
                <ul>
                    <li>URLs</li>
                </ul>
            </li>
            <li>
                Akzeptieren der AGBs und Kenntnisnahme der
                Datenschutzbestimmungen
            </li>
        </ul>
        <p>
            Im weiteren Anmeldevorgang fragen wir verpflichtend Ihre
            Steuernummer ab, um Sie als Unternehmen qualifizieren zu können.
            Ebenso erhalten wir Ihre Kontodaten bzw. PayPal-E-Mail-Adresse, die
            Sie durch den Ausgleich unserer Rechnung an uns übermitteln.
        </p>
        <p>Optional können Sie zudem die folgenden Angaben machen:</p>

        <ul>
            <li>weitere E-Mail-Adressen</li>
            <li>weitere Telefonnummern</li>
            <li>Kontodaten</li>
            <li>
                Umsatzsteuer-ID
                <br />
            </li>
            <li>Nachweis als steuerpflichtiger Unternehmer</li>
        </ul>
        <p>Darüber hinaus speichern wir bei Publishern technische Daten:</p>
        <p>
            Das Datum und die Uhrzeit des letzten Logins, wann die
            API-Schnittstelle das letzte Mal durch den Publisher aufgerufen
            wurde, das Interface-Passwort, Datum und Uhrzeit der letzten
            Auszahlung, IP-Adresse der Anmeldung und die IP-Adresse des
            Anklickens des Bestätigungslinks, den wir Ihnen per E-Mail nach
            Ihrer Anmeldung zusenden (sog. Double-Opt-In).
            <br />
        </p>
        <p>
            Zur Abwicklung der Vertragsverhältnisse mit unseren
            Geschäftspartnern setzen wir die Software „Coyote“ ein. Diese wird
            bereitgestellt von: nitramIT GmbH, Poststr. 68, 44809 Bochum,
            Deutschland.
            <br />
        </p>
        <p>
            Ihre Daten werden auf dem Server von Coyote in Deutschland
            gespeichert. Zum Schutz Ihrer Daten haben wir mit Coyote einen
            Auftragsverarbeitungsvertrag geschlossen.
        </p>
        <p>
            Beim Besuch der Anmelde-Seite speichert Coyote zudem folgende Daten,
            die nach sieben Tagen gelöscht werden:
        </p>

        <ul>
            <li>IP-Adresse</li>
            <li>Datum und Uhrzeit der Anfrage</li>
            <li>Zeitzonendifferenz zur Greenwich Mean Time (GMT)</li>
            <li>Inhalt der Anforderung (konkrete Seite)</li>
            <li>Zugriffsstatus/HTTP-Statuscode</li>
            <li>jeweils übertragene Datenmenge</li>
            <li>Meldung über erfolgreichen Abruf</li>
            <li>Referrer URL der zuvor besuchten Website</li>
            <li>
                die Unterwebseiten, welche über ein zugreifendes System auf
                unserer Website angesteuert werden
            </li>
        </ul>
        <p>
            <span>
                Weitere Informationen zum Datenschutz der Software Coyote finden
                Sie hier:
                <a href="https://www.nitramit.de/datenschutz/">
                    <span>nitramIT – Datenschutzerklärung</span>
                </a>
                und
                <a href="https://www.coyote-software.de/">
                    <span>
                        Coyote, die umfassende Affiliate-Marketing Software
                        (coyote-software.de)
                    </span>
                </a>
            </span>
        </p>

        <h4>5.2. Rechtsgrundlage für die Datenverarbeitung</h4>
        <p>
            Sofern Sie personenbezogene Daten angeben, die zu den
            Pflichtfeldeingabemasken gehören, basiert die Datenverarbeitung auf
            Art. 6 Abs. 1 S.1 lit b) DSGVO. Tragen Sie darüber hinaus
            personenbezogene Daten in die übrigen (optionalen) Eingabefeldmasken
            ein, beruht die Datenverarbeitung auf Art. 6 Abs. 1 S.1 lit. a)
            DSGVO.
        </p>
        <p>
            Die Verarbeitung der technischen Daten dienen der Sicherheit der
            Systeme, sodass ein berechtigtes Interesse unsererseits besteht, das
            eine Speicherung der Daten gemäß Art. 6 Abs. 1 S. 1 lit. f) DSGVO
            gestattet.
        </p>

        <h4>5.3. Zweck der Datenverarbeitung</h4>
        <p>
            Wir verarbeiten Ihre Daten ausschließlich zu dem Zweck Ihre
            Registrierung abzuschließen und Ihr Webseitenkonto bei uns zu
            führen.
        </p>

        <h4>5.4. Dauer der Datenspeicherung</h4>
        <p>
            Die Daten werden gelöscht, sobald sie für die Erreichung des Zweckes
            ihrer Erhebung nicht mehr erforderlich sind. Dies ist der Fall, wenn
            Sie Ihr Konto bei uns schließen und keine gesetzlichen oder
            behördlichen Aufbewahrungsfristen einer Löschung entgegenstehen.
        </p>

        <h4>5.5. Beseitigungsmöglichkeit durch die betroffene Person</h4>
        <p>
            Die von Ihnen zur Verfügung gestellten Daten sowie die zum Nachweis
            der Leistungserbringung und Vertragsabwicklung erforderlichen Daten
            können Sie in dem Kundenkonto jederzeit einsehen. Sowohl während als
            auch nach der erfolgten Registrierung steht es Ihnen frei, die
            Angabe der personenbezogenen Daten zu ändern, zu korrigieren oder zu
            löschen.
        </p>
        <p>
            Sie haben jederzeit die Möglichkeit der Datenverarbeitung zu
            widersprechen und erteilte Einwilligungen zu widerrufen. Hierfür
            kontaktieren Sie bitte unseren Datenschutzbeauftragten.
        </p>

        <h3>6. Registrierung als Advertiser</h3>
        <h4>6.1. Beschreibung und Umfang der Datenverarbeitung</h4>
        <p>
            Als Advertiser können Sie sich bei uns persönlich anmelden. Bei der
            Anmeldung fragen wir folgende Daten verpflichtend ab:
            <br />
        </p>

        <ul>
            <li>
                Firma
                <br />
            </li>
            <li>Vorname</li>
            <li>Nachname</li>
            <li>
                Adresse
                <br />
            </li>
            <li>Startdatum des Vertragsbeginns</li>
            <li>Kontodaten zur Überweisung von Gutschriften</li>
        </ul>
        <p>Optional können Sie zudem folgende Daten angeben:</p>

        <ul>
            <li>Telefonnummer</li>
        </ul>
        <p>Darüber hinaus werden die folgenden Daten von uns verarbeitet:</p>
        <p>
            Datum der Anmeldung, Passwort (verschlüsselt), Einstufung des
            Advertisers für die Netzwerkgebühr, bisherige Einzahlungen,
            bisherige Umsätze (Ausgaben), Werber-ID, IP-Adresse der Anmeldung
            und IP-Adresse des Anklickens des Bestätigungslinks, den wir Ihnen
            per E-Mail nach Ihrer Anmeldung zusenden (sog. Double-Opt-In).
        </p>
        <p>
            Zur Abwicklung der Vertragsverhältnisse mit unseren
            Geschäftspartnern setzen wir die Software „Coyote“ ein. Diese wird
            bereitgestellt von: nitramIT GmbH, Poststr. 68, 44809 Bochum,
            Deutschland.
            <br />
        </p>
        <p>
            Ihre Daten werden auf dem Server von Coyote in Deutschland
            gespeichert. Zum Schutz Ihrer Daten haben wir mit Coyote einen
            Auftragsverarbeitungsvertrag geschlossen.
        </p>
        <p>
            Beim Besuch der Anmelde-Seite speichert Coyote zudem folgende Daten,
            die nach sieben Tagen gelöscht werden:
        </p>

        <ul>
            <li>IP-Adresse</li>
            <li>Datum und Uhrzeit der Anfrage</li>
            <li>Zeitzonendifferenz zur Greenwich Mean Time (GMT)</li>
            <li>Inhalt der Anforderung (konkrete Seite)</li>
            <li>Zugriffsstatus/HTTP-Statuscode</li>
            <li>jeweils übertragene Datenmenge</li>
            <li>Meldung über erfolgreichen Abruf</li>
            <li>Referrer URL der zuvor besuchten Website</li>
            <li>
                die Unterwebseiten, welche über ein zugreifendes System auf
                unserer Website angesteuert werden
            </li>
        </ul>
        <p>
            <span>
                Weitere Informationen zum Datenschutz der Software Coyote finden
                Sie hier:
                <a href="https://www.nitramit.de/datenschutz/">
                    <span>nitramIT – Datenschutzerklärung</span>
                </a>
                und
                <a href="https://www.coyote-software.de/">
                    <span>
                        Coyote, die umfassende Affiliate-Marketing Software
                        (coyote-software.de)
                    </span>
                </a>
            </span>
        </p>

        <h4>6.2. Rechtsgrundlage für die Datenverarbeitung</h4>
        <p>
            Sofern Sie personenbezogene Daten angeben, die zu den
            Pflichtfeldeingabemasken gehören, basiert die Datenverarbeitung auf
            Art. 6 Abs. 1 S.1 lit b) DSGVO. Tragen Sie darüber hinaus
            personenbezogene Daten in die übrigen (optionalen) Eingabefeldmasken
            ein, beruht die Datenverarbeitung auf Art. 6 Abs. 1 S.1 lit. a)
            DSGVO.
        </p>
        <p>
            Die Verarbeitung der technischen Daten dienen der Sicherheit der
            Systeme, sodass ein berechtigtes Interesse unsererseits besteht, das
            eine Speicherung der Daten gemäß Art. 6 Abs. 1 S. 1 lit. f) DSGVO
            gestattet.
        </p>

        <h4>6.3. Zweck der Datenverarbeitung</h4>
        <p>
            Wir verarbeiten Ihre Daten ausschließlich zu dem Zweck, Ihre
            Registrierung abzuschließen und Ihr Webseitenkonto bei uns zu
            führen.
        </p>

        <h4>6.4. Dauer der Datenspeicherung</h4>
        <p>
            Die Daten werden gelöscht, sobald sie für die Erreichung des Zweckes
            ihrer Erhebung nicht mehr erforderlich sind. Dies ist der Fall, wenn
            Sie Ihr Konto bei uns schließen und keine gesetzlichen oder
            behördlichen Aufbewahrungsfristen einer Löschung entgegenstehen.
        </p>

        <h4>6.5. Beseitigungsmöglichkeit durch die betroffene Person</h4>
        <p>
            Die von Ihnen zur Verfügung gestellten Daten sowie die zum Nachweis
            der Leistungserbringung und Vertragsabwicklung erforderlichen Daten
            können Sie in dem Kundenkonto jederzeit einsehen. Sowohl während als
            auch nach der erfolgten Registrierung steht es Ihnen frei, die
            Angabe der personenbezogenen Daten zu ändern, zu korrigieren oder zu
            löschen.
        </p>
        <p>
            Sie haben jederzeit die Möglichkeit der Datenverarbeitung zu
            widersprechen und erteilte Einwilligungen zu widerrufen. Hierfür
            kontaktieren Sie bitte unseren Datenschutzbeauftragten.
        </p>

        <h3>7. Datenverarbeitung im Rahmen von Bewerbungen</h3>
        <h4>7.1. Beschreibung und Umfang der Datenverarbeitung</h4>
        <p>
            Über unsere Webseite ist es möglich, sich mittels E-Mail an
            <a href="mailto:info@mobility-ads.de">
                <span>info&#64;mobility-ads.de</span>
            </a>
            bei uns zu bewerben. Hierfür werden personenbezogene Daten
            verarbeitet und zur weiteren Verarbeitung für das jeweilige
            Bewerbungsverfahren gespeichert.
            <br />
        </p>

        <h4>7.2. Rechtsgrundlage für die Datenverarbeitung</h4>
        <p>
            Die Rechtsgrundlagen für die Datenverarbeitung sind Art. 88 DSGVO
            und § 26 BDSG.
        </p>

        <h4>7.3. Zweck der Datenverarbeitung</h4>
        <p>
            Wir verarbeiten Ihre Daten ausschließlich zu dem Zweck das
            Bewerbungsverfahren durchzuführen.
        </p>

        <h4>7.4. Dauer der Datenspeicherung</h4>
        <p>
            Sofern die Bewerbung zur Aufnahme eines Arbeitsverhältnisses führen
            sollte, werden die personenbezogenen Daten unter Achtung der
            gesetzlichen Vorschriften entsprechend gespeichert. Sollte die
            Bewerbung bei der Auswahl eines potenziellen Kandidaten nicht
            berücksichtigt werden, wird diese nach den Regeln des hiesigen
            Löschkonzepts gelöscht, wobei die Vorschriften zum AGG, insbesondere
            der bestehenden Beweispflicht nach § 22 AGG, Berücksichtigung
            finden.
        </p>
        <p>
            Dies gilt nicht, sofern gesetzliche Bestimmungen der Löschung
            entgegenstehen oder Sie einer längeren Speicherung Ihre Einwilligung
            erteilt haben. In diesem Falle erfolgt die weitergehende Speicherung
            Ihrer personenbezogenen Daten auf Grundlage des Art. 6 Abs. 1 S. 1
            lit. c) bzw. lit. a) DSGVO.
        </p>

        <h4>7.5. Beseitigungsmöglichkeit durch die betroffene Person</h4>
        <p>
            Sie können jederzeit mit uns Kontakt aufnehmen und einer weiteren
            Verarbeitung Ihrer Daten widersprechen. Alle personenbezogenen
            Daten, die im Zuge des Bewerbungsverfahrens von uns verarbeitet
            wurden, werden in diesem Fall gelöscht, es sei denn einer Löschung
            zwingende gesetzliche Vorschriften entgegen.
        </p>

        <h3>8. Produkt-E-Mails</h3>
        <h4>8.1. Beschreibung und Umfang der Datenverarbeitung</h4>
        <p>
            Unsere Bestandskunden können von uns Produkt-E-Mails erhalten, die
            Informationen und Neuerungen zu unserem Produkt beinhalten. Hierfür
            wird Ihr angegebener Name und Ihre E-Mail-Adresse verarbeitet. Eine
            Weitergabe der Daten an Dritte erfolgt grundsätzlich nicht.
        </p>

        <h4>8.2. Rechtsgrundlage für die Datenverarbeitung</h4>
        <p>
            Dies erfolgt jedoch nur in den engen Grenzen des § 7 Abs. 3 UWG,
            welcher im Lichte des Art. 95 DSGVO spiegelbildlich zu Art. 6 Abs. 1
            S.1 lit. f) DSGVO zu verstehen ist. Unser berechtigtes Interesse
            besteht darin, unsere Bestandskunden durch werbliche E-Mails über
            unsere Produkte zu informieren und so den Kontakt zu diesen Kunden
            aufrechtzuerhalten.
        </p>

        <h4>8.3. Zweck der Datenverarbeitung</h4>
        <p>
            Die Produkt-E-Mails haben die Funktion, Ihnen Informationen zu
            Neuerungen in unserem Produkt oder Hinweise zukommen zu lassen.
            <br />
        </p>

        <h4>8.4. Dauer der Datenspeicherung</h4>
        <p>
            Ihre Daten verarbeiten wir nur so lange, wie dies zur Zweckerfüllung
            notwendig ist und einer Löschung keine gesetzlichen oder
            behördlichen Aufbewahrungspflichten entgegenstehen.
        </p>

        <h4>8.5. Beseitigungsmöglichkeit durch die betroffene Person</h4>
        <p>
            Sie haben jederzeit die Möglichkeit der Datenverarbeitung zu diesem
            Zweck zu widersprechen. Hierzu können Sie den in jedem Newsletter
            integrierten Link zum Abbestellen klicken oder Ihre
            Profileinstellungen anpassen.
        </p>

        <h3>9. Social Media</h3>
        <p>
            Wir haben auf unserer Webseite die Social Media-Plattformen
            Facebook, Instagram und LinkedIn über Links eingebunden, die dazu
            führen, dass die Social Media- Anbieter ggf. Daten von Ihnen
            erhalten. Sofern Sie auf den Social Media-Link klicken, wird die
            Webseite des jeweiligen Social Media-Anbieters aufgerufen. Durch den
            Aufruf der Webseiten des jeweiligen Social Media-Anbieters über
            unsere Webseite werden dem jeweiligen Social Media-Anbieter die
            jeweiligen Bezugsdaten von uns übermittelt. Der Social
            Media-Anbieter erhält dadurch die Information, dass Sie uns besucht
            haben.
            <br />
        </p>
        <p><b>Hinweis zur Datenverarbeitung in den USA:</b></p>
        <p>
            Wenn Sie auf einen Social Media-Link klicken, werden möglicherweise
            Daten von Ihnen durch den jeweiligen Anbieter in den USA
            verarbeitet. Der Datenschutzstandard in den USA ist nach Ansicht des
            EuGHs unzureichend und es besteht die Gefahr, dass Ihre Daten durch
            die US-Behörden zu Kontroll- und Überwachungszwecken, möglicherweise
            auch ohne Rechtsbehelfsmöglichkeiten, verarbeitet werden. Sofern Sie
            nicht auf die Links der Social Media-Anbieter klicken, findet eine
            Datenübermittlung nicht statt.
        </p>
        <p>
            Weitere Informationen zur Datenverarbeitung durch die Social
            Media-Anbieter finden Sie hier:
        </p>
        <p>
            <span>
                <b>Facebook:</b>
                <a href="https://de-de.facebook.com/help/pages/insights">
                    <span>https://de-de.facebook.com/help/pages/insights</span>
                </a>
                ,
                <br />
            </span>
        </p>
        <p>
            <span>
                <a href="https://de-de.facebook.com/about/privacy">
                    https://de-de.facebook.com/about/privacy
                </a>
            </span>
            <span>
                ,
                <br />
            </span>
        </p>
        <p>
            <span>
                <a href="https://de-de.facebook.com/full_data_use_policy">
                    https://de-de.facebook.com/full_data_use_policy
                </a>
            </span>
            <span>
                <br />
            </span>
        </p>
        <p>
            <span>
                <b>Instagram:</b>
                <a href="https://help.instagram.com/155833707900388">
                    <span>https://help.instagram.com/155833707900388</span>
                </a>
            </span>
        </p>
        <p>
            <span>
                <b>LinkedIn:</b>
                <a href="https://www.linkedin.com/legal/privacy-policy">
                    <span>https://www.linkedin.com/legal/privacy-policy</span>
                </a>
            </span>
        </p>

        <h3>10. Tools von Drittanbietern</h3>
        <p>
            Des Weiteren nutzen wir Drittanbieter, die uns bei der
            Seitendarstellung und der Funktionalität der Webseite helfen. Diese
            sind im Folgenden aufgeführt:
        </p>

        <h4>10.1. Google Analytics</h4>
        <p>
            <b style="font-size: 16px">
                10.1.1. Beschreibung und Umfang der Datenverarbeitung
            </b>
        </p>
        <p>
            Unser Internetangebot nutzt Google Analytics. Dies ist ein Dienst
            zur Analyse von Zugriffen auf Webseiten der Google LLC. („Google“)
            und ermöglicht uns die Verbesserung unseres Internetangebotes. Die
            Datenverarbeitung für den europäischen Wirtschaftsraum und für die
            Schweiz wird durchgeführt von: Google Ireland Limited, Gordon House,
            Barrow Street, Dublin 4, Irland.
        </p>
        <p><b>Hinweis zur Datenverarbeitung in den USA:</b></p>
        <p>
            Indem Sie der Datenverarbeitung durch Google Analytics im
            Cookiebanner zustimmen, erteilen Sie gemäß Art. 6 Abs. 1 S. 1 lit.
            a) i.V.m. Art. 49 Abs. 1 S.1 lit. a) DSGVO Ihre Einwilligung, dass
            Ihre Daten möglicherweise durch Google in den USA verarbeitet
            werden. Der Datenschutzstandard in den USA ist nach Ansicht des
            EuGHs unzureichend und es besteht die Gefahr, dass Ihre Daten durch
            die US-Behörden zu Kontroll- und Überwachungszwecken, möglicherweise
            auch ohne Rechtsbehelfsmöglichkeiten, verarbeitet werden. Sofern Sie
            nur notwendigen Cookies zustimmen, findet eine Datenübermittlung
            durch Google Analytics nicht statt. Eine erteilte Einwilligung kann
            jederzeit widerrufen werden.
        </p>
        <p>
            Durch Cookies wird uns die Analyse Ihrer Nutzung unseres
            Internetangebotes ermöglicht. Die mittels eines Cookies erhobenen
            Informationen sind:
            <br />
        </p>

        <ul>
            <li>
                IP-Adresse,
                <br />
            </li>
            <li>
                Zugriffszeitpunkt,
                <br />
            </li>
            <li>Zugriffsdauer</li>
        </ul>
        <p>
            und werden an einen Server von Google in den USA übertragen und dort
            gespeichert. Die Auswertung Ihrer Aktivitäten auf unserem
            Internetangebot wird uns in Form von Berichten übermittelt. Google
            gibt die erhobenen Informationen gegebenenfalls an Dritte weiter,
            sofern dies gesetzlich vorgeschrieben ist oder soweit Dritte diese
            Daten im Auftrag von Google verarbeiten.
            <span class="s8">
                Die Google-Trackingcodes unseres Internetangebotes verwenden die
                Funktion „_anonymizeIp()“, somit werden IP-Adressen nur gekürzt
                weiterverarbeitet, um eine gegebenenfalls mögliche direkte
                Personenbeziehbarkeit mit Ihnen auszuschließen.
            </span>
            <br />
            Unter
            <a href="https://www.google.de/intl/de/policies/">
                <span>https://www.google.de/intl/de/policies/</span>
            </a>
            sowie unter
            <a href="http://www.google.com/analytics/terms/de.html">
                <span>http://www.google.com/analytics/terms/de.html</span>
            </a>
            finden Sie nähere Informationen zu den Nutzungsbedingungen und dem
            Datenschutz von Google Analytics.
        </p>
        <p>
            <strong>10.1.2. Rechtsgrundlage für die Datenverarbeitung</strong>
        </p>
        <p>
            Die Rechtsgrundlage für die Verarbeitung der personenbezogenen Daten
            ist Ihre Einwilligung gemäß Art. 6 Abs. 1 S.1 lit. a) DSGVO.
        </p>
        <p><strong>10.1.3. Zweck der Datenverarbeitung</strong></p>
        <p>
            Die Verarbeitung Ihrer personenbezogenen Daten ermöglicht uns eine
            Analyse Ihres Surfverhaltens. Wir sind in durch die Auswertung der
            gewonnen Daten in der Lage, Informationen über die Nutzung der
            einzelnen Komponenten unserer Webseite zusammenzustellen. Dies hilft
            uns dabei unsere Webseite und deren Nutzerfreundlichkeit stetig zu
            verbessern.
        </p>
        <p><strong>10.1.4. Dauer der Datenspeicherung</strong></p>
        <p>
            <span class="s8">
                Die Daten werden 14 Monate nach Ihrem letzten Besuch auf unserer
                Webseite gelöscht.
            </span>
        </p>
        <p>
            <strong>
                10.1.5. Beseitigungsmöglichkeit durch die betroffene Person
            </strong>
        </p>
        <p>
            Sie haben jederzeit die Möglichkeit, eine erteilte Einwilligung in
            die Datenverarbeitung mit Wirkung für die Zukunft zu widerrufen.
            Bitte kontaktieren Sie hierfür unseren Datenschutzbeauftragten. Sie
            können die Installation der Cookies von Google Analytics auch durch
            eine entsprechende Einstellung Ihrer Browser Software selbst
            unterbinden. In diesem Fall kann es allerdings vorkommen, dass Sie
            nicht sämtliche Funktionen unseres Internetangebotes voll umfänglich
            nutzen können. Auch durch Browsererweiterungen z. B.
            <a href="http://tools.google.com/dlpage/gaoptout?hl=de">
                <span>http://tools.google.com/dlpage/gaoptout?hl=de</span>
            </a>
            kann Google Analytics deaktiviert und kontrolliert werden.
            <br />
        </p>

        <h4>10.2. Google Web Fonts</h4>
        <b>10.2.1. Beschreibung und Umfang der Datenverarbeitung</b>
        <p>
            Wir nutzen auf der Webseite zur einheitlichen Darstellung von
            Schriftarten sogenannte Web Fonts. Die Datenverarbeitung für den
            europäischen Wirtschaftsraum und für die Schweiz wird durchgeführt
            von: Google Ireland Limited, Gordon House, Barrow Street, Dublin 4,
            Irland.
        </p>
        <p>
            Beim Aufruf einer Seite lädt Ihr Browser die benötigten Web Fonts in
            ihren Browsercache, um Texte und Schriftarten korrekt anzuzeigen. Zu
            diesem Zweck muss der von Ihnen verwendete Browser Verbindung zu den
            Servern von Google aufnehmen. Hierdurch erlangt Google Kenntnis
            darüber, dass über Ihre IP-Adresse unsere Webseite aufgerufen wurde.
            Wenn Ihr Browser Web Fonts nicht unterstützt, wird eine
            Standardschrift von Ihrem Computer genutzt.
        </p>
        <p>
            Weitere Informationen zu Google Web Fonts finden Sie unter
            <a href="https://developers.google.com/fonts/faq">
                <span>https://developers.google.com/fonts/faq</span>
            </a>
            und in der Datenschutzerklärung von Google:
            <a href="https://www.google.com/policies/privacy/">
                <span>https://www.google.com/policies/privacy/</span>
            </a>
            .
        </p>
        <b>10.2.2. Rechtsgrundlage für die Datenverarbeitung</b>
        <p>
            Die Rechtsgrundlage beruht auf Art. 6 Abs. 1 S. 1 lit. f) DSGVO. Es
            ist unser berechtigtes Interesse, Ihnen eine visuell interessante
            und gleichzeitig nutzerfreundliche Webseite anbieten zu können.
        </p>
        <b>10.2.3. Zweck der Datenverarbeitung</b>
        <p>
            Der Zweck der Datenverarbeitung ist die einheitliche Darstellung von
            Schriftarten auf dieser Webseite. Wir könnten Ihnen unser online
            Angebot sonst nicht effektiv darstellen.
        </p>
        <b>10.2.4. Dauer der Datenspeicherung</b>
        <p>
            Die Daten werden gelöscht, sobald sie für den Zweck der
            Datenverarbeitung nicht mehr benötigt werden, es sei denn, dass
            gesetzliche, behördliche oder vertragliche Vorschriften einer
            Löschung entgegenstehen.
        </p>
        <b>10.2.5. Beseitigungsmöglichkeit durch die betroffene Person</b>
        <p>
            Sie können Ihren Browser so einstellen, dass die Schriften nicht von
            den Google-Servern geladen werden. Falls Ihr Browser Google Fonts
            nicht unterstützt oder Sie den Zugriff auf die Google-Server
            unterbinden, wird der Text in der Standardschrift des Systems
            angezeigt.
            <br />
        </p>

        <h4>10.3. Google reCAPTCHA</h4>
        <b>10.3.1. Beschreibung und Umfang der Datenverarbeitung</b>
        <p>
            Wir nutzen Google reCAPTCHA, um uns vor übermäßigem Spam zu
            schützen. Dieses Programm soll sicherstellen, dass es sich bei dem
            Anfragenden um einen Menschen und nicht um ein automatisiertes
            Programm handelt. Die Datenverarbeitung für den Europäischen
            Wirtschaftsraum und für die Schweiz wird durchgeführt von: Google
            Ireland Limited, Gordon House, Barrow Street, Dublin 4, Ireland.
        </p>
        <p>
            Google erfasst automatisiert die IP-Adresse, den Zugriffsort und den
            Zeitpunkt des Zugriffs. Außerdem wird das Verhalten des
            Webseitenbesuchers anhand verschiedener Merkmale (z. B. Verweildauer
            des Besuchers auf der Webseite, getätigte Mausbewegungen, im
            Browserverlauf gespeicherte Cookies) analysiert. Diese Analyse durch
            Google beginnt automatisch, sobald Sie eine Seite aufrufen, die
            Google reCAPTCHA nutzt.
        </p>
        <p>
            <span>
                Weitere Informationen zum Umgang mit personenbezogenen Daten
                finden Sie in der Datenschutzerklärung von Google
                <a href="https://www.google.de/intl/de/policies/privacy/">
                    <span>https://www.google.de/intl/de/policies/privacy/</span>
                </a>
                , unter
                <a
                    href="https://policies.google.com/technologies/partner-sites">
                    <span>
                        https://policies.google.com/technologies/partner-sites
                    </span>
                </a>
                und unter
                <a href="https://www.google.com/recaptcha">
                    <span>https://www.google.com/recaptcha</span>
                </a>
            </span>
        </p>
        <b>10.3.2. Rechtsgrundlage für die Datenverarbeitung</b>
        <p>
            Die Datenverarbeitung wird auf Art. 6 Abs. 1 S. 1 lit. f) DSGVO
            gestützt. Unser berechtigtes Interesse liegt in der Abwehr von
            Spam-Mail.
        </p>
        <b>10.3.3. Zweck der Datenverarbeitung</b>
        <p>
            Der Zweck der Datenverarbeitung deckt sich mit unserem berechtigten
            Interesse. Durch Google reCAPTCHA wird sichergestellt, dass sich
            hinter den Anfragen eine natürliche Person mit potenziellem
            Interesse befindet. Durch diese Einschränkung der Menge der Anfragen
            können wir auf die einzelnen Anfragen schneller und effizienter
            eingehen und sichern unsere Webseite zugleich gegenüber
            automatisiert verbreiteter Schadsoftware ab.
        </p>
        <b>10.3.4. Dauer der Datenspeicherung</b>
        <p>
            Ihre personenbezogenen Daten werden nur so lange verarbeitet, wie es
            notwendig ist. Sobald der Zweck der Datenverarbeitung erfüllt ist,
            findet nach den Maßstäben des hiesigen Löschkonzepts eine Sperrung
            und Löschung statt, es sei denn, dass gesetzliche Vorschriften einer
            Löschung entgegenstehen.
        </p>
        <b>10.3.5. Beseitigungsmöglichkeit durch die betroffene Person</b>
        <p>
            Google reCAPTCHA dient dem sicheren Betrieb dieser Internetseiten
            und der dahinter liegenden Infrastruktur, aufgrund dessen ist von
            unserer Seite keine Möglichkeit vorgesehen, diesen
            Sicherheitsmechanismus zu deaktivieren. Sie haben allerdings die
            Wahl, das Kontaktformular nicht zu verwenden und stattdessen eine
            E-Mail oder einen Brief an die oben angegebene Kontaktadresse zu
            schicken oder uns telefonisch zu kontaktieren.
        </p>

        <h4>10.4. Google Maps</h4>
        <b>10.4.1. Beschreibung und Umfang der Datenverarbeitung</b>
        <p>
            Diese Webseite verwendet das Produkt Google Maps von Google LLC. Die
            Datenverarbeitung für den europäischen Wirtschaftsraum und für die
            Schweiz wird durchgeführt von: Google Ireland Limited, Gordon House,
            Barrow Street, Dublin 4, Irland.
        </p>
        <p>
            Beim Aufruf einer Seite lädt Ihr Browser die benötigten
            Geo-Informationen in ihren Browsercache, um die Karte korrekt
            anzuzeigen. Zu diesem Zweck muss der von Ihnen verwendete Browser
            Verbindung zu den Servern von Google aufnehmen. Hierdurch erlangt
            Google Kenntnis darüber, dass über Ihre IP-Adresse unsere Webseite
            aufgerufen wurde und welche Karte angezeigt wurde.
            <br />
        </p>
        <p><b>Hinweis zur Datenverarbeitung in den USA:</b></p>
        <p>
            Wenn Sie Google Maps verwenden und der Datenverarbeitung zustimmen,
            werden möglicherweise Daten von Ihnen durch Google in den USA
            verarbeitet. Der Datenschutzstandard in den USA ist nach Ansicht des
            EuGHs unzureichend und es besteht die Gefahr, dass Ihre Daten durch
            die US-Behörden zu Kontroll- und Überwachungszwecken, möglicherweise
            auch ohne Rechtsbehelfsmöglichkeiten, verarbeitet werden.
            <br />
        </p>
        <p>
            Die Nutzungsbedingungen von Google Maps finden sie unter
            <a href="https://www.google.com/intl/de_de/help/terms_maps.html">
                <span>
                    https://www.google.com/intl/de_de/help/terms_maps.html
                </span>
            </a>
            <span>
                <br />
            </span>
        </p>
        <b>10.4.2. Rechtsgrundlage für die Datenverarbeitung</b>
        <p>
            Die Rechtsgrundlage für die Datenverarbeitung ist Ihre Einwilligung
            gemäß Art. 6 Abs. 1 S. 1 lit. a) DSGVO.
            <br />
        </p>
        <b>10.4.3. Zweck der Datenverarbeitung</b>
        <p>
            Die Nutzung von Google Maps erleichtert es Ihnen, unseren Standort
            zu finden und mit ihm in unterschiedlicher Weise z. B. durch
            Routenplanung zu interagieren.
        </p>
        <b>10.4.4. Dauer der Datenspeicherung</b>
        <p>
            Die Daten werden gelöscht, sobald sie für den Zweck der
            Datenverarbeitung nicht mehr benötigt werden, es sei denn, dass
            gesetzliche, behördliche oder vertragliche Vorschriften einer
            Löschung entgegenstehen.
        </p>
        <b>10.4.5. Beseitigungsmöglichkeit durch die betroffene Person</b>
        <p>
            Sie haben jederzeit die Möglichkeit, eine erteilte Einwilligung in
            die Datenverarbeitung zu widerrufen. Hierfür wenden Sie sich bitte
            an unseren Datenschutzbeauftragten.
        </p>

        <h3>11. Datentransfer in ein Drittland</h3>
        <p>
            Damit wir unsere Leistungen erbringen können, bedienen wir uns der
            Unterstützung von Diensteanbietern aus dem europäischen Raum als
            auch aus Drittstaaten. Um den Schutz Ihrer personenbezogenen Daten
            auch im Fall der Datenübermittlung in ein Drittland sicherzustellen,
            schließen wir mit jedem der sorgfältig ausgewählten Diensteanbieter
            spezielle Auftragsverarbeitungsverträge. Alle von uns genutzten
            Diensteanbieter verfügen über hinreichende Nachweise, dass Sie die
            Datensicherheit über geeignete technische und organisatorische
            Maßnahmen sicherstellen. Unsere Diensteanbieter aus Drittländern
            befinden sich entweder in Ländern, die über ein von der
            EU-Kommission anerkanntes, angemessenes Datenschutzniveau verfügen
            (Art. 45 DSGVO) oder haben geeignete Garantien vorgesehen (Art. 46
            DSGVO).
            <br />
        </p>
        <p>
            <b><i>Angemessenes Schutzniveau:</i></b>
            Der Anbieter stammt aus einem Land, dessen angemessenes
            Datenschutzniveau von der EU-Kommission anerkannt wurde.
            <br />
            Nähere Informationen finden Sie unter:
            <a
                href="https://ec.europa.eu/info/law/law-topic/data-protection/data-transfers-outside-eu/adequacy-protection-personal-data-non-eu-countries_en">
                <span>
                    https://ec.europa.eu/info/law/law-topic/data-protection/data-transfers-outside-eu/adequacy-protection-personal-data-non-eu-countries_en
                </span>
            </a>
            <br />
        </p>
        <p>
            <b><i>EU-Standardvertragsklauseln:</i></b>
            Unser Anbieter hat sich den EU-Standardvertragsklauseln unterworfen,
            um einen sicheren Datentransfer zu gewährleisten. Nähere
            Informationen hierzu finden Sie unter:
            <a
                href="https://eur-lex.europa.eu/LexUriServ/LexUriServ.do?uri=OJ:L:2010:039:0005:0018:DE:PDF">
                <span>
                    https://eur-lex.europa.eu/LexUriServ/LexUriServ.do?uri=OJ:L:2010:039:0005:0018:DE:PDF
                </span>
            </a>
            <br />
        </p>
        <p>
            <b><i>Binding Corporate Rules:</i></b>
            Die DSGVO sieht mit Art. 47 die Möglichkeit vor, den Datenschutz
            beim Datentransfer in einen Drittstaat über verbindliche interne
            Datenschutzvorschriften zu gewährleisten. Diese werden von den
            zuständigen Aufsichtsbehörden im Rahmen des Kohärenzverfahrens nach
            Art. 63 DSGVO geprüft und abgenommen.
            <br />
        </p>
        <p>
            <b><i>Einwilligung:</i></b>
            Außerdem findet ein Datentransfer in ein Drittland ohne angemessenes
            Schutzniveau nur dann statt, wenn Sie uns gem. Art. 49 Abs. 1 lit.
            a) DSGVO hierfür Ihre Einwilligung erteilt haben oder eine andere
            Ausnahme nach Art. 49 DSGVO für die Datenübermittlung einschlägig
            ist.
        </p>

        <h3>
            <b>
                12. Ihre Rechte
                <br />
            </b>
        </h3>
        <p>
            Sie haben gegenüber uns folgende Rechte hinsichtlich der Sie
            betreffenden personenbezogenen Daten:
            <span class="s9">
                <br />
            </span>
        </p>

        <h4>12.1. Recht auf Widerruf der Einwilligung (vgl. Art. 7 DSGVO)</h4>
        <p>
            Falls Sie eine Einwilligung zur Verarbeitung Ihrer Daten erteilt
            haben, können Sie diese jederzeit widerrufen. Ein solcher Widerruf
            beeinflusst die Zulässigkeit der Verarbeitung Ihrer
            personenbezogenen Daten für die Zukunft, nachdem Sie ihn gegenüber
            uns ausgesprochen haben. Er kann (fern-)mündlich oder schriftlich
            per Post oder E-Mail an uns erfolgen.
        </p>

        <h4>12.2. Recht auf Auskunft (vgl. Art. 15 DSGVO)</h4>
        <p>
            Im Falle eines Auskunftsersuchens müssen Sie hinreichende Angaben zu
            Ihrer Identität machen und einen Nachweis erbringen, dass es sich um
            Ihre Informationen handelt. Die Auskunft betrifft folgende
            Informationen:
        </p>

        <ul>
            <li>
                die Zwecke, zu denen die personenbezogenen Daten verarbeitet
                werden;
            </li>
            <li>
                die Kategorien von personenbezogenen Daten, welche verarbeitet
                werden;
            </li>
            <li>
                die Empfänger bzw. die Kategorien von Empfängern, gegenüber
                denen die Sie betreffenden personenbezogenen Daten offengelegt
                wurden oder noch offengelegt werden;
            </li>
            <li>
                die geplante Dauer der Speicherung der Sie betreffenden
                personenbezogenen Daten oder, falls konkrete Angaben hierzu
                nicht möglich sind, Kriterien für die Festlegung der
                Speicherdauer;
            </li>
            <li>
                das Bestehen eines Rechts auf Berichtigung oder Löschung der Sie
                betreffenden personenbezogenen Daten, eines Rechts auf
                Einschränkung der Verarbeitung durch den Verantwortlichen oder
                eines Widerspruchsrechts gegen diese Verarbeitung;
            </li>
            <li>
                das Bestehen eines Beschwerderechts bei einer Aufsichtsbehörde;
            </li>
            <li>
                alle verfügbaren Informationen über die Herkunft der Daten, wenn
                die personenbezogenen Daten nicht bei der betroffenen Person
                erhoben werden;
            </li>
            <li>
                das Bestehen einer automatisierten Entscheidungsfindung
                einschließlich Profiling gemäß Art. 22 Abs. 1 und 4 DSGVO und –
                zumindest in diesen Fällen – aussagekräftige Informationen über
                die involvierte Logik sowie die Tragweite und die angestrebten
                Auswirkungen einer derartigen Verarbeitung für die betroffene
                Person.
            </li>
        </ul>
        <h4>
            12.3 Recht auf Berichtigung oder Löschung (vgl. Art. 16, 17 DSGVO)
        </h4>
        <p>
            Sie haben ein Recht auf Berichtigung und/oder Vervollständigung
            gegenüber uns als Verantwortlichem, sofern die verarbeiteten
            personenbezogenen Daten, die Sie betreffen, unrichtig oder
            unvollständig sind. Der Verantwortliche hat die Berichtigung
            unverzüglich vorzunehmen.
        </p>
        <p>
            Zudem können Sie die Löschung der Sie betreffenden personenbezogenen
            Daten verlangen, sofern einer der folgenden Gründe auf Sie zutrifft:
        </p>

        <ul>
            <li>
                die Sie betreffenden personenbezogenen Daten sind für die
                Zwecke, für die sie erhoben oder auf sonstige Weise verarbeitet
                wurden, nicht mehr notwendig.
            </li>
            <li>
                Sie widerrufen Ihre Einwilligung, auf die sich die Verarbeitung
                gem. Art. 6 Abs. 1 S.1 lit. a) oder Art. 9 Abs. 2 lit. a) DSGVO
                stützte, und es fehlt an einer anderweitigen Rechtsgrundlage für
                die Verarbeitung.
            </li>
            <li>
                Sie legen gem. Art. 21 Abs. 1 DSGVO Widerspruch gegen die
                Verarbeitung ein und es liegen keine vorrangigen berechtigten
                Gründe für die Verarbeitung vor, oder Sie legen gem. Art. 21
                Abs. 2 DSGVO Widerspruch gegen die Verarbeitung ein.
            </li>
            <li>
                die Sie betreffenden personenbezogenen Daten wurden unrechtmäßig
                verarbeitet.
            </li>
            <li>
                die Löschung der Sie betreffenden personenbezogenen Daten ist
                zur Erfüllung einer rechtlichen Verpflichtung nach dem
                Unionsrecht oder dem Recht der Mitgliedstaaten erforderlich, dem
                der Verantwortliche unterliegt.
            </li>
            <li>
                die Sie betreffenden personenbezogenen Daten wurden in Bezug auf
                angebotene Dienste der Informationsgesellschaft gemäß Art. 8
                Abs. 1 DSGVO erhoben.
            </li>
        </ul>
        <p>
            Haben wir die Sie betreffenden personenbezogenen Daten öffentlich
            gemacht und sind wir gem. Art. 17 Abs. 1 DSGVO zu deren Löschung
            verpflichtet, so treffen wir alle zumutbaren Maßnahmen auch andere
            für die Datenverarbeitung Verantwortliche darüber zu informieren,
            dass Sie die Löschung aller Links zu diesen personenbezogenen Daten
            oder von Kopien oder Replikationen dieser personenbezogenen Daten
            verlangt haben.
        </p>
        <p>
            Das Recht auf Löschung
            <b>besteht nicht</b>, soweit die Verarbeitung erforderlich ist:
        </p>

        <ul>
            <li>
                zur Ausübung des Rechts auf freie Meinungsäußerung und
                Information;
            </li>
            <li>
                zur Erfüllung einer rechtlichen Verpflichtung, die die
                Verarbeitung nach dem Recht der Union oder der Mitgliedstaaten,
                dem der Verantwortliche unterliegt, erfordert, oder zur
                Wahrnehmung einer Aufgabe, die im öffentlichen Interesse liegt
                oder in Ausübung öffentlicher Gewalt erfolgt, die dem
                Verantwortlichen übertragen wurde;
            </li>
            <li>
                aus Gründen des öffentlichen Interesses im Bereich der
                öffentlichen Gesundheit gemäß Art. 9 Abs. 2 lit. h und i sowie
                Art. 9 Abs. 3 DSGVO;
            </li>
            <li>
                für im öffentlichen Interesse liegende Archivzwecke,
                wissenschaftliche oder historische Forschungszwecke oder für
                statistische Zwecke gem. Art. 89 Abs. 1 DSGVO, soweit das oben
                genannte Recht voraussichtlich die Verwirklichung der Ziele
                dieser Verarbeitung unmöglich macht oder ernsthaft
                beeinträchtigt, oder
            </li>
            <li>
                zur Geltendmachung, Ausübung oder Verteidigung von
                Rechtsansprüchen.
            </li>
        </ul>
        <h4>
            12.4 Recht auf Einschränkung der Verarbeitung (vgl. Art. 18 DSGVO)
        </h4>
        <p>
            Unter den folgenden Voraussetzungen können Sie die Einschränkung der
            Verarbeitung der Sie betreffenden personenbezogenen Daten von uns
            verlangen:
        </p>

        <ul>
            <li>
                wenn Sie die Richtigkeit, der Sie betreffenden personenbezogenen
                für eine Dauer bestreiten, die es uns ermöglicht, die
                Richtigkeit Ihrer personenbezogenen Daten zu überprüfen;
            </li>
            <li>
                die Verarbeitung unrechtmäßig ist und Sie die Löschung der
                personenbezogenen Daten ablehnen und stattdessen die
                Einschränkung der Nutzung der personenbezogenen Daten verlangen;
            </li>
            <li>
                wir die personenbezogenen Daten für die Zwecke der Verarbeitung
                nicht länger benötigen, Sie diese jedoch zur Geltendmachung,
                Ausübung oder Verteidigung von Rechtsansprüchen benötigen, oder
            </li>
            <li>
                wenn Sie Widerspruch gegen die Verarbeitung gemäß Art. 21 Abs. 1
                DSGVO eingelegt haben und noch nicht feststeht, ob unsere
                berechtigten Gründe gegenüber Ihren Gründen überwiegen.
            </li>
        </ul>
        <p>
            Wurde die Verarbeitung der Sie betreffenden personenbezogenen Daten
            eingeschränkt, dürfen diese Daten – von ihrer Speicherung abgesehen
            – nur mit Ihrer Einwilligung oder zur Geltendmachung, Ausübung oder
            Verteidigung von Rechtsansprüchen oder zum Schutz der Rechte einer
            anderen natürlichen oder juristischen Person oder aus Gründen eines
            wichtigen öffentlichen Interesses der Union oder eines
            Mitgliedstaats verarbeitet werden.
        </p>
        <p>
            Wurde die Einschränkung der Verarbeitung nach den o.g.
            Voraussetzungen eingeschränkt, werden Sie von uns unterrichtet,
            bevor die Einschränkung aufgehoben wird.
        </p>

        <h4>12.5. Recht auf Unterrichtung (vgl. Art. 19 DSGVO)</h4>
        <p>
            Haben Sie uns gegenüber Ihr Recht auf Berichtigung, Löschung oder
            Einschränkung der Datenverarbeitung geltend gemacht, sind wir
            verpflichtet, allen Empfängern Ihrer personenbezogenen Daten die
            Berichtigung, Löschung oder Einschränkung der Datenverarbeitung
            mitzuteilen. Dies gilt nur insoweit, wie sich diese Mitteilung nicht
            als unmöglich erweist oder mit einem unverhältnismäßigen Aufwand
            verbunden wäre.
        </p>
        <p>
            Sie haben das Recht zu erfahren, welche Empfänger Ihre Daten
            erhalten haben.
            <br />
        </p>

        <h4>12.6. Recht auf Datenübertragbarkeit (vgl. Art. 20 DSGVO)</h4>
        <p>
            Sie haben das Recht, Ihre personenbezogenen Daten in einem gängigen,
            maschinenlesbaren Format von uns zu erhalten, um sie ggf. an einen
            anderen Verantwortlichen weiterleiten zu lassen, sofern.
        </p>

        <ul>
            <li>
                die Verarbeitung auf einer Einwilligung gemäß Art. 6 Abs. 1 S.1
                lit. a) DSGVO oder Art. 9 Abs. 2 lit. a) DSGVO oder auf einem
                Vertrag gemäß Art. 6 Abs. 1 S. 1 lit. b) DSGVO beruht und
            </li>
            <li>
                die Verarbeitung mithilfe automatisierter Verfahren erfolgt.
            </li>
        </ul>
        <p>
            Bei der Ausübung Ihres Rechts auf Datenübertragbarkeit haben Sie das
            Recht zu erwirken, dass die personenbezogenen Daten direkt von uns
            einem anderen Verantwortlichen übermittelt werden, soweit dies
            technisch machbar ist.
            <br />
        </p>
        <p>
            Das Recht auf Datenübertragbarkeit gilt nicht für eine Verarbeitung
            personenbezogener Daten, die für die Wahrnehmung einer Aufgabe
            erforderlich ist, die im öffentlichen Interesse liegt oder in
            Ausübung öffentlicher Gewalt erfolgt, die uns übertragen wurde.
        </p>

        <h4>
            12.7. Recht auf Widerspruch gegen die Verarbeitung (vgl. Art. 21
            DSGVO)
        </h4>
        <p>
            Soweit wir die Verarbeitung Ihrer personenbezogenen Daten auf ein
            berechtigtes Interesse (gem. Art. 6 Abs. 1 S. 1 lit. f) DSGVO)
            unsererseits stützen, können Sie Widerspruch gegen die Verarbeitung
            einlegen. Gleiches gilt, sollten wir die Datenverarbeitung auf Art.
            6 Abs. 1 S. 1 lit. e) DSGVO stützen.
        </p>
        <p>
            Bei Ausübung eines solchen Widerspruchs bitten wir um Darlegung der
            Gründe, weshalb wir Ihre personenbezogenen Daten nicht wie von uns
            durchgeführt verarbeiten sollten. Im Falle Ihres begründeten
            Widerspruchs prüfen wir die Sachlage und werden entweder die
            Datenverarbeitung einstellen bzw. anpassen oder Ihnen unsere
            zwingenden schutzwürdigen Gründe aufzeigen, aufgrund derer wir die
            Verarbeitung fortführen.
            <br />
        </p>

        <h4>
            12.8. Recht auf Beschwerde bei der zuständigen Aufsichtsbehörde
            (vgl. Art. 77 DSGVO)
        </h4>
        <p>
            Unbeschadet eines anderweitigen verwaltungsrechtlichen oder
            gerichtlichen Rechtsbehelfs steht Ihnen das Recht auf Beschwerde bei
            einer Aufsichtsbehörde, insbesondere in dem Mitgliedstaat ihres
            Aufenthaltsorts, ihres Arbeitsplatzes oder des Orts des mutmaßlichen
            Verstoßes zu, wenn Sie der Ansicht sind, dass die Verarbeitung der
            Sie betreffenden personenbezogenen Daten gegen die DSGVO verstößt.
            <br />
        </p>
        <p>
            Die Aufsichtsbehörde, bei der die Beschwerde eingereicht wurde,
            unterrichtet Sie über den Stand und die Ergebnisse der Beschwerde
            einschließlich der Möglichkeit eines gerichtlichen Rechtsbehelfs
            nach Art. 78 DSGVO.
        </p>

        <h3>13. Wie Sie diese Rechte wahrnehmen</h3>
        <p>
            Zur Wahrnehmung dieser Rechte wenden Sie sich bitte an unseren
            Datenschutzbeauftragten:
        </p>
        <p>Kemal Webersohn von der WS Datenschutz GmbH</p>
        <p>
            <span>
                <a href="mailto:mobility-ads@ws-datenschutz.de">
                    mobility-ads&#64;ws-datenschutz.de
                </a>
            </span>
        </p>
        <p>oder per Post:</p>
        <p>WS Datenschutz GmbH</p>
        <p>
            Dircksenstraße 51
            <br />
        </p>
        <p>D-10178 Berlin</p>
        <h3>14. Änderungsvorbehalt</h3>
        <p>
            Wir behalten uns vor, diese Datenschutzerklärung unter Beachtung der
            gesetzlichen Bestimmungen zu ändern.
            <br />
        </p>
        <p>Stand Mai 2021</p>
    </div>
</section>
