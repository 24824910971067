import {
    Component,
    ElementRef,
    Inject,
    QueryList,
    ViewChildren,
    ViewEncapsulation,
    PLATFORM_ID,
} from '@angular/core'
import { HeroComponent } from '../../core/components/hero/hero.component'
import {
    BentoComponent,
    BentoItem,
} from '../../core/components/bento/bento.component'
import { BentoItemComponent } from '../../core/components/bento/bento-item/bento-item.component'
import { RouterLink } from '@angular/router'
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import { AnimationUtils } from '../../core/utils/animation.utils'
import { isPlatformBrowser } from '@angular/common'
import { Title } from '@angular/platform-browser'

@Component({
    selector: 'app-about',
    templateUrl: './about.component.html',
    styleUrls: ['./about.component.scss'],
    encapsulation: ViewEncapsulation.None,
    standalone: true,
    imports: [HeroComponent, BentoComponent, BentoItemComponent, RouterLink],
})
export class AboutComponent {
    @ViewChildren('reveal') revealTexts!: QueryList<ElementRef>
    @ViewChildren('bento', {read: ElementRef}) bentos!: QueryList<ElementRef>
    @ViewChildren('team', {read: ElementRef}) team!: QueryList<ElementRef>

    constructor(
        @Inject(PLATFORM_ID) private platformId: Object,
        private animationUtils: AnimationUtils,
        private title: Title
    ) {}

    ngOnInit(): void {
        this.title.setTitle('Mobility Ads – Ihr Online-Vertriebsexperte im Autohandel');
    }

    ngAfterViewInit(): void {
        if (isPlatformBrowser(this.platformId)) {
            gsap.registerPlugin(ScrollTrigger)
            this.animationUtils.revealText(this.revealTexts)
            this.animationUtils.animateBento(this.bentos)
            this.animationUtils.animateTeam(this.team)
        }
    }
}
