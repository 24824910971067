import { Component, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-protection',
  templateUrl: './protection.component.html',
  styleUrl: './protection.component.scss',
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [],
})
export class ProtectionComponent {

}
