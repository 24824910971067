<app-hero page="publisher"></app-hero>

<section class="quote">
    <div class="container">
        <app-bento #bento>
            <app-bento-item col="12" row="2" direction="middle" class="quote">
                <div class="quote__ceo">
                    <div class="quote__ceo-img">
                        <img
                            src="assets/img/team/alex-pp.webp"
                            alt="Alexander Piwonka" />
                    </div>
                    <div class="quote__ceo-content">
                        <div class="quote__ceo-text">
                            <p>
                                Unsere Publisher-Partner profitieren von unserer
                                Expertise und Leidenschaft für den
                                Mobilitätsmarkt. Gemeinsam entwickeln wir
                                Lösungen, die Ihre Reichweite monetarisieren und
                                Ihnen neue Umsatzquellen erschließen. Unsere
                                Partnerschaft ist familiär, sie basiert auf
                                Vertrauen, Innovation und dem gemeinsamen Ziel,
                                erfolgreich zu sein.
                            </p>
                        </div>
                        <div class="quote__ceo-author">
                            <span>Alexander Piwonka</span>
                            <small>
                                Chief Partnership Officer, Mobility Ads
                            </small>
                        </div>
                    </div>
                </div>
            </app-bento-item>
        </app-bento>
    </div>
</section>

<section class="sector">
    <div class="container">
        <h2 #reveal>
            Ihre redaktionellen Artikel haben Power!
            <br />
            Nur bleibt die Monetarisierung auf der Strecke.
        </h2>
        <p class="question-i">Sie kennen es bestimmt:</p>
        <app-bento #bento class="plain">
            <app-bento-item col="8" row="1" direction="left">
                <ul class="qa-list">
                    <li>
                        <span class="question">
                            Viel Aufwand, null Ergebnisse?
                        </span>
                        <span class="answer">
                            Sie investieren in Inhalte, die nicht ankommen. Hohe
                            Kosten, niedrige Klickzahlen – Ihr Aufwand bleibt
                            unbemerkt.
                        </span>
                    </li>
                    <li>
                        <span class="question">
                            Ihre Zielgruppe bleibt aus?
                        </span>
                        <span class="answer">
                            Überraschen Sie Ihre Leser mit lukrativen Angeboten.
                            Ihre Zielgruppe will mehr, also geben Sie ihnen
                            mehr.
                        </span>
                    </li>
                    <li>
                        <span class="question">Werbung ohne Wirkung?</span>
                        <span class="answer">
                            Sie wünschen sich redaktionelle Artikel, die Ihre
                            Ads befeuern? Kein Problem. Mobility Ads führt eine
                            eigene Redaktion zu den Top-Themen rund um Autos &
                            Automobile.
                        </span>
                    </li>
                    <li>
                        <span class="question">Klicks ohne Cash?</span>
                        <span class="answer">
                            Ihr Traffic wächst, doch der Umsatz bleibt aus. Die
                            Besucher schauen nur vorbei – kein Umsatz, kein
                            Gewinn. Mit passenden Ads sorgen wir für lukrative
                            Deals!
                        </span>
                    </li>
                </ul>
            </app-bento-item>
            <app-bento-item
                col="4"
                row="1"
                direction="right"
                class="hide-on-mobile">
                <div
                    class="img-glow"
                    style="
                        background-image: url('assets/img/cars/car-on-fire.webp');
                    "></div>
            </app-bento-item>
        </app-bento>
        <p class="answer-i">
            Mobility Ads ist IHR Ansprechpartner, wenn es um Werbung für
            Automobile geht. Wir haben die exklusivsten Werbepartner und
            Autohäuser in unserem Portfolio.
        </p>
        <button [routerLink]="['/kontakt']" class="button-2 reverse">
            Jetzt Publisher-Vorteile sichern
            <figure class="arrow"></figure>
        </button>
    </div>
</section>

<section class="info-box">
    <div class="container">
        <div class="">
            <div class="">
                <h3 class="gradient publisher">
                    Geben Sie Gas und monetarisieren Sie Ihre Reichweite!
                </h3>
                <p class="m-0">
                    Vollgas Richtung Umsatz – mit Mobility Ads bringen Sie Ihre
                    Plattform auf die Überholspur.
                </p>
            </div>
        </div>
    </div>
</section>

<section class="sector">
    <div class="container">
        <h2 #reveal>
            Boost für Ihren Traffic – und für Ihren Umsatz! So holen Sie das
            Maximum aus Ihrer Reichweite heraus.
        </h2>
        <p class="info-i">
            Was sich die meisten Autohäuser und Autohausbesitzer wünschen?
        </p>
        <app-bento #bento class="plain">
            <app-bento-item col="8" row="1" direction="left">
                <ul class="qa-list">
                    <li>
                        <span class="question">
                            Mehr Umsatz – Die Macht der Monetarisierung
                        </span>
                        <span class="answer">
                            Exklusive Partnerprogramme, die jeden Klick in bares
                            Geld verwandeln.
                        </span>
                    </li>
                    <li>
                        <span class="question">Maßgeschneiderte Inhalte</span>
                        <span class="answer">
                            Publizieren Sie ab sofort Automotive-Inhalte, die
                            Ihre Leser begeistern und auf Ihrer Plattform
                            halten.
                        </span>
                    </li>
                    <li>
                        <span class="question">Transparenz & Kontrolle</span>
                        <span class="answer">
                            Verfolgen Sie Ihre Werbeerfolge live dank unserem
                            übersichtlichen Dashboard! Sie wissen immer, was
                            funktioniert und können Ihre Werbung leichter
                            optimieren!
                        </span>
                    </li>
                    <li>
                        <span class="question">
                            Neue Kunden = attraktive Provisionen
                        </span>
                        <span class="answer">
                            Ihre Reichweite wird zu Ihrem stärksten
                            Vermögenswert. Steigern Sie Ihre Einnahmen mit
                            einfach zu integrierenden Partnerprogrammen.
                        </span>
                    </li>
                    <li>
                        <span class="question">
                            Einfacher Start, individuelle Betreuung
                        </span>
                        <span class="answer">
                            Von der Integration bis zur Umsatzsteigerung –
                            Mobility Ads begleitet Sie auf jedem Schritt zum
                            Erfolg.
                        </span>
                    </li>
                </ul>
            </app-bento-item>
            <app-bento-item
                col="4"
                row="1"
                direction="right"
                class="hide-on-mobile">
                <div
                    class="img-glow"
                    style="
                        background-image: url('assets/img/cars/car-super-fast.webp');
                    "></div>
            </app-bento-item>
        </app-bento>
        <hr />
        <button [routerLink]="['/kontakt']" class="button-2 secondary">
            Jetzt Publisher-Vorteile sichern
            <figure class="arrow"></figure>
        </button>
    </div>
</section>

<section class="online-presence">
    <div class="container">
        <span class="subtitle">ERFOLG, DER VERBINDET</span>
        <h2 #reveal>Ihr Weg zur reichweitenstarken Online-Präsenz.</h2>
        <div class="d-flex">
            <div class="col-3">
                <div class="step-box__header">
                    <div class="step-box__icon">
                        <span class="icon">
                            <img
                                src="assets/img/icons/support.svg"
                                alt="Einstieg & Beratung" />
                        </span>
                        <span class="emphasized">Schritt 1</span>
                    </div>
                </div>
                <div class="step-box">
                    <div class="step-box__content">
                        <span class="emphasized">Einstieg & Beratung</span>
                        <p>
                            Kontakt aufnehmen und durchstarten! Unsere Experten
                            beraten Sie individuell und entwickeln gemeinsam mit
                            Ihnen die passende Strategie.
                        </p>
                    </div>
                </div>
            </div>
            <div class="col-3">
                <div class="step-box__header">
                    <div class="step-box__icon">
                        <span class="icon">
                            <img
                                src="assets/img/icons/access.svg"
                                alt="Anmeldung & Zugang" />
                        </span>
                        <span class="emphasized">Schritt 2</span>
                    </div>
                </div>
                <div class="step-box">
                    <div class="step-box__content">
                        <span class="emphasized">Anmeldung & Zugang</span>
                        <p>
                            Registrieren Sie sich im Mobility Ads-Partnerportal
                            – einfach, schnell und mit direktem Zugang zu
                            exklusiven Angeboten.
                        </p>
                    </div>
                </div>
            </div>
            <div class="col-3">
                <div class="step-box__header">
                    <div class="step-box__icon">
                        <span class="icon">
                            <img
                                src="assets/img/icons/cursor.svg"
                                alt="Kampagne starten & Performance steigern" />
                        </span>
                        <span class="emphasized">Schritt 3</span>
                    </div>
                </div>
                <div class="step-box">
                    <div class="step-box__content">
                        <span class="emphasized">
                            Kampagne starten & Performance steigern
                        </span>
                        <p>
                            Ihre Kampagne geht live. Verfolgen Sie die
                            Ergebnisse in Echtzeit und reagieren Sie flexibel
                            auf Marktveränderungen – für einen Vorsprung im
                            mobilen Werbegeschäft.
                        </p>
                    </div>
                </div>
            </div>
            <div class="col-3">
                <div class="step-box__header">
                    <div class="step-box__icon">
                        <span class="icon">
                            <img
                                src="assets/img/icons/chart-up.svg"
                                alt="Ergebnisanalyse & kontinuierlicher Wachstum" />
                        </span>
                        <span class="emphasized">Schritt 4</span>
                    </div>
                </div>
                <div class="step-box">
                    <div class="step-box__content">
                        <span class="emphasized">
                            Ergebnisanalyse & kontinuierlicher Wachstum
                        </span>
                        <p>
                            Profitieren Sie von Ihrer Reichweite. Nutzen Sie
                            unsere Analysewerkzeuge, für mehr Umsatz und eine
                            optimierte Umsatzentwicklung.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="sector">
    <div class="container">
        <span class="subtitle">GEMEINSAM ERFOLGREICH</span>
        <h2 #reveal>
            Mobility Ads-Partnerprogramm – Gas geben für Ihren Erfolg.
        </h2>
        <p class="answer-i">Ihr Autohandel wirkt ab sofort auch online!</p>
        <p>
            Mobility Ads setzt effektive Online-Strategien ein, die Ihnen Kunden
            bringen.
        </p>
        <br />
        <app-bento #bento>
            <app-bento-item col="6" row="1" direction="left">
                <div class="bento__item-icon-wrapper">
                    <span class="icon">
                        <img src="assets/img/icons/exclusive.svg" alt="" />
                    </span>
                </div>
                <span class="emphasized">
                    Exklusive Programme, direkter Mehrwert
                </span>
                <p>
                    Greifen Sie auf unsere einzigartigen Partnerprogramme zu und
                    steigern Sie Ihre Reichweite mit exklusiven Angeboten für
                    Ihre Zielgruppe.
                </p>
            </app-bento-item>
            <app-bento-item col="6" row="1" direction="right">
                <div class="bento__item-icon-wrapper">
                    <span class="icon">
                        <img src="assets/img/icons/rocket.svg" alt="" />
                    </span>
                </div>
                <span class="emphasized">
                    Einfacher Start, wie beim Hochschalten
                </span>
                <p>
                    Unsere benutzerfreundlichen Tools machen die Integration so
                    einfach wie einen Gangwechsel – einfach, schnell und sofort
                    einsatzbereit.
                </p>
            </app-bento-item>
            <app-bento-item col="6" row="1" direction="left">
                <div class="bento__item-icon-wrapper">
                    <span class="icon">
                        <img src="assets/img/icons/browse-up.svg" alt="" />
                    </span>
                </div>
                <span class="emphasized">
                    Umsatz-Turbo durch klare Echtzeit-Ergebnisse
                </span>
                <p>
                    Behalten Sie den Überblick über Ihre Kampagnen und
                    optimieren Sie gezielt – mit Live-Tracking in Echtzeit.
                </p>
            </app-bento-item>
            <app-bento-item col="6" row="1" direction="right">
                <div class="bento__item-icon-wrapper">
                    <span class="icon">
                        <img src="assets/img/icons/save-euro.svg" alt="" />
                    </span>
                </div>
                <span class="emphasized">
                    Attraktive Provisionen von uns für Ihr nachhaltiges Wachstum
                </span>
                <p>
                    Mit jedem erfolgreichen Abschluss erhöhen Sie Ihre Einnahmen
                    – ohne großen Aufwand, dafür mit maximaler Effektivität.
                </p>
            </app-bento-item>
        </app-bento>
        <hr>
        <button [routerLink]="['/kontakt']" class="button-2 reverse">
            Jetzt Partner werden & Umsatz beschleunigen!
            <figure class="arrow"></figure>
        </button>
    </div>
</section>

<section id="testimonials" class="quote customers">
    <div class="container">
        <span class="subtitle">FEEDBACK</span>
        <h2 #reveal>Was sagen Kunden über uns?</h2>
        <app-bento #bento>
            <app-bento-item col="4" row="1" direction="left">
                <div class="quote__customers">
                    <div class="quote__customers-img">
                        <img
                            src="assets/img/customers/michael-wamhof.webp"
                            alt="Michael Wamhof" />
                        <div class="quote__customers-author">
                            <p>Michael Wamhof</p>
                            <small>Geschäftsführer, WakeUp Media OHG</small>
                        </div>
                    </div>
                    <div class="quote__customers-text">
                        <p>
                            “Die Angebote der Mobility Ads Partnerprogramme sind
                            für unsere Nutzer von großem Wert und sorgen für
                            mehr Abwechslung und starke Monetarisierung.”
                        </p>
                        <br />
                    </div>
                </div>
            </app-bento-item>
            <app-bento-item col="4" row="1" direction="middle">
                <div class="quote__customers">
                    <div class="quote__customers-img">
                        <img
                            src="assets/img/customers/julian-wenzel.webp"
                            alt="Julian Wenzel" />
                        <div class="quote__customers-author">
                            <p>Julian Wenzel</p>
                            <small>
                                Senior Key Account Manager, Checkout Charlie
                                GmbH
                            </small>
                        </div>
                    </div>
                    <div class="quote__customers-text">
                        <p>
                            “Durch die Zusammenarbeit mit der Mobility-Ads GmbH
                            konnten wir das Potenzial für den Bereich Leasing um
                            ein Vielfaches erhöhen.”
                        </p>
                        <br />
                    </div>
                </div>
            </app-bento-item>
            <app-bento-item col="4" row="1" direction="right">
                <div class="quote__customers">
                    <div class="quote__customers-img">
                        <img
                            src="assets/img/customers/marc-majewski.webp"
                            alt="Marc Majewski" />
                        <div class="quote__customers-author">
                            <p>Marc Majewski</p>
                            <small>Geschäftsführer, Advanced Store GmbH</small>
                        </div>
                    </div>
                    <div class="quote__customers-text">
                        <p>
                            “Wir konnten durch die Mobility-Ads GmbH unsere
                            Wachstumsstrategie für den Bereich der Mobilität
                            zusätzlich verstärken und beschleunigen.”
                        </p>
                        <br />
                    </div>
                </div>
            </app-bento-item>
        </app-bento>
    </div>
</section>

<section class="quote ceo">
    <div class="container">
        <app-bento #bento>
            <app-bento-item col="12" row="2" direction="middle">
                <div class="quote__ceo">
                    <div class="quote__ceo-img">
                        <img
                            src="assets/img/team/oguz-pp.webp"
                            alt="Oguz Özcan" />
                    </div>
                    <div class="quote__ceo-content">
                        <div class="quote__ceo-link hide-on-desktop">
                            <a
                                [routerLink]="['/uber-uns']"
                                class="link secondary">
                                Lernen Sie unser Team kennen
                                <span class="arrow"></span>
                            </a>
                        </div>
                        <div class="quote__ceo-text">
                            <p>
                                Bei Mobility Ads setzen wir auf Transparenz,
                                Verbindlichkeit und nachhaltigen Erfolg. Unsere
                                Mission: Mobilitätsanbieter und Publisher durch
                                exklusive Partnerschaften und maßgeschneiderte
                                Strategien sichtbar und erfolgreich zu machen –
                                und gemeinsam in den höchsten Gang zu schalten.
                            </p>
                        </div>
                        <div class="quote__ceo-author">
                            <span>Oguz Özcan</span>
                            <small>Chief Operating Officer, Mobility Ads</small>
                        </div>
                        <div class="quote__ceo-link hide-on-mobile">
                            <a
                                [routerLink]="['/uber-uns']"
                                class="link secondary">
                                Lernen Sie unser Team kennen
                                <span class="arrow"></span>
                            </a>
                        </div>
                    </div>
                </div>
            </app-bento-item>
        </app-bento>
    </div>
</section>

<section class="faq">
    <div class="container">
        <div class="d-flex">
            <div class="col-4">
                <span class="subtitle">FAQ</span>
                <h3 #reveal>Offene Fragen finden hier ihre Antworten</h3>
            </div>
            <div class="col-8">
                <ul class="faq-list">
                    @for (faq of faqs; track $index; let i = $index) {
                        <li
                            (click)="onToggleFaq(i)"
                            [class.expanded]="expandedFaqIndex === i">
                            <span class="question">{{ faq.question }}</span>
                            <span class="answer">{{ faq.answer }}</span>
                            <span class="icon"></span>
                        </li>
                    }
                </ul>
            </div>
        </div>
    </div>
</section>

<section class="banner">
    <div class="container">
        <h2>
            Setzen Sie den Blinker auf Erfolg –
            <br />
            <span class="gradient publisher">
                Mit Mobility Ads auf die Überholspur
            </span>
        </h2>
        <p>
            Erreichen Sie mehr mit maßgeschneiderten Partnerprogrammen, die Sie
            als Publisher sichtbar und erfolgreich machen. Wir bringen Bewegung
            in Ihre Monetarisierung – effizient, exklusiv und mit Dynamik, die
            Sie nach vorne bringt.
        </p>
        <div class="buttons">
            <button [routerLink]="['/kontakt']" class="button-2 secondary">
                Jetzt Publisher-Vorteile sichern
                <figure class="arrow"></figure>
            </button>
        </div>
    </div>
</section>
