<section class="imprint">
    <div class="container">
        <h1>Impressum</h1>
        <h5>Angaben gemäß § 5 TMG:</h5>
        <p class="mb-0">
            Mobility-Ads Gesellschaft mit beschränkter Haftung (GmbH)
        </p>
        <p class="mb-0">Stresemannstraße 23</p>
        <p>10963 Berlin</p>
        <p class="mb-0">Registergericht: Amtsgericht Berlin-Charlottenburg</p>
        <p class="mb-0">Registernummer: HRB 213659 B</p>
        <p class="mb-0">Geschäftsführer: Fatih Özcan</p>
        <p class="mb-0">Tel: 030 800987840</p>
        <p class="mb-0">Fax: 030 22187647</p>
        <p class="mb-0">
            Mail:
            <a href="mailto:info@mobility-ads.de">info&#64;mobility-ads.de</a>
        </p>
        <p class="mb-0">USt-IdNr.: DE327817608</p>
        <h5>Verantwortlich für den Inhalt ist gemäß § 55 II RStV:</h5>
        <p class="mb-0">Mobility-Ads GmbH</p>
        <p class="mb-0">Stresemannstraße 23</p>
        <p>10963 Berlin</p>
        <p>
            Mit technischer Unterstützung durch die NitramIT GmbH
            <a href="https://www.nitramit.de/">https://www.nitramit.de/</a>
        </p>
        <h2>Online-Streitbeilegung:</h2>
        <p>
            Die Europäische Kommission stellt eine Plattform für die
            außergerichtliche Online-Streitbeilegung (OS-Plattform) bereit, die
            unter www.ec.europa.eu/consumers/odr aufrufbar ist. Unsere
            E-Mail-Adresse finden Sie in unserem Impressum. Wir nehmen
            freiwillig am Streitschlichtungsverfahren teil. Eine Liste mit den
            Kontaktdaten der anerkannten Streitschlichtungsstellen finden Sie
            unter
            https://ec.europa.eu/consumers/odr/main/index.cfm?event=main.adr.show.
            Die Europäische Kommission stellt unter ec.europa.eu/consumers/odr/
            eine Internetplattform zur Online-Streitbeilegung bereit. Die
            Mobility Ads GmbH beteiligt sich an Verbraucherschlichtungsverfahren
            nach dem Verbraucherstreitbeilegungsgesetz.
        </p>
        <h2>Haftungsbeschränkung:</h2>
        <h3>Haftung für Inhalte</h3>
        <p>
            Anbieter ist für eigene Inhalte auf diesen Seiten nach den
            allgemeinen Gesetzen verantwortlich, § 7 Abs.1 TMG. Der Anbieter als
            Diensteanbieter ist jedoch nicht verpflichtet, übermittelte oder
            gespeicherte fremde Informationen zu überwachen noch nach Umständen
            zu forschen, die auf eine rechtswidrige Tätigkeit hinweisen, §§ 8
            bis 10 TMG. Hiervon unberührt bleibt die Verpflichtung zur
            Entfernung oder Sperrung der Nutzung von Informationen nach den
            allgemeinen Gesetzen. Eine diesbezügliche Haftung ist jedoch erst ab
            dem Zeitpunkt der Kenntnis einer konkreten Rechtsverletzung möglich.
            Bei Bekanntwerden von entsprechenden Rechtsverletzungen wird der
            Anbieter diese Inhalte umgehend entfernen.
        </p>
        <h3>Externe Links</h3>
        <p>
            Diese Website enthält Verknüpfungen zu Websites Dritter („externe
            Links“), die der Haftung des jeweiligen Betreibers unterliegen. Der
            Anbieter hat bei der erstmaligen Verknüpfung der externen Links die
            fremden Inhalte daraufhin überprüft, ob womöglich Rechtsverstöße
            bestehen. Zu dem Zeitpunkt waren keine Rechtsverstöße ersichtlich.
            Der Anbieter hat keinen Einfluss auf die aktuelle und künftige
            Gestaltung und auf die Inhalte der verknüpften Seiten. Das Setzen
            von externen Links bedeutet nicht, dass sich der Anbieter die hinter
            den Verweisen oder Links liegenden Inhalte zu Eigen macht. Eine
            ständige Kontrolle der externen Links ist für den Anbieter ohne
            konkrete Hinweise auf Rechtsverstöße nicht zumutbar. Bei Kenntnis
            von Rechtsverstößen werden jedoch derartige externe Links
            unverzüglich gelöscht.
        </p>
        <h3>Urheber- und Leistungsschutzrechte</h3>
        <p>
            Die auf dieser Website veröffentlichten Inhalte unterliegen dem
            deutschen Urheber- und Leistungsschutzrecht. Jede vom deutschen
            Urheber- und Leistungsschutzrecht nicht zugelassene Verwertung
            bedarf der vorherigen schriftlichen Zustimmung des Anbieters oder
            jeweiligen Rechteinhabers. Dies gilt insbesondere für
            Vervielfältigung, Bearbeitung, Übersetzung, Einspeicherung,
            Verarbeitung bzw. Wiedergabe von Inhalten in Datenbanken oder
            anderen. Soweit die Inhalte auf dieser Seite nicht von dem Anbieter
            erstellt wurden, werden die Urheberrechte Dritter beachtet. Sollten
            Sie trotzdem auf eine Urheberrechtsverletzung aufmerksam werden,
            bittet der Anbieter um einen entsprechenden Hinweis. Bei
            Bekanntwerden von Rechtsverletzungen wird der Anbieter derartige
            Inhalte umgehend entfernen.
        </p>
    </div>
</section>
