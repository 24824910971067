import { DOCUMENT } from '@angular/common'
import {
    Inject,
    Injectable,
    WritableSignal,
    effect,
    signal,
} from '@angular/core'
import { BehaviorSubject } from 'rxjs'

export const storageKey = 'theme'

@Injectable({
    providedIn: 'root',
})
export class ThemeService {
    themeSignal: WritableSignal<string>

    localStorage: Storage | undefined

    constructor(@Inject(DOCUMENT) private document: Document) {
        this.localStorage = document.defaultView?.localStorage
        const initialTheme = this.getStoredThemeOrDefault()
        this.themeSignal = signal<string>(initialTheme)

        this.updateRenderedTheme()

        effect(() => {
            this.updateRenderedTheme()
        })
    }

    toggleTheme(): void {
        this.themeSignal.update(() =>
            this.isDarkThemeActive() ? 'light' : 'dark',
        )
    }

    isDarkThemeActive(): boolean {
        return this.themeSignal() === 'dark' ? true : false
    }

    getStoredThemeOrDefault(): string {
        const storedTheme =
            this.localStorage && this.localStorage.getItem(storageKey)
        return storedTheme || 'dark'
    }

    private updateRenderedTheme(): void {
        this.document.documentElement.setAttribute(
            'data-theme',
            this.themeSignal(),
        )
        this.document.documentElement.style.setProperty(
            'color-scheme',
            this.themeSignal(),
        )

        this.localStorage &&
            this.localStorage.setItem(storageKey, this.themeSignal())
    }
}
