import { Component, ElementRef, ViewChild, ViewEncapsulation } from '@angular/core'
import { ContactService } from '../../core/services/contact.service'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { ReactiveFormsModule } from '@angular/forms'
import { Router, RouterLink } from '@angular/router'
import { BehaviorSubject, Subject, tap } from 'rxjs'
import { CommonModule, ViewportScroller } from '@angular/common'
import { Title } from '@angular/platform-browser'

@Component({
    selector: 'app-contact',
    templateUrl: './contact.component.html',
    styleUrls: ['./contact.component.scss'],
    encapsulation: ViewEncapsulation.None,
    standalone: true,
    imports: [ReactiveFormsModule, RouterLink, CommonModule],
})
export class ContactComponent {
    contactForm: FormGroup

    readonly intent = ContactRequestIntent
    submitSuccess: boolean | null = null

    readonly loading$ = new BehaviorSubject<boolean>(false);
    // isLoading: boolean = false

    constructor(
        private fb: FormBuilder,
        private contactService: ContactService,
        private router: Router,
        private title: Title
    ) {
        this.contactForm = this.fb.group({
            firstName: ['', [Validators.required, Validators.minLength(2), Validators.maxLength(64)]],
            lastName: ['', [Validators.required, Validators.minLength(2), Validators.maxLength(64)]],
            email: ['', [Validators.required, Validators.email, Validators.maxLength(64)]],
            phone: ['', [Validators.required, Validators.minLength(4), Validators.maxLength(20)]],
            contactViaPhone: [''],
            message: ['', [Validators.maxLength(256)]],
            intent: ['', Validators.required],
            policy: [false, Validators.requiredTrue],
        })

        this.contactForm.get('contactViaPhone')?.valueChanges.subscribe((value) => {
            if (value) {
                this.contactForm.get('phone')?.enable()
            } else {
                this.contactForm.get('phone')?.disable()
            }
        })
    }

    private mapToContactRequest(): ContactRequest {
        const { firstName, lastName, email, phone, message, intent } = this.contactForm.value
        return { ...this.contactForm.value, policy: undefined, contactViaPhone: undefined }
    }

    ngOnInit(): void {
        this.title.setTitle('Individuelle Lösungen für mehr Traffic und Kunden im Automobilsektor – Mobility Ads')
        this.contactForm.get('phone')?.disable()
    }

    onSubmit() {
        if (this.contactForm.valid) {
            const contactRequest: ContactRequest = this.mapToContactRequest()
            this.loading$.next(true);

            this.contactService
                .sendContactRequest(contactRequest)
                .pipe(tap(() => this.loading$.next(false)))
                .subscribe({
                    next: () => {
                        this.submitSuccess = true
                        this.router.navigate(['/kontakt-erfolgreich'], {
                            state: { contactRequest }
                        });
                    },
                    error: (error) => {
                        this.submitSuccess = false
                        console.log('Error sending the contact request:', error)
                    },
                });
        } else {
            console.log('Form is invalid!')
        }
    }
}

export interface ContactRequest {
    firstName: string
    lastName: string
    email: string
    message: string
    phone?: string
    intent: ContactRequestIntent
}

export enum ContactRequestIntent {
    MERCHANT = 'MERCHANT',
    PUBLISHER = 'PUBLISHER',
    JOB = 'JOB',
    SUPPORT = 'SUPPORT',
    OTHER = 'OTHER',
}
