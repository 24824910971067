import { Component, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-agb',
  standalone: true,
  imports: [],
  encapsulation: ViewEncapsulation.None,
  templateUrl: './agb.component.html',
  styleUrl: './agb.component.scss'
})
export class AgbComponent {

}
