<section class="imprint">
    <div class="container">
        <h1>
            Allgemeine Geschäftsbedingungen des Mobility-Ads Affiliate-Netzwerks
        </h1>
        <div class="et_pb_text_inner">
            <p>
                Die Mobility-Ads GmbH (im Folgenden „Mobility-Ads“ genannt)
                betreibt im Internet unter verschiedenen Subdomains zur Domain
                mobility-ads.de die Online-Plattform Mobility-Ads (im Folgenden
                „MoAds“ genannt“) zum Zweck der administrativen Vermittlung und
                Vermarktung von Werbeflächenplatzierungen und/oder -Nutzungen
                zwischen an MoAds teilnehmenden Werbekunden (Advertiser) und an
                Mobility-Ads teilnehmenden Betreibern von Online-Werbeflächen
                (Publisher) (gemeinsam im Folgenden „Teilnehmer“ genannt).
            </p>
            <p>
                Die Nutzung von MoAds erfolgt auf Grundlage der nachfolgenden
                Allgemeinen Geschäftsbedingungen (im Folgenden „AGB“):
            </p>

            <h4>§1 Geltungsbereich &amp; Definitionen</h4>
            <p>
                (1) Auf das die Nutzung von Mobility-Ads betreffende
                Vertragsverhältnis zwischen Mobility-Ads und dem jeweiligen
                Teilnehmer finden ausschließlich die AGB und ausdrücklich in das
                Vertragsverhältnis einbezogene Vereinbarungen Anwendung.
                Allgemeine Geschäftsbedingungen eines Teilnehmers finden auf das
                Vertragsverhältnis zwischen Mobility-Ads und dem Teilnehmer
                keine Anwendung, auch wenn Mobility-Ads diesen nicht
                ausdrücklich widerspricht. Die AGB gelten, auch wenn sie nicht
                erneut ausdrücklich vereinbart werden, auch für alle weiteren
                künftigen Vertragsverhältnisse, die zwischen Mobility-Ads und
                dem Teilnehmer im Rahmen der Teilnahme an MoAds geschlossen
                werden, sofern die dann geltenden AGB nicht vorgehen.
            </p>
            <p>
                (2) MoAds und diese AGB richten sich ausschließlich an
                Unternehmer im Sinne des § 14 BGB.
            </p>
            <p>
                (3) Diesen AGB sowie allen Vereinbarungen mit Mobility-Ads, die
                unter Einbeziehung dieser AGB geschlossen werden, sollen die
                nachfolgenden Definitionen zugrunde gelegt werden:
            </p>
            <p>
                a) „Advertiser“ ist ein Teilnehmer, der Produkte und/oder
                Dienstleistungen anbietet und diese mit Werbemitteln bewirbt.
            </p>
            <p>
                b) „Affiliate-Partnerprogramm“ ist das vom jeweiligen Advertiser
                über MoAds betriebene Marketing-Netzwerk-Programm, das am
                Affiliate-Partnerprogramm teilnehmenden Publishern erlaubt,
                unter Einhaltung dieser AGB und ggf. weiterer vom Advertiser
                vorgegebener Bedingungen die Werbemittel des Advertisers zum
                Zweck der Vermarktung von dessen Produkten einzusetzen bzw.
                einzubinden. Das Affiliate-Partnerprogramm kann auf MoAds auch
                als Kampagne bezeichnet werden.
            </p>
            <p>
                c) „Publisher“ ist ein Teilnehmer, der eine oder mehrere
                Publisher-Seite/n betreibt, auf denen er zum Zweck der
                Vermarktung der Produkte eines oder mehrerer Advertiser/s,
                dessen/deren Werbemittel – im Fall von dynamischen
                Advertiser-Werbemittel-Containern: inhaltlich durch MoAds um
                technische Interaktionsmöglichkeiten ergänzt – einbindet
                und/oder der nach vorheriger Freigabe durch Mobility-Ads in
                Textform Sub-Publisher zum Zweck der Vermarktung der Produkte
                eines Advertisers oder mehrerer Advertiser einsetzt.
            </p>
            <p>
                d) „Am Affiliate-Partnerprogramm teilnehmender Publisher“ ist
                ein Publisher, der am Affiliate-Partnerprogramm eines
                Advertisers oder mehrerer Advertiser teilnimmt.
            </p>
            <p>
                e) „Produkte“ sind die vom Advertiser angebotenen Produkte
                und/oder Dienstleistungen.
            </p>
            <p class="p3">
                f) „Publisher-Seiten“ sind vom Publisher betriebene Webseiten
                und/oder mobile Applikationen und/oder sonstige
                Dienstleistungen.
            </p>
            <p>
                g) „Sub-Publisher“ sind vom Publisher unter Beachtung der
                diesbezüglich durch diese AGB und ggf. durch AGB des Advertisers
                vorgegebenen Einschränkungen eingesetzte Unterauftragnehmer.
            </p>
            <p>
                h) „Advertiser–Werbemittel“ ist ein vom Advertiser über MoAds
                zur Einbindung auf den Publisher-Seiten bereitgestellter Inhalt.
                In der Regel kann ein Nutzer über ein Advertiser-Werbemittel auf
                die Ziel-URL eines Advertiser-Links weitergeleitet werden. Ein
                Advertiser-Werbemittel kann auch allein aus einem
                Advertiser-Link bestehen.
            </p>
            <p>
                i) „Advertiser-Link“ ist ein vom Advertiser oder vom Advertiser
                und Mobility-Ads gemeinsam festgelegter Hyperlink, der zu den
                Produkten oder sonstigen Angeboten des Advertisers führt. Die
                Domain und der Pfad des Advertiser-Links werden stets vom
                Advertiser festgelegt. Sofern und soweit der Advertiser ihm von
                Mobility-Ads angebotene Tracking-URL-Dienstleistungen („Tracking
                Tools“) nutzt, werden die dem Pfad nachfolgenden Parameter
                und/oder Werte und/oder Parameterwerte von Mobility-Ads oder
                Mobility-Ads und dem Advertiser gemeinsam festgelegt.
            </p>
            <p>
                j) „Publisher-Werbeumfeld“ sind die übrigen Inhalte des
                Publishers auf der bzw. den Publisher-Seite/n, auf welcher/n der
                Publisher die Advertiser-Werbemittel einbindet.
            </p>
            <p>
                k) „Einzelauftrag“ ist eine Vereinbarung zwischen Mobility-Ads
                und einem Teilnehmer über die Teilnahme an MoAds als Advertiser
                und/oder den Betrieb eines Affiliate-Partnerprogramms über
                MoAds. Der Einzelauftrag selbst kann auch als „Auftragsformular“
                oder „Insertion Order“ bezeichnet werden. Bei Widersprüchen
                zwischen dem Einzelauftrag und diesen AGB gehen die Bestimmungen
                des Einzelauftrags diesen AGB vor.
            </p>
            <p>
                l) „Vergütungsmodell“ ist das für das jeweilige
                Advertiser-Werbemittel oder ggf. gesamte
                Affiliate-Partnerprogramm des Advertisers festgelegte
                Grundmuster zur Bestimmung der Vergütung. MoAds sieht
                grundsätzlich für jedes Advertiser-Werbemittel eines von vier
                Vergütungsmodellen vor: „CPC (Cost per Click)“, „CPA (Cost per
                Action)“, „CPL (Cost per Lead)“ oder „CPO (Cost per Order)”. Die
                konkrete vergütungsauslösende Bedingung wird für jedes
                Advertiser-Werbemittel individuell bestimmt und dem an einem
                Affiliate-Partnerprogramm teilnehmendem Publisher bei MoAds
                mitgeteilt.
            </p>
            <p>
                m) „CPC (Cost per Click)“ ist ein Vergütungsmodell, bei dem eine
                Provision je von einem Nutzer bzw. Endkunden getätigten Klick
                ausgezahlt wird.
            </p>
            <p>
                n) „CPA (Cost per Action)“ ist ein Vergütungsmodell, bei dem
                eine Provision je von einem Nutzer bzw. Endkunden getätigter,
                zuvor vom Publisher oder MoAds definierter Handlung, die nicht
                in unmittelbarem Zusammenhang mit der Abgabe oder Annahme eines
                Angebots bezüglich der Produkte steht (z.B. die Installation von
                Software, eine Registrierung für einen Dienst oder einen
                Newsletter o.ä.), ausgezahlt wird.
            </p>
            <p>
                o) „CPL (Cost per Lead)“ ist ein Vergütungsmodell, bei dem eine
                Provision je Weiterleitung eines Endkunden auf die Ziel-URL des
                Advertiser-Links und vollständiger und inhaltlich zutreffender
                Eingabe eines unter der Ziel-URL oder innerhalb des
                Advertiser-Werbemittel abgefragten Datensatzes durch den
                Endkunden ausgezahlt wird.
            </p>
            <p>
                p) „CPO (Cost per Order)” ist ein Vergütungsmodell, bei dem eine
                Provision je über das Advertiser-Werbemittel oder nach
                Weiterleitung des Endkunden über das Advertiser-Werbemittel von
                einem Endkunden abgeschlossenem Vertrag über eines der Produkte,
                der nicht innerhalb der gesetzlichen Widerrufsfrist widerrufen
                wird, ausgezahlt wird.
            </p>
            <p>
                q)„Vergütungsauslösende Bedingung“ ist die für ein
                Advertiser-Werbemittel unter Zugrundelegung eines
                Vergütungsmodells festgelegte Bedingung, die vollständig erfüllt
                werden muss, damit der Publisher einen Anspruch auf Auszahlung
                einer Provision erhält. Die vergütungsauslösende Bedingung wird
                in der Regel durch die im Affiliate-Partnerprogramm für das
                jeweilige Advertiser-Werbemittel hinterlegten
                Vergütungsbedingungen bestimmt.
            </p>
            <p>
                r) „Dynamischer Advertiser-Werbemittel-Container“ ist eine für
                jede technisch einzelne Bereitstellung an den Publisher grundsätzlich nach Kriterien von MoAds festgelegte initiale
                Zusammenstellung von Advertiser-Werbemitteln aus einem oder
                mehreren verschiedenen Affliate-Partnerprogramm/en, an dem/denen
                der Publisher am Affiliate-Partnerprogramm teilnehmender
                Publisher ist, die inhaltlich durch MoAds um technische
                Interaktionsmöglichkeiten für Nutzer der Publisher-Seiten
                ergänzt ist. Mittels der von MoAds ergänzten technischen
                Interaktionsmöglichkeiten können Nutzer der Publisher-Seiten
                spezifische, von MoAds näher vorgegebene Eingaben und/oder
                Angaben (beispielsweise eine vom Nutzer der Publisher-Seite
                gewünschte Vertragslaufzeit oder einen maximal gewünschten Preis
                für ein Produkt) tätigen, die sodann nach erster initialer
                Bereitstellung der Zusammenstellung von Advertiser-Werbemitteln
                zu dynamisch fortlaufenden und neuen Zusammenstellungen von
                Advertiser-Werbemitteln aus einem oder mehreren verschiedenen
                Affliate-Partnerprogramm/en, an dem/denen der Publisher am
                Affiliate-Partnerprogramm teilnehmender Publisher ist, führen
                können, wobei sich jene dynamisch fortlaufenden erneuten
                Zusammenstellungen allein aus der initialen Zusammenstellung von
                Advertiser-Werbemitteln bilden können, soweit nicht einzelne
                Advertiser-Werbemittel aus der initialen Zusammenstellung auf
                Basis der Vorgaben eines Nutzers aus dessen
                Interaktionsmöglichkeiten auf den Publisher-Seiten rein
                inhaltlich auszuscheiden hat (beispielsweise Ausscheiden eines
                Advertiser-Werbemittels mit einem spezifischen Preis für ein
                Produkt, nachdem der Nutzer der Publisher-Seiten durch Nutzung
                der Interaktionsmöglichkeiten zuvor gewählt / eingeschränkt hat,
                dass ein bestimmter Preis für ein Produkt nicht überschritten
                werden soll). Die Reihenfolge der Advertiser-Werbemittel in
                Folge dynamisch fortlaufender erneuter Zusammenstellungen nach
                der je ersten initialen Zusammenstellung auf den
                Publisher-Seiten richtet sich nach den Präferenzen des Nutzers
                der Publisher-Seiten, die er über die Interaktionsmöglichkeiten
                kundtun kann (beispielsweise nach Preis je Produkt
                aufsteigend/absteigend sortieren) und – falls eine solche
                Präferenz nicht kundgetan wurde – nach der ursprünglichen
                Reihenfolge der initialen Zusammenstellung. MoAds stellt dem
                Publisher Konfigurationsmöglichkeiten zur Verfügung, über die
                der Publisher inhaltliche Einschränkungen für die initiale
                Zusammenstellung der Advertiser-Werbemittel jedes dynamischen
                Advertiser-Werbemittel-Containers vornehmen kann, insbesondere
                zur Begrenzung auf bestimmte Marken und Modelle von Produkten;
                bei Kraftfahrzeugen zudem: Getriebeart, Kraftstoff, Fahrzeugtyp,
                Vertragstyp und Angebotstyp. Die Reihenfolge der
                Advertiser-Werbemittel in initialen Zusammenstellungen legt
                MoAds für jede einzelne technische Auslieferung auf
                Publisher-Seiten automatisiert und nach jederzeit durch MoAds
                veränderbaren Kriterien zum Zwecke der Kampagnenoptimierung
                fest.
            </p>

            <h4>§2 Teilnahme an MoAds</h4>
            <p>
                (1) Eine Teilnahme an MoAds wird durch entsprechende
                Vereinbarung zwischen Mobility-Ads und dem Teilnehmer
                vereinbart. Eine solche Vereinbarung kann:
            </p>
            <p>
                a) für die Teilnahme als Advertiser durch ein von Teilnehmer und
                Mobility-Ads unterzeichnetes Einzelauftrags-Formular oder
            </p>
            <p>
                b) für die Teilnahme als Publisher durch vollständiges
                Durchlaufen eines von Mobility-Ads bereitgestellten
                Online-Registrierungsprozesses durch den Teilnehmer erfolgen.
            </p>
            <p>
                (2) Das Vorhalten eines Online-Registrierungsprozesses im Sinne
                des Abs. 1 b) und/oder einer sonstigen Registrierungsmöglichkeit
                an MoAds stellt kein verbindliches Angebot von Mobility-Ads zur
                Teilnahme an MoAds dar, sondern allein eine Einladung zur Abgabe
                eines Angebots durch den Teilnehmer. Der Teilnehmer gibt ein
                bindendes Angebot zur Teilnahme an MoAds ab, indem er das unter
                <a href="https://partner.mobility-ads.de/de/registration.html">
                    https://partner.mobility-ads.de/de/registration.html
                </a>
                abrufbare Registrierungsformular wahrheitsgemäß und vollständig
                ausfüllt und das als „Anmeldung“ gekennzeichnete Feld klickt.
            </p>
            <p>
                (3) Mobility-Ads nimmt das Angebot des Teilnehmers zur Teilnahme
                an MoAds an, indem Mobility-Ads eine E-Mail an die vom Teilnehmer im
                Registrierungsformular angegebene E-Mail-Adresse verschickt, in
                der dem Teilnehmer die künftige Teilnahme an MoAds bestätigt
                („Registrierungsbestätigungsmail“) wird.
            </p>
            <p>
                (4) Sofern ein Teilnehmer beide in Abs. 1 dargestellte
                Teilnahmeprozesse vollständig absolviert, steht es einem
                Teilnehmer frei, sowohl als Advertiser wie auch als Publisher an
                MoAds teilzunehmen.
            </p>
            <p>
                (5) Mobility-Ads behält sich vor, Teilnehmer ohne Angabe von
                Gründen abzulehnen.
            </p>
            <p>
                (6) Der Teilnehmer hat seine Zugangsdaten für MoAds,
                insbesondere das Passwort, geheim zu halten und vor dem Zugriff
                durch unbefugte Dritte geschützt aufzubewahren. Sofern und
                soweit dem Teilnehmer die Zugangsdaten abhandenkommen oder er
                feststellt oder den begründeten Verdacht hegt, dass seine
                Zugangsdaten von einem Dritten genutzt werden, wird er dies
                Mobility-Ads umgehend mitzuteilen. Im Fall des Verlusts oder bei
                begründetem Verdacht einer missbräuchlichen Nutzung der
                Zugangsdaten des Teilnehmers durch einen Dritten ist
                Mobility-Ads zur sofortigen Sperrung des MoAds-Nutzerkontos des
                Teilnehmers berechtigt.
            </p>

            <h4>
                §3 Besondere Bedingungen für an MoAds teilnehmende Advertiser
            </h4>
            <p>
                (1) Administration des Affiliate-Partnerprogramms durch
                Mobility-Ads: Der Advertiser erhält die Möglichkeit, sein
                Affiliate-Partnerprogramm von Mobility-Ads administrieren zu
                lassen und über MoAds zu betreiben, um dort Mobility-Ads
                Publishern die Teilnahme am Affiliate-Partnerprogramm des
                Advertisers anbieten zu lassen. Sofern und soweit der Advertiser
                und Mobility-Ads nichts Abweichendes vereinbaren, erteilt der
                Advertiser zum Zweck der Administration des
                Affiliate-Partnerprogramms des Advertisers Mobility-Ads
                insbesondere das Recht bestimmten am Affiliate-Partnerprogramm
                des Advertisers teilnehmenden Publishern die in § 6 genannten
                Freigaben in Rücksprache mit dem Advertiser und unter
                Berücksichtigung dessen wirtschaftlicher Interessen für den
                Advertiser zu erteilen.
            </p>
            <p>
                (2) Zurverfügungstellung der Advertiser-Werbemittel über MoAds:
                Der Advertiser erhält außerdem die Möglichkeit, seine
                Affiliate-Werbemittel zum Zweck der Einbindung durch am
                Affiliate-Partnerprogramm teilnehmende Publishern bereitzustellen. Mobility-Ads wird dem Advertiser dafür einen
                gesonderten Bereich auf MoAds („Advertiser-Space“) zur Verfügung
                stellen, der in Abstimmung zwischen Mobility-Ads und dem
                Advertiser entweder von Mobility-Ads oder dem Advertiser selbst
                verwaltet wird. Der Advertiser wird Mobility-Ads die
                Affiliate-Werbemittel zum Zweck der Einbindung bzw. des Angebots
                im Advertiser-Space auf MoAds in einem gängigen Dateiformat und,
                sofern und soweit erforderlich, gemeinsam mit den zur Einbindung
                erforderlichen Hinweisen auf technische Voraussetzungen zur
                Verfügung stellen. Sofern und soweit Mobility-Ads nach Satz 1
                die Verwaltung den Advertiser-Space verwaltet, kann Mobility-Ads
                dem Advertiser jederzeit die Verwaltung des Advertiser-Space
                nach Mitteilung 14 Tage vor Übertragung in Textform übertragen.
            </p>
            <p>
                (3) Festlegung des Vergütungsmodells für das
                Advertiser-Werbemittel: Mobility-Ads kann bei Einbindung der
                Advertiser-Werbemittel und Eintritt von vergütungsauslösenden
                Bedingungen im eigenen Namen Provisionen an Publisher
                ausschütten. Der Advertiser verpflichtet sich, MoAds spätestens
                mit Zurverfügungstellung des jeweiligen Advertiser-Werbemittels
                für jedes nach Abs. 2 zur Verfügung gestellte
                Advertiser-Werbemittel das für dieses vorgesehene
                Vergütungsmodell und die vergütungsauslösende Bedingung in
                Textform mitzuteilen. Sofern eine solche Mitteilung nicht
                erfolgt, kann Mobility-Ads das Vergütungsmodell oder die
                vergütungsauslösende Bedingung frei festlegen.
            </p>
            <p>
                (4) Pflicht zur Validierung des Eintritts von
                vergütungsauslösenden Bedingungen: Der Advertiser verpflichtet
                sich, Mobility-Ads jeden Eintritt der von nach Abs. 3
                festgelegten vergütungsauslösenden Bedingungen unverzüglich in
                der von Mobility-Ads festgelegten Form mitzuteilen
                (Validierung).
            </p>
            <p>
                (5) Einbindung der Advertiser-Werbemittel durch Publisher: Der
                Advertiser wird jedem am Affiliate-Partnerprogramm teilnehmender
                Publisher erlauben, die dem jeweiligen Publisher zur Verfügung
                gestellten Advertiser-Werbemittel auf den Publisher-Seiten
                einzubinden und die Produkte des Advertisers zu vermarkten.
            </p>
            <p>
                (6) Mitteilungspflicht bzgl. besonderer Bedingungen des
                Advertisers: Der Advertiser verpflichtet sich, Mobility-Ads und,
                sofern und soweit nicht Mobility-Ads gegenüber dem Advertiser in
                Textform erklärt hat, die diesbezügliche Information des und
                Kommunikation mit dem Publisher zu übernehmen, den Publisher
                über etwaige besondere Bedingungen, von denen er der Nutzung
                und/oder Einbindung seiner Advertiser-Werbemittel auf den
                Publisher-Seiten abhängig gemacht hat, insbesondere eigene
                Allgemeine Geschäftsbedingungen oder technische Voraussetzungen
                zur Einbindung seiner Werbemittel auf den Publisher-Seiten
                rechtzeitig und vollumfänglich zu informieren.
            </p>
            <p>
                (7) Compliance der Advertiser-Werbemittel: Der Advertiser
                verpflichtet sich, sicherzustellen, dass die
                Advertiser-Werbemittel im Einklang mit allen anwendbaren
                gesetzlichen Bestimmungen und behördlichen Regelungen und
                gerichtlichen Anordnungen, insbesondere dem Gesetz gegen den
                unlauteren Wettbewerb (UWG), den jugendschutzrechtlichen
                Vorschriften, v.a. dem Jugendschutzgesetz (JuSchG) und dem
                Jugendmedienschutz-Staatsvertrag (JMStV), dem Telemediengesetz
                (TMG); dem Bundesdatenschutzgesetz (BDSG), der
                Datenschutz-Grundverordnung der Europäischen Union (EU-DSGVO)
                und verbraucherschützende Vorschriften, insbesondere den
                fernabsatzrechtlichen Vorschriften des Bürgerlichen Gesetzbuch
                (BGB) stehen und deren Anforderungen erfüllen.
            </p>
            <p>
                (8) Garantie der Rechtefreiheit der Advertiser-Werbemittel: Der
                Advertiser garantiert, dass die Advertiser-Werbemittel frei von
                Rechten Dritter sind und insbesondere keine gewerblichen
                Schutzrechte wie Marken-, Patent- oder allgemeine
                Persönlichkeitsrechte sowie Urheberrechte verletzen oder gegen
                strafrechtliche Vorschriften verstoßen. Der Advertiser
                verpflichtet sich, Mobility-Ads auf erstes Anfordern von
                Ansprüchen Dritter freizustellen, die gegenüber Mobility-Ads
                oder Publishern wegen eines Verstoßes gegen vorstehende
                Verpflichtung erhoben werden. Von dieser
                Freistellungsverpflichtung umfasst sind die Kosten einer
                angemessenen Rechtsverteidigung. Mobility-Ads wird die
                Advertiser-Werbemittel nicht rechtlich prüfen. Der Advertiser
                verpflichtet sich, Mobility-Ads bei nachträglichem Bekanntwerden
                eines Verstoßes gegen die Verpflichtung aus Satz 1 unverzüglich
                in Textform zu informieren.
            </p>
            <p>
                (9) Erreichbarkeitspflicht: Der Advertiser verpflichtet sich,
                die Erreichbarkeit der von ihm bestimmten Teile der
                Advertiser-Links für die Dauer der Nutzung des jeweiligen
                Advertiser-Links oder eines den Advertiser-Link enthaltenden
                Advertiser-Werbemittels durch mindestens einen am
                Affiliate-Partnerprogramm teilnehmenden Publisher
                sicherzustellen.
            </p>
            <p>
                (10) Mitteilungspflicht bzgl. gesetzlicher Pflichtangaben: Der
                Advertiser verpflichtet sich, Mobility-Ads auf ggf. gesetzlich
                erforderliche Pflichtangaben hinsichtlich der durch ein
                jeweilige Advertiser-Werbemittels beworbenen Produkte in
                Textform hinzuweisen und Mobility-Ads diese gesetzlich
                erforderlichen Pflichtenangaben spätestens mit
                Zurverfügungstellung des jeweiligen Advertiser-Werbemittels
                vollständig in einem gängigen Dateiformat zur Verfügung zu
                stellen.
            </p>
            <p>
                (11) Mitteilungspflicht bzgl. rechtlicher Einschränkungen bei
                Nutzung der Advertiser-Werbemittel: Sofern und soweit der
                Advertiser und/oder mit ihm in einem Kooperationsverhältnis
                stehende Dritte rechtlichen Einschränkungen hinsichtlich der
                Darstellung oder der mit Mobility-Ads vereinbarten Nutzung oder
                einem sonstigen Einsatz eines Advertiser-Werbemittel rechtlichen
                Einschränkungen, insbesondere durch bestehende
                Unterlassungsverpflichtungen oder -verfügungen, unterliegen,
                wird der Advertiser Mobility-Ads spätestens mit
                Zurverfügungstellung des Advertiser-Werbemittels in Textform
                über diese informieren und Mobility-Ads sämtliche zur Einhaltung
                der jeweiligen Einschränkung erforderlichen Informationen zu
                Verfügung stellen. Mobility-Ads hat das Recht, die Vermittlung
                der Einbindung eines jeden solchen Einschränkungen
                unterliegenden Advertiser-Werbemittels über MoAds ohne Angabe
                von Gründen zu untersagen. In dem Fall, dass dem Advertiser eine
                Einschränkung im Sinne des Satz 1 erst nach Zurverfügungstellung
                des jeweiligen Advertiser-Werbemittels bekannt wird, wird er
                Mobility-Ads unverzüglich über diese in Schriftform informieren.
            </p>
            <p>
                (12) Beschwerdemitteilungen: Der Advertiser wird Mobility-Ads
                über Beschwerden über am Affiliate-Partnerprogramm teilnehmende
                Publisher (bzw. Sub-Publisher) oder Verletzungen dieser AGB
                durch Publisher (bzw. Sub-Publisher) unverzüglich informieren.
            </p>
            <p>
                (13) Publisher-Beschränkungsverbot: Der Advertiser verpflichtet
                sich, für die Dauer seiner Teilnahme an MoAds keine Vereinbarung
                mit einem Publisher abzuschließen, die den Publisher daran
                hindert oder ihn darin beschränkt oder ihm Anreize entzieht,
                andere Advertiser und/oder deren Produkte zu bewerben. Im Falle
                eines Verstoßes des Advertisers gegen die Verpflichtung aus Satz
                1 hat Mobility-Ads einen Anspruch auf eine Vertragsstrafenzahlung in Höhe von 10% der seit Beginn der gegen die
                Verpflichtung aus Satz verstoßende Vereinbarung (im Folgenden
                „Strafbemessungszeitraum“) direkt oder indirekt an den oder die
                betroffenen Advertiser ausgezahlten Provisionen. Der
                Strafbemessungszeitraum ist zeitlich auf 12 Kalendermonate
                beschränkt. Eventuelle weitergehende Schadensersatzansprüche
                von Mobility-Ads gegen den Advertiser werden durch diese
                Bestimmung nicht berührt. Im Falle eines Verstoßes des
                Advertisers gegen die Verpflichtung aus Satz 1 hat Mobility-Ads
                das Recht, die Teilnahme des Advertisers an MoAds aus wichtigem
                Grund fristlos zu kündigen.
            </p>

            <h4>§4 Exklusivität des Affiliate-Partnerprogramms</h4>
            <p>
                (1) Sofern und so weit MobilityAds und der Advertiser im
                Einzelauftrag eine Exklusivität vereinbart haben, verpflichtet
                sich der Advertiser zum Schutz der Investitionen und des
                Know-hows von MobilityAds für den im Einzelauftrag benannten
                Zeitraum kein Affiliate-Partnerprogramm oder eine kerngleiche
                Vertriebsmaßnahme zur Bewerbung der Produkte über einen Dritten
                anzubieten und/oder zu betreiben.
            </p>
            <p>
                (2) Die Verpflichtung nach Abs. 1 entfällt, sofern und sobald
                die Teilnahme des Advertisers (als Advertiser) an MoAds vor
                Ablauf des im Einzelauftrag benannten Zeitraums der Exklusivität
                beendet wird.
            </p>

            <h4>
                §5 Teilnahme als Publisher an einem Affiliate-Partnerprogramm
            </h4>
            <p>
                (1) Der Publisher erhält die Möglichkeit, über MoAds als
                Publisher am Affiliate-Partnerprogramm eines oder mehrerer
                Advertiser/s teilzunehmen, indem er bei MoAds im Publisher
                zugewiesenen Bereich die Online-Anmeldemöglichkeit/en zur Teilnahme als Publisher am
                Affiliate-Partnerprogramm des jeweiligen Advertisers durchläuft.
            </p>
            <p>
                (2) Das Vorhalten einer Online-Anmeldemöglichkeit am
                Affiliate-Partnerprogramm an MoAds stellt kein verbindliches
                Angebot eines Advertisers oder Mobility-Ads zur Teilnahme als
                Publisher an einem Affiliate-Partnerprogramm des Advertisers
                dar, sondern allein eine Einladung zur Abgabe eines Angebots
                durch den Publisher. Der Publisher gibt ein bindendes Angebot
                zur Teilnahme am Affiliate-Partnerprogramm des Advertisers ab,
                indem er bei MoAds in dem für den Publisher vorgehaltenen
                Online-Bereich zur Teilnahme am jeweiligen
                Affiliate-Partnerprogramm etwaige dort vorgehaltenen
                Eingabeformulare wahrheitsgemäß und vollständig ausfüllt und
                unter Zustimmung zu den dort genannten Teilnahmebedingungen der
                Kampagne und zu diesen AGB die Schaltfläche „Absenden“ im
                Bereich „Kampagnenbewerbung“ betätigt..
            </p>
            <p>
                (3) Der Advertiser (oder Mobility-Ads im Namen des Advertisers)
                nimmt das Angebot des Publishers zur Teilnahme am
                Affiliate-Partnerprogramm des Advertisers an, indem er eine
                E-Mail an die vom Publisher im Rahmen seiner Registrierung
                hinterlegten E-Mail-Adresse verschickt, in der er dem Teilnehmer
                die künftige Teilnahme am Affiliate-Partnerprogramm bestätigt
                („Partnerprogramm-Anmeldebestätigungsmail“).
            </p>
            <p>
                (4) Der Advertiser (oder Mobility-Ads im Namen des Advertisers)
                behält sich vor, die Teilnahme eines Publisher am
                Affiliate-Partnerprogramm ohne Angabe von Gründen abzulehnen.
            </p>

            <h4>
                §6 Besondere Bedingungen für an MoAds teilnehmende Publisher
            </h4>
            <p>
                (1) Einbindungsrecht bzgl. Advertiser-Werbemitteln: Der
                Publisher kann von Advertisern, an deren
                Affiliate-Partnerprogramm er teilnimmt, die Möglichkeit erhalten,
                Advertiser-Werbemittel auf seinen Publisher-Seiten einzubinden.
            </p>
            <p>
                (2) Einhaltungspflicht bzgl. besonderer Bedingungen des
                Advertisers: Advertiser können die Teilnahme als Publisher an
                ihrem Affiliate-Partnerprogramm und/oder die Nutzung bestimmter
                Advertiser-Werbemittel von bestimmten Bedingungen ggf. in
                eigenen Allgemeinen Geschäftsbedingungen abhängig machen. In
                diesem Fall verpflichtet sich der Publisher, auch die ihm vom
                Advertiser mitgeteilten Bedingungen einzuhalten.
            </p>
            <p>
                (3) Erfordernis der Teilnahme am Affiliate-Partnerprogramm: Der
                Publisher verpflichtet sich, keine Advertiser-Werbemittel von
                Advertisern zu nutzen oder auf den Publisher-Seiten einzubinden,
                an deren Affiliate-Partnerprogramm er nicht teilnimmt, sofern
                und soweit diese Advertiser dem nicht ausdrücklich zugestimmt
                haben.
            </p>
            <p>
                (4) Bearbeitungs- und Änderungsverbot: Der Publisher
                verpflichtet sich, die Advertiser-Werbemittel oder die
                Advertiser-Links oder dynamische
                Advertiser-Werbemittel-Container nicht ohne vorherige
                ausdrückliche Zustimmung von Mobility-Ads oder dem Advertiser zu
                verändern oder zu bearbeiten oder ganz oder teilweise zu
                verkürzen oder zu verdecken.
            </p>
            <p>
                (5) SEA-Freigabeerfordernis: Der Publisher verpflichtet sich,
                Suchmaschinenwerbung bzw. Search Engine Advertising („SEA“) für
                die Advertiser-Werbemittel und/oder die Publisher-Seiten
                und/oder dynamische Advertiser-Werbemittel-Container erst nach
                Freigabe von Mobility-Ads in Textform zu betreiben.
            </p>
            <p>
                (6) Social Media-Freigabeerfordernis: Der Publisher verpflichtet
                sich, Advertiser-Werbemittel und/oder Advertiser-Links, die
                Tracking-Tools enthalten, und/oder dynamische
                Advertiser-Werbemittel-Container nur nach ausdrücklicher
                Freigabe durch Mobility-Ads für den Einzelfall in Textform auf
                Social Media-Plattformen (Facebook, Instagram, Twitter, YouTube,
                Snapchat, LinkedIn etc.) einzubinden oder zu veröffentlichen
                oder in sonstiger Weise zu nutzen.
            </p>
            <p>
                (7) E-Mail-Marketing-Freigabeerfordernis: Der Publisher
                verpflichtet sich die Advertiser-Werbemittel und/oder dynamische
                Advertiser-Werbemittel-Container nur nach ausdrücklicher
                Freigabe von Mobility-Ads für den Einzelfall in Textform per
                E-Mail zur bewerben. Sofern und soweit der Publisher die
                Advertiser-Werbemittel und/oder dynamische
                Advertiser-Werbemittel-Container nach Freigabe nach Satz 1 per
                E-Mail-Werbung bewirbt, verpflichtet sich der Publisher,
                ausschließlich werbliche E-Mail an Empfänger zu verschicken, von
                denen eine rechtswirksame, per Double-Opt-In-Verfahren
                (DOI-Verfahren) dokumentierte Werbeeinwilligung für den Empfang
                der konkreten E-Mail vorliegt. Der Publisher verpflichtet sich,
                Mobility-Ads und/oder den Advertiser auf erstes Anfordern von
                Ansprüchen Dritter freizustellen, die gegenüber Mobility-Ads
                und/oder dem Advertiser wegen eines Verstoßes gegen vorstehende
                Verpflichtung erhoben werden. Von dieser
                Freistellungsverpflichtung umfasst sind die Kosten einer
                angemessenen Rechtsverteidigung. Der Publisher verpflichtet sich
                ohne Freigabe der jeweiligen Partei in Textform keine E-Mails im
                Namen von Mobility-Ads oder dem Advertiser zu verschicken.
                Sofern und soweit ein Publisher mit einem Advertiser und/oder
                mit Mobility-Ads den Versand von werblichen E-Mails durch den
                Publisher im Namen des Advertisers vereinbart, werden die
                Parteien dieser Vereinbarung, soweit datenschutzrechtlich
                erforderlich, einen Vertrag über die Verarbeitung durch einen
                Auftragsverarbeiter im Sinne des Art. 28 Abs. 3 EU-DSGVO
                abschließen.
            </p>
            <p>
                (8) Darstellungspflicht bzgl. Pflichtangaben: Für den Fall, dass
                der Advertiser und/oder Mobility-Ads ein Advertiser-Werbemittel
                und/oder einen dynamischen Advertiser-Werbemittel-Container
                gemeinsam mit vom Publisher einzublendenden Pflichtangaben zur
                Verfügung stellt, ist der Publisher verpflichtet, auch solche
                Pflichtangaben unter Einhaltung der diesbezüglich vom Advertiser
                und/oder von Mobility-Ads vorgegebenen Bedingungen und
                Darstellungsvorgaben bei Einbindung des jeweiligen
                Advertiser-Werbemittels und/oder dynamischen
                Advertiser-Werbemittel-Containers mit darzustellen; dies können
                Angaben sein, die direkt einer gesetzlichen Pflicht entstammen
                oder aber auch jede beliebige andere Angabe, die der Advertiser
                und/oder Mobility-Ads als Pflichtangabe kennzeichnet.
            </p>
            <p>
                (9) Compliance des Publisher-Werbeumfelds: Der Publisher
                verpflichtet sich, sicherzustellen, dass das
                Publisher-Werbeumfeld im Einklang mit allen anwendbaren
                gesetzlichen Bestimmungen und behördlichen Regelungen und
                gerichtlichen Anordnungen, insbesondere dem Gesetz gegen den
                unlauteren Wettbewerb (UWG), den jugendschutzrechtlichen
                Vorschriften v.a. dem Jugendschutzgesetz (JuSchG) und dem
                Jugendmedienschutz-Staatsvertrag (JMStV), dem Telemediengesetz
                (TMG); dem Bundesdatenschutzgesetz (BDSG), der
                Datenschutz-Grundverordnung der Europäischen Union (EU-DSGVO)
                und verbraucherschützende Vorschriften, insbesondere den
                fernabsatzrechtlichen Vorschriften des Bürgerlichen Gesetzbuch
                (BGB) stehen und deren Anforderungen erfüllen.
            </p>
            <p>
                (10) Sonstige Bedingungen an Inhalt der Werbemittel: Der
                Publisher verpflichtet sich, sicherzustellen, dass das
                Publisher-Werbeumfeld keine Inhalte enthält,
            </p>
            <p>
                a) die einen pornographischen oder jugendgefährdenden Inhalt
                haben oder;
            </p>
            <p>b) die gegen die guten Sitten verstoßen oder;</p>
            <p>
                c) die ehrverletzende oder hinsichtlich Rasse, Geschlecht,
                Religion, Nationalität, Behinderung, sexuelle Orientierung oder
                Alter diskriminierende Aussagen oder Darstellungen enthalten.
            </p>
            <p>
                (11) Einsatz von Sub-Publishern: Sofern und soweit ein
                Affiliate-Partnerprogramm den Einsatz von Sub-Publishern erlaubt
                und Mobility-Ads dem Publisher seine Freigabe zum Einsatz von
                Sub-Publishern in Textform erteilt hat, kann der Publisher für
                die Dauer seiner Teilnahme am Affiliate-Partnerprogramm des
                Advertisers Sub-Publisher unterbeauftragen,
                Advertiser-Werbemittel und/oder dynamische
                Advertiser-Werbemittel-Container auf den Seiten der Publisher
                einzubinden, es sei denn, die im ersten Halbsatz genannte
                Erlaubnis oder Freigabe wird mit Wirkung für die Zukunft
                widerrufen. Der Publisher verpflichtet sich, von ihm eingesetzte
                Sub-Publisher entsprechend der Bestimmungen dieser AGB zu
                verpflichten. Ferner verpflichtet sich der Publisher gegenüber
                Mobility-Ads und/oder dem Advertiser für die Einhaltung der sich
                aus diesen AGB und/oder eventuellen sonstigen Bedingungen des
                Advertisers ergebenden Pflichten des Publisher durch die von ihm
                eingesetzten Sub-Publisher einzustehen. Der Publisher wird
                Mobility-Ads auf erstes Anfragen unverzüglich vollumfänglich
                über den Einsatz von Sub-Publishern informieren.
            </p>

            <h4>§7 Nutzungsrechte</h4>
            <p>
                (1) Der Advertiser überträgt an Mobility-Ads für die Dauer
                seiner Teilnahme an MoAds sowie an die an seinem
                Affiliate-Partnerprogramm teilnehmenden Publisher für die Dauer
                ihrer Teilnahme am Affiliate-Partnerprogramm zum Zweck des
                vertragsgegenständlichen Affiliate-Marketings die
                nicht-exklusiven, inhaltlich und territorial unbegrenzten
                Nutzungsrechte an den Advertiser-Werbemitteln, insbesondere das
                Vervielfältigungsrecht, das Verbreitungsrecht, das Recht zur
                Wiedergabe durch Bild- und Tonträger, das Recht der öffentlichen
                Zugänglichmachung und das Archivrecht.
            </p>
            <p>
                (2) Sofern und soweit ein Affiliate-Partnerprogramm den Einsatz
                von Sub-Publishern erlaubt und Mobility-Ads dem Publisher seine
                Freigabe zum Einsatz von Sub-Publishern in Textform erteilt hat,
                räumt der Advertiser dem Publisher das Recht ein, die in Abs. 1
                genannten Nutzungsrechte für die Dauer des Einsatzes des
                jeweiligen Sub-Publishers an die Publisher unter den übrigen
                Beschränkungen des Abs. 1 zu übertragen.
            </p>

            <h4>§8 Provision / Vergütung</h4>
            <p>
                (1) Je Eintritt der für das jeweilige Advertiser-Werbemittel
                bestimmten vergütungsauslösenden Bedingung hat der Publisher
                gegen Mobility-Ads einen Anspruch auf die für das jeweilige
                Advertiser-Werbemittel bestimmte Provision, sofern Mobility-Ads
                für den Eintritt der jeweiligen vergütungsauslösenden Bedingung
                nach Abs. 5 ein durchsetzbarer Netzwerk-Provisionsanspruch gegen
                den Advertiser entsteht. Die vergütungsauslösende Bedingung und
                die Höhe der Provision ergibt sich, sofern und soweit nichts
                anderes vereinbart ist, aus der den für das jeweilige
                Advertiser-Werbemittel bei MoAds hinterlegten
                Vergütungsbedingungen.
            </p>
            <p>
                (2) Mobility-Ads wird dem Advertiser und dem Publisher zu
                Abrechnungszwecken eine monatliche Gesamtübersicht der im
                Vormonat eingetretenen nach Abs. 1 vergütungsauslösenden
                Bedingungen bei MoAds zur Verfügung stellen bzw. einsehbar
                machen.
            </p>
            <p>
                (3) Die Fälligkeit der Provision bestimmt sich nach den bei
                MoAds für das jeweilige Advertiser-Werbemittel hinterlegten
                Vergütungsbedingungen. Sofern und soweit in den
                Vergütungsbedingungen keine Angaben zur Fälligkeit der Provision
                gemacht sind, ist die Netzwerk-Provision innerhalb von 30 Tagen
                ab Beendigung des Kalendermonats, in dem der Eintritt der
                vergütungsauslösenden Bedingung vom Advertiser validiert wurde,
                fällig.
            </p>
            <p>
                (4) Voraussetzung für die Auszahlung von Provisionen ist die
                Hinterlegung einer gültigen Bankverbindung sowie die
                Bereitstellung der erforderlichen steuerlichen Angaben
                (Steuernummer bzw. USt. IDNr.) durch den Publisher in MoAds.
            </p>
            <p>
                (5) Je Eintritt der für das jeweilige Advertiser-Werbemittel
                bestimmten vergütungsauslösenden Bedingung hat Mobility-Ads
                gegen den Advertiser einen Anspruch auf Zahlung einer
                Netzwerk-Provision in der im Einzelauftrag vereinbarten Höhe.
            </p>
            <p>
                (6) Die Fälligkeit der Netzwerk-Provision bestimmt sich nach dem
                Einzelauftrag. Sofern und soweit im Einzelauftrag keine
                Fälligkeit der Netzwerk-Provision vereinbart ist, ist die
                Netzwerk-Provision innerhalb von 14 Tagen ab Rechnungsstellung
                durch Mobility-Ads fällig. In dem Fall, dass Mobility-Ads und
                der Advertiser im Einzelauftrag oder einer sonstigen Vereinbarung
                vereinbaren, dass die nach Abs. 5 geschuldete Netzwerk-Provision
                oder ein Teil davon vom Advertiser an Mobility-Ads vorab per
                Vorkasse zu zahlen ist (im Folgenden
                „Prepaid-Netzwerk-Provisionszahlung“) wird Mobility-Ads dem
                Advertiser den vereinbarten Betrag der Prepaid
                Netzwerk-Provisionszahlung zur Vorabzahlung in Rechnung stellen
                und die ihm nach Abs. 5 geschuldete Netzwerk-Provision mit
                dieser Zahlung aufrechnen. Sofern und sobald der vereinbarte
                Betrag der Prepaid Netzwerk-Provisionszahlung nicht oder nach
                Abzug zuvor erfolgter Aufrechnungen nicht mehr ausreicht, um mit
                die nach Abs. 5 geschuldeten Netzwerk-Provision vollständig
                aufzurechnen, wird der Mobility-Ads dem Advertiser erneut den im
                Einzelauftrag vereinbarten Betrag der
                Prepaid-Netzwerk-Provisionszahlung zur Vorabzahlung in Rechnung
                stellen. Sofern und soweit im Einzelauftrag keine Fälligkeit der
                Prepaid-Netzwerk-Provisionszahlung vereinbart ist, ist diese
                innerhalb von 14 Tagen nach Rechnungsstellung fällig. Bei
                Beendigung der Teilnahme des Advertisers an MoAds oder nach
                Abschluss eines mit zwischen Mobility-Ads und dem Advertiser
                vereinbarten Prepaid-Netzwerk-Provisions-Abrechnungszeitraums
                wird Mobility-Ads dem Advertiser ggf. ausstehende bzw. nicht
                aufgerechnete Beträge aus Prepaid-Netzwerk-Provisionszahlungen
                innerhalb von 30 Tagen ab Beendigung der Teilnahme des
                Advertisers an MoAds auf die vom Advertiser gegenüber
                Mobility-Ads angegebene Bankverbindung zurückzahlen. Sofern im
                Einzelauftrag kein
                Prepaid-Netzwerk-Provisions-Abrechnungszeitraum vereinbart ist,
                wird Moblility-Ads die vorstehende Zurückzahlung spätestens
                innerhalb von einem Kalenderjahr ab Fälligkeit Zahlung der
                jeweiligen Prepaid-Netzwerk-Provision vornehmen.
            </p>
            <p>
                (7) Sofern und soweit einmalige Zahlungen des Advertisers (im
                Folgenden „Einmalzahlungen“) z. B. als Setup-Gebühren,
                Werbekostenzuschuss (WKZ) oder Gegenleistung für die Erstellung
                von Werbemitteln oder -seiten im Einzelauftrag bestimmt sind,
                hat Mobility-Ads gegen den Advertiser einen Anspruch auf Zahlung
                der Einmalzahlungen in der im Einzelauftrag bestimmten Höhe.
            </p>
            <p>
                (8) Die Fälligkeit einer Einmalzahlung bestimmt sich nach dem
                Einzelauftrag. Ist im Einzelauftrag keine Fälligkeit bzgl. einer
                Einmalzahlung bestimmt, ist die Einmalzahlung innerhalb von 30
                Tagen ab Rechnungsstellung durch Mobility-Ads fällig.
            </p>
            <p>
                (9) Alle angegebenen, im Rahmen der Teilnahme an MoAds zu
                zahlenden Beträge verstehen sich exklusive Mehrwertsteuer, die
                gegebenenfalls mit dem entsprechenden Satz hinzugefügt wird. Die
                Mehrwertsteuer ist nach geltendem Recht von der
                mehrwertsteuerpflichtigen Partei zu entrichten.
            </p>

            <h4>§9 Vertraulichkeit</h4>
            <p>
                (1) Jeder Teilnehmer verpflichtet sich, über sämtliche
                Geschäfts- und Betriebsgeheimnisse eines anderen Teilnehmers
                oder Mobility-Ads, die sich aus der Teilnahme an MoAds ergeben,
                Stillschweigen zu bewahren.
            </p>
            <p>
                (2) Diese Verpflichtung zur Geheimhaltung entfällt, sofern die
                Information ohne Verletzung dieser Geheimhaltungspflicht
                offenkundig werden oder bereits offenkundig bzw. öffentlich
                zugänglich sind oder soweit der Teilnehmer kraft Gesetzes zur
                Offenlegung der Information verpflichtet ist.
            </p>
            <p>
                (3) Die Verpflichtungen aus Abs. 1 entfalten bis fünf Jahre nach
                Beendigung der Teilnahme an MoAds weiterhin Gültigkeit.
            </p>

            <h4>§10 Laufzeit / Kündigung</h4>
            <p>
                (1) Die Laufzeit der Teilnahme des Teilnehmers an MoAds als
                Advertiser hinsichtlich eines konkreten Einzelauftrags ist im
                Einzelauftrag selbst festgelegt. Ist im Einzelauftrag keine
                Laufzeit genannt, läuft die Teilnahme des Teilnehmers an
                Mobility-Ads für diesen Einzelauftrag auf unbestimmte Zeit.
            </p>
            <p>
                (2) Laufzeit und Kündigungsmöglichkeit betreffend jede einzelne
                Teilnahme als Publisher an einem Affiliate-Partnerprogramm wird
                in dem jeweils von MoAds vorgehaltenen Bereich für die
                Online-Anmeldemöglichkeit zur Teilnahme als Publisher am
                Affiliate-Partnerprogramm ausgewiesen. War zum Zeitpunkt des
                Angebots des Publishers auf Teilnahme an einem
                Affiliate-Partnerprogramm keine Laufzeit genannt, läuft eine
                solche Teilnahme als Publisher an einem
                Affiliate-Partnerprogramm auf unbestimmte Zeit und ist dann von
                jeder Vertragspartei jederzeit kündbar.
            </p>
            <p>(3) Die Teilnahme an MoAds</p>
            <p>
                a) als Publisher kann von Mobility-Ads oder einem allein als
                Publisher an MoAds teilnehmenden Teilnehmer mit einer Frist von
                14 Tagen jeweils ohne Angaben von Gründen gekündigt werden.
            </p>
            <p>
                b) als Advertiser kann von Mobility-Ads mit einer Frist von 14
                Tagen oder vom als Advertiser an MoAds teilnehmenden Teilnehmer
                mit einer Frist von 3 Monaten jeweils ohne Angaben von Gründen
                gekündigt werden, sofern und soweit die Teilnahme des
                Advertisers zum Zeitpunkt des Wirksamwerdens der Kündigung die
                Mindestvertragslaufzeit für Advertiser von 12 Monaten erreicht
                oder überschritten hat. Eine ordentliche Kündigung des
                Advertisers während der Mindestvertragslaufzeit ist
                ausgeschlossen.
            </p>
            <p>
                (4) Das Recht zur fristlosen Kündigung aus wichtigem Grund
                bleibt unberührt.
            </p>
            <p>(5) Jede Kündigung bedarf der Textform.</p>

            <h4>§11 Haftung</h4>
            <p>
                (1) Mobility-Ads haftet dem Teilnehmer auf Schadensersatz und
                Ersatz der vergeblichen Aufwendungen (nachfolgend einzeln oder
                gemeinsam „Schadensersatz“) wegen Verletzung vertraglicher oder
                außervertraglicher Pflichten nur:
            </p>
            <p>a) bei Vorsatz oder grober Fahrlässigkeit oder;</p>
            <p>
                b) bei vorsätzlicher oder fahrlässiger Verletzung des Lebens,
                des Körpers oder der Gesundheit oder;
            </p>
            <p>c) aufgrund der Übernahme einer Beschaffungsgarantie oder;</p>
            <p>
                d) bei vorsätzlicher oder fahrlässiger Verletzung einer Pflicht,
                welche die Durchführung der diesen AGB zu Grunde liegenden
                vertraglichen Vereinbarung erst ermöglicht und auf deren
                Erfüllung sich der Teilnehmer deswegen regelmäßig verlassen darf
                („wesentliche Vertragspflicht“) oder;
            </p>
            <p>
                e) aufgrund zwingender Haftung nach dem Produkthaftungsgesetz
                oder;
            </p>
            <p>f) aufgrund sonstiger zwingender Haftung.</p>
            <p>
                (2) Der Schadensersatz für die Verletzung von wesentlichen
                Vertragspflichten ist auf den vertragstypischen vorhersehbaren
                Schaden begrenzt, sofern und soweit nicht Vorsatz oder grobe
                Fahrlässigkeit vorliegt oder wegen der Verletzung des Lebens,
                des Körpers oder der Gesundheit oder der Übernehme einer
                Beschaffenheitsgarantie gehaftet wird.
            </p>
            <p>
                (3) Die Haftung für entgangenen Gewinn, Produktionsausfälle und
                -unterbrechungen, Folgeschäden, indirekte Schäden und/oder
                sonstige Vermögensschäden des Teilnehmers ist ausgeschlossen,
                sofern und soweit nicht Vorsatz oder grobe Fahrlässigkeit
                vorliegt oder wegen der Verletzung des Lebens, des Körpers oder
                der Gesundheit oder der Übernehme einer Beschaffenheitsgarantie
                gehaftet wird.
            </p>
            <p>
                (4) Schadensersatzansprüche gegen Mobility-Ads, gleich aus
                welchem Rechtsgrund, sind in anderen als den vorstehend
                vereinbarten Fällen ausgeschlossen.
            </p>
            <p>
                (5) Eine Änderung der Beweislast zum Nachteil des Teilnehmers
                ist mit den vorstehenden Regelungen nicht verbunden.
            </p>
            <p>
                (6) Zwischen Mobility-Ads und dem Teilnehmer entsteht durch
                diese AGB oder diesen AGB zu Grunde liegenden Vereinbarungen
                weder ein Gesellschafts- noch ein Handelsvertreterverhältnis.
            </p>

            <h4>§12 Datenschutz</h4>
            <p>
                Mobility-Ads und die Teilnehmer verpflichten sich zur Einhaltung
                der datenschutzrechtlichen Bestimmungen. Die Datenschutzhinweise
                von Mobility-Ads können unter
                <a href="https://www.mobility-ads.de/datenschutz">
                    https://www.mobility-ads.de/datenschutz
                </a>
                eingesehen werden.
            </p>

            <h4>§13 Änderung dieser AGB</h4>
            <p>
                (1) Mobility-Ads kann diese AGB ändern, soweit hierdurch
                wesentliche Regelungen des Vertragsverhältnisses nicht berührt
                werden und soweit dies zur Anpassung an Entwicklungen
                erforderlich ist, welche bei Vertragsschluss nicht vorhersehbar
                waren und welche Mobility-Ads weder veranlasst hat noch
                beeinflussen kann und deren Nichtberücksichtigung die
                Ausgewogenheit des Vertragsverhältnisses in nicht unbedeutendem
                Maße stören würde. Wesentliche Regelungen dieses
                Vertragsverhältnisses sind solche über Art und Umfang der
                vertraglich vereinbarten Leistungen und die Laufzeit
                einschließlich der Regelungen zur Kündigung.
            </p>
            <p>
                (2) Außerdem kann Mobility-Ads diese AGB anpassen, soweit dies
                zur Beseitigung von nicht unerheblichen Schwierigkeiten bei der
                Durchführung des Vertrages aufgrund von nach Vertragsschluss
                entstandenen Regelungslücken erforderlich ist. Dies kann
                insbesondere der Fall sein, wenn eine oder mehrere Bestimmungen
                dieser durch eine Gesetzänderung unwirksam oder von der
                Rechtsprechung für unwirksam erklärt werden oder wenn sich die
                Rechtsprechung zur Wirksamkeit von einer oder mehrerer
                Bestimmungen dieser AGB ändert.
            </p>
            <p>
                (3) Mobility-Ads kann die vertraglich vereinbarten Leistungen
                ändern, wenn und soweit dies aus einem triftigem, bei
                Vertragsschluss nicht vorhersehbarem Grund erforderlich wird und
                das Verhältnis von Leistung und Gegenleistung durch diese
                Änderung derart nicht zuungunsten des Teilnehmers verschoben
                wird, dass die Änderung für den Teilnehmer nicht zumutbar wäre.
                Ein triftiger Grund liegt vor, wenn neue technische
                Entwicklungen eine Leistungsänderung erforderlich machen, da
                Mobility-Ads die Leistung nicht mehr in der bis dahin
                vertraglich vereinbarten Form erbringen kann oder wenn
                Gesetzesänderungen oder -erlasse oder sonstige hoheitliche
                Vorgaben eine Änderung der Leistungen erforderlich machen.
            </p>
            <p>
                (4) Mobility-Ads wird dem Teilnehmer Änderungen dieser AGB oder
                der vertraglich vereinbarten Leistungen mindestens vier Wochen
                vor ihrem geplanten Wirksamwerden in Textform mitteilen.
            </p>
            <p>
                (5) Bei Änderungen, die nicht ausschließlich zu seinen Gunsten
                sind, ist der Teilnehmer berechtigt, die Teilnahme an
                Mobility-Ads ohne Einhaltung einer Kündigungsfrist zum Zeitpunkt
                des Wirksamwerdens der Änderungen in Textform zu kündigen.
            </p>
            <p>
                (6) Änderungen dieser Vereinbarung oder der vertraglich
                vereinbarten Leistungen gelten als vom Teilnehmer genehmigt,
                wenn der Teilnehmer ihnen nicht bis zu deren Wirksamwerden
                widerspricht. Mobility-Ads wird den Teilnehmer bei Mitteilung
                der Änderung nach Abs. 4 auf sein Kündigungsrecht und die
                Bedeutung eines fehlenden Widerspruchs hinweisen.
            </p>

            <h4>§14 Sonstiges</h4>
            <p>
                (1) Sämtliche Streitigkeiten aus diesen AGB unterliegen dem
                Recht der Bundesrepublik Deutschland unter Ausschluss des
                UN-Kaufrechts.
            </p>
            <p>
                (2) Sofern ein Schriftformerfordernis in diesen AGB vereinbart
                wurde, wird dieses auch durch Erklärungen per Post, Fax oder
                E-Mail gewahrt.
            </p>
            <p>
                (3) Sollte ein Teil dieser AGB nichtig oder unwirksam sein oder
                werden, so soll an die Stelle der nichtigen oder unwirksamen
                Bestimmung eine angemessene Ersatzregelung treten, die dem Geist
                dieses Vertrags gerecht wird und von der angenommen werden kann,
                dass die Vertragschließenden sie vereinbart hätten, wenn sie die
                Nichtigkeit gekannt hätten. Die übrigen Bestimmungen dieses
                Vertrags bleiben von der Nichtigkeit oder Unwirksamkeit
                unberührt.
            </p>
            <p>
                (4) Gerichtsstand ist Berlin, sofern alle beteiligten Parteien
                Kaufmann sind.
            </p>
            <br />
            <br />
            <p>Version: 1.1</p>
            <p>Stand: 24.08.2021</p>
            <p>Online-Streitbeilegung:</p>
            <p>
                Die Europäische Kommission stellt eine Plattform für die
                außergerichtliche Online-Streitbeilegung (OS-Plattform) bereit,
                die unter www.ec.europa.eu/consumers/odr aufrufbar ist. Unsere
                E-Mail-Adresse finden Sie in unserem Impressum. Wir nehmen
                freiwillig am Streitschlichtungsverfahren teil. Eine Liste mit
                den Kontaktdaten der anerkannten Streitschlichtungsstellen
                finden Sie unter
                <a
                    href="https://ec.europa.eu/consumers/odr/main/index.cfm?event=main.adr.show.">
                    https://ec.europa.eu/consumers/odr/main/index.cfm?event=main.adr.show.
                </a>
            </p>
        </div>
    </div>
</section>
