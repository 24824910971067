<section class="bar">
    <img src="assets/img/hero/autohandler-hero-bg-dark.webp" class="hero__image-bg only-on-dark" alt="" />
    <img src="assets/img/hero/autohandler-hero-bg.webp" class="hero__image-bg only-on-light" alt="" />
    <div class="container">
        <!-- <span class="subtitle">KONTAKT</span> -->
        <h1>Jetzt Kontakt aufnehmen!</h1>
        <p class="mb-0">
            Schreiben Sie uns, bevor die Ampel auf Grün schaltet. Nutzen Sie den Turbo-Boost für Ihre
            Online-Sichtbarkeit. Wir unterstützen Sie und freuen uns auf Ihre Nachricht!
        </p>
    </div>
</section>

<section class="contact-form">
    <div class="container">
        <div class="d-flex">
            <div class="col-5">
                <h3>Einfach ausfüllen und abschicken!</h3>
                <p class="muted">Schreiben Sie Ihr Anliegen ins Formular – wir antworten innerhalb von 24 Stunden.</p>
                <hr />
                <div class="hide-on-mobile">
                    <p>
                        <b>
                            Mobility-Ads GmbH
                            <br />
                        </b>
                        Stresemannstraße 23
                        <br />
                        10963 Berlin
                    </p>
                    <p>
                        <span>
                            <b>E-Mail:&nbsp;</b>
                            <a href="mailto:info@mobility-ads.de">
                                <span>info&#64;mobility-ads.de</span>
                            </a>
                            <br />
                        </span>
                        <span>
                            <b>Telefon:&nbsp;</b>
                            <a href="tel:0300800987840">
                                <span>030 - 800987840</span>
                            </a>
                            <br />
                        </span>
                    </p>
                </div>
            </div>
            <div class="col-7">
                <form
                    [formGroup]="contactForm"
                    (ngSubmit)="onSubmit()"
                    [class.disabled]="submitSuccess">
                    <label for="firstName">
                        Vorname
                        <span class="required">*</span>
                    </label>
                    <input
                        type="text"
                        formControlName="firstName"
                        placeholder="Dein Vorname"
                        autocomplete="given-name" />

                    @if (contactForm.controls['firstName'].invalid && contactForm.controls['firstName'].touched) {
                        @if (contactForm.controls['firstName'].hasError('required')) {
                            <div>
                                <small>Vorname ist erforderlich.</small>
                            </div>
                        }

                        @if (contactForm.controls['firstName'].hasError('minlength')) {
                            <div>
                                <small>Vorname muss mindestens 2 Zeichen lang sein.</small>
                            </div>
                        }

                        @if (contactForm.controls['firstName'].hasError('maxlength')) {
                            <div>
                                <small>Vorname darf 64 Zeichen nicht überschreiten.</small>
                            </div>
                        }
                    }

                    <label for="lastName">
                        Nachname
                        <span class="required">*</span>
                    </label>
                    <input
                        type="text"
                        formControlName="lastName"
                        placeholder="Dein Nachname"
                        autocomplete="family-name" />

                    @if (contactForm.controls['lastName'].invalid && contactForm.controls['lastName'].touched) {
                        @if (contactForm.controls['lastName'].hasError('required')) {
                            <div>
                                <small>Nachname ist erforderlich.</small>
                            </div>
                        }

                        @if (contactForm.controls['lastName'].hasError('minlength')) {
                            <div>
                                <small>Nachname muss mindestens 2 Zeichen lang sein.</small>
                            </div>
                        }

                        @if (contactForm.controls['lastName'].hasError('maxlength')) {
                            <div>
                                <small>Nachname darf 64 Zeichen nicht überschreiten.</small>
                            </div>
                        }
                    }

                    <label for="email">
                        E-Mail
                        <span class="required">*</span>
                    </label>
                    <input
                        type="email"
                        formControlName="email"
                        placeholder="Deine E-Mail-Adresse"
                        autocomplete="email" />

                    @if (contactForm.controls['email'].invalid && contactForm.controls['email'].touched) {
                        @if (contactForm.controls['email'].hasError('required')) {
                            <div>
                                <small>E-mail ist erforderlich.</small>
                            </div>
                        }

                        @if (contactForm.controls['email'].hasError('email')) {
                            <div>
                                <small>Bitte eine gültige E-Mail-Adresse eingeben.</small>
                            </div>
                        }

                        @if (contactForm.controls['email'].hasError('maxlength')) {
                            <div>
                                <small>E-mail darf 64 Zeichen nicht überschreiten.</small>
                            </div>
                        }
                    }

                    <label for="contactViaPhone">
                        <input type="checkbox" formControlName="contactViaPhone" />
                        Bitte kontaktieren Sie mich per Telefon.
                    </label>

                    @if (contactForm.controls['contactViaPhone'].value) {
                        <label for="phone">
                            Telefonnummer
                            <span class="required">*</span>
                        </label>
                        <input
                            type="tel"
                            formControlName="phone"
                            placeholder="Deine Telefonnummer"
                            autocomplete="tel" />

                        @if (contactForm.controls['phone'].invalid && contactForm.controls['phone'].touched) {
                            @if (contactForm.controls['phone'].hasError('required')) {
                                <div>
                                    <small>Telefonnummer ist erforderlich.</small>
                                </div>
                            }

                            @if (contactForm.controls['phone'].hasError('minlength')) {
                                <div>
                                    <small>Telefonnummer muss mindestens 4 Zeichen lang sein.</small>
                                </div>
                            }

                            @if (contactForm.controls['phone'].hasError('maxlength')) {
                                <div>
                                    <small>Telefonnummer darf 20 Zeichen nicht überschreiten.</small>
                                </div>
                            }
                        }
                    }

                    <label for="message">
                        Thema
                        <span class="required">*</span>
                    </label>
                    <select formControlName="intent">
                        <option value="" disabled selected>Bitte wählen Sie eine Option</option>
                        <option [value]="intent.MERCHANT">Ich bin ein Händler und möchte Kontakt aufnehmen.</option>
                        <option [value]="intent.PUBLISHER">Ich bin ein Publisher und möchte Kontakt aufnehmen.</option>
                        <option [value]="intent.JOB">Ich suche eine neue berufliche Herausforderung.</option>
                        <option [value]="intent.SUPPORT">Ich benötige technischen Support.</option>
                        <option [value]="intent.OTHER">Ich habe andere Frage.</option>
                    </select>
                    @if (contactForm.controls['intent'].invalid && contactForm.controls['intent'].touched) {
                        <div>
                            <small>Thema ist erforderlich</small>
                        </div>
                    }

                    <label for="message">Nachricht</label>
                    <textarea
                        formControlName="message"
                        placeholder="Hier kannst Du Deine Mitteilung an uns eintragen."
                        rows="5"
                        autocomplete="off"></textarea>

                    <label for="policy">
                        <input type="checkbox" formControlName="policy" />
                        Hiermit bestätige ich, dass meine Daten nur zur Kontaktaufnahme dieser überreichten Anfrage be-
                        und verarbeitet werden.
                        <a [routerLink]="['/datenschutz']">Informationen zum Datenschutz</a>
                    </label>

                    @if (contactForm.controls['policy'].invalid && contactForm.controls['policy'].touched) {
                        <div>
                            <small>Du musst die Datenschutzrichtlinie bestätigen</small>
                        </div>
                    }

                    <button
                        type="submit"
                        class="button-2 reverse"
                        [disabled]="contactForm.invalid"
                        [class.loading]="loading$ | async">
                        <b>Durchstarten</b>
                        <figure class="arrow"></figure>
                        <figure class="loader"></figure>
                    </button>
                    @if (submitSuccess !== null) {
                        @if (submitSuccess) {
                            <div class="success-box">
                                <small class="success">
                                    Kontaktanfrage erfolgreich gestellt.
                                    <br />
                                    Danke, dass Sie uns erreicht haben!
                                </small>
                            </div>
                        } @else {
                            <div>
                                <small>
                                    Uups... Fehler beim Senden der Kontaktanfrage.
                                    <br />
                                    Bitte versuchen Sie es später noch einmal!
                                </small>
                            </div>
                        }
                    }
                </form>

                <div class="hide-on-desktop">
                    <hr />
                    <hr />
                    <p>
                        <b>
                            Mobility-Ads GmbH
                            <br />
                        </b>
                        Stresemannstraße 23
                        <br />
                        10963 Berlin
                    </p>
                    <p>
                        <span>
                            <b>E-Mail: </b>
                            <a href="mailto:info@mobility-ads.de">
                                <span>info&#64;mobility-ads.de</span>
                            </a>
                            <br />
                        </span>
                        <span>
                            <b>Telefon: </b>
                            <a href="tel:0300800987840">
                                <span>030 - 800987840</span>
                            </a>
                            <br />
                        </span>
                    </p>
                </div>
            </div>
        </div>
    </div>
</section>
