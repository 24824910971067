<app-hero page="about"></app-hero>

<section class="quote">
    <div class="container">
        <app-bento #bento>
            <app-bento-item col="12" row="2" direction="middle" class="quote">
                <div class="quote__ceo">
                    <div class="quote__ceo-img">
                        <img
                            src="assets/img/team/fatih-pp.webp"
                            alt="Fatih Özcan" />
                    </div>
                    <div class="quote__ceo-content">
                        <div class="quote__ceo-text">
                            <p>
                                Wir leben Mobilität und treiben den digitalen
                                Wandel im Automobilhandel voran. Mit unserer
                                Expertise und Leidenschaft schaffen wir
                                zukunftsfähige Lösungen, die Ihr Wachstum und
                                Ihren Erfolg beschleunigen.
                            </p>
                        </div>
                        <div class="quote__ceo-author">
                            <span>Fatih Özcan</span>
                            <small>Geschäftsführer, Mobility Ads</small>
                        </div>
                    </div>
                </div>
            </app-bento-item>
        </app-bento>
    </div>
</section>

<!-- <section class="banner no-bg">
    <div class="container">
        <div class="gradient-border secondary">
            <div class="gradient-border__content">
                <h2>Unser Ansporn: Ihre digitale Sichtbarkeit und langfristige
                    Wettbewerbsfähigkeit im Automobilsektor!</h2>
            </div>
        </div>
    </div>
</section> -->

<section class="info-box">
    <div class="container">
        <div class="">
            <div class="">
                <p class="subtitle">Unser Mission-Statement:</p>
                <h3 class="mt-0 gradient autohandler">
                    Wir bewegen Mobilität – mit Strategie und Herz.
                </h3>
                <p class="m-0">
                    Wir glauben an die Kraft von Partnerschaften und das
                    Potenzial digitaler Strategien. Bei Mobility Ads setzen wir
                    auf mehr als bloße Zahlen. Wir schaffen Verbindungen, die
                    langfristig und nachhaltig sind. Transparenz, Ownership und
                    Verbindlichkeit sind für uns keine leeren Begriffe, sondern
                    gelebte Werte, die uns zu einer echten Verlängerung des
                    Teams unserer Kunden machen. Gemeinsam wollen wir den
                    Automobilhandel auf das nächste Level bringen – innovativ,
                    zielgerichtet und immer am Puls der Zeit.
                </p>
            </div>
        </div>
    </div>
</section>

<section class="values">
    <div class="container">
        <span class="subtitle">GRUNDSTEIN</span>
        <h2 #reveal>Unsere Werte</h2>
        <br />
        <app-bento #bento>
            <app-bento-item col="4" row="1" direction="left">
                <div class="bento__item-icon-wrapper">
                    <span class="icon">
                        <img
                        src="assets/img/icons/conversation.svg" alt="Transparenz" />
                    </span>
                </div>
                <span class="emphasized">Transparenz</span>
                <p>
                    Klare Kommunikation und Offenheit sind bei uns keine leeren
                    Worte – wir leben es.
                </p>
            </app-bento-item>
            <app-bento-item col="4" row="1" direction="middle">
                <div class="bento__item-icon-wrapper">
                    <span class="icon">
                        <img src="assets/img/icons/knowledge.svg" alt="Ownership" />
                    </span>
                </div>
                <span class="emphasized">Ownership</span>
                <p>
                    Wir übernehmen Verantwortung für unseren Erfolg und den
                    unserer Partner.
                </p>
            </app-bento-item>
            <app-bento-item col="4" row="1" direction="right">
                <div class="bento__item-icon-wrapper">
                    <span class="icon">
                        <img
                            src="assets/img/icons/message-true.svg"
                            alt="Verbindlichkeit" />
                    </span>
                </div>
                <span class="emphasized">Verbindlichkeit</span>
                <p>
                    Was wir sprechen, halten wir auch. Unsere Kunden können sich
                    auf uns verlassen.
                </p>
            </app-bento-item>
        </app-bento>
    </div>
</section>

<section class="about">
    <div class="container">
        <span class="subtitle">INNOVATOREN</span>
        <h2 #reveal>Unser Team</h2>
        <div class="team" #team>
            <ul class="team__inner">
                <li>
                    <img src="assets/img/team/derya-pp.webp" alt="Derya Gül" />
                    <p>Derya Gül</p>
                    <small>Content Managerin</small>
                </li>
                <li>
                    <img src="assets/img/team/oguz-pp.webp" alt="Oguz Özcan" />
                    <p>Oguz Özcan</p>
                    <small>Chief Operating Officer</small>
                </li>
                <li>
                    <img
                        src="assets/img/team/ogulcan-pp.webp"
                        alt="Ogulcan Esen" />
                    <p>Ogulcan Esen</p>
                    <small>Head of Design</small>
                </li>
                <li>
                    <img
                        src="assets/img/team/fatih-pp.webp"
                        alt="Fatih Özcan" />
                    <p>Fatih Özcan</p>
                    <small>Geschäftsführer</small>
                </li>
                <li>
                    <img
                        src="assets/img/team/chris-pp.webp"
                        alt="Christopher Wagner" />
                    <p>Christopher Wagner</p>
                    <small>Chief Technical Officer</small>
                </li>
                <li>
                    <img
                        src="assets/img/team/neslihan-pp.webp"
                        alt="Neslihan Yazlak" />
                    <p>Neslihan Yazlak</p>
                    <small>Content Managerin</small>
                </li>
                <li>
                    <img
                        src="assets/img/team/alex-pp.webp"
                        alt="Alexander Piwonka" />
                    <p>Alexander Piwonka</p>
                    <small>Chief Partnership Officer</small>
                </li>
                <li>
                    <img src="assets/img/team/ezgi-pp.webp" alt="Ezgi Akinci" />
                    <p>Ezgi Akinci</p>
                    <small>Content Managerin</small>
                </li>
                <li>
                    <img
                        src="assets/img/team/avdy-pp.webp"
                        alt="Avdyrahman Agajykov" />
                    <p>Avdyrahman Agajykov</p>
                    <small>Dualer Student IT</small>
                </li>
                <li>
                    <img
                        src="assets/img/team/leo-pp.webp"
                        alt="Leonardo Montesdeoca" />
                    <p>Leonardo Montesdeoca</p>
                    <small>Werkstudent</small>
                </li>
            </ul>
        </div>
    </div>
</section>

<section class="carreer">
    <div class="container">
        <span class="subtitle">KARRIERE</span>
        <h2 #reveal>
            Gemeinsam erfolgreich bewegen<br>Deine Karriereschritte bei Mobility
            Ads
        </h2>
        <app-bento #bento>
            <app-bento-item col="6" row="1" direction="left">
                <div class="bento__item-icon-wrapper">
                    <span class="icon">
                        <img
                            src="assets/img/icons/mail-send.svg"
                            alt="Bewerbung einreichen" />
                    </span>
                </div>
                <span class="emphasized">Bewerbung einreichen</span>
                <p>
                    Sende uns deinen Lebenslauf und ein aussagekräftiges
                    Anschreiben an unsere Kontaktadresse.
                </p>
            </app-bento-item>
            <app-bento-item col="6" row="1" direction="right">
                <div class="bento__item-icon-wrapper">
                    <span class="icon">
                        <img
                            src="assets/img/icons/video-call.svg"
                            alt="Erstes Kennenlernen" />
                    </span>
                </div>
                <span class="emphasized">Erstes Kennenlernen</span>
                <p>
                    Lass uns in einem Telefonat oder Videocall mehr über dich
                    und deine Motivation erfahren.
                </p>
            </app-bento-item>
            <app-bento-item col="6" row="1" direction="left">
                <div class="bento__item-icon-wrapper">
                    <span class="icon">
                        <img
                            src="assets/img/icons/comment.svg"
                            alt="Zeitnahe Rückmeldung" />
                    </span>
                </div>
                <span class="emphasized">Zeitnahe Rückmeldung</span>
                <p>
                    Wir sichten alle Kandidaten und melden uns bei dir, sobald
                    wir eine Entscheidung getroffen haben.
                </p>
            </app-bento-item>
            <app-bento-item col="6" row="1" direction="right">
                <div class="bento__item-icon-wrapper">
                    <span class="icon">
                        <img
                            src="assets/img/icons/team-add.svg"
                            alt="Willkommen im Team" />
                    </span>
                </div>
                <span class="emphasized">Willkommen im Team</span>
                <p>
                    Sobald wir uns für dich entschieden haben, erhältst du ein
                    Angebot und du bist Teil unseres dynamischen Teams.
                </p>
            </app-bento-item>
        </app-bento>
        <br />
        <app-bento #bento class="plain">
            <app-bento-item col="12" row="1" direction="middle">
                <ul class="qa-list">
                    <li>
                        <span class="question">
                            Dein neues Umfeld: Dynamisch, digital, familiär!
                            Einfach dein Style!
                        </span>
                        <span class="answer">
                            Du arbeitest mitten in Berlin in unserem Büro mit
                            uns oder auch Remote. Mit unserer
                            Check-in/Check-out-Kultur ist Flexibilität
                            vorprogrammiert. Du liebst Mac oder Windows – kein
                            Problem. Mach´s wie´s dir passt. Nutze Tools wie
                            Slack, Monday.com, KiwiHR oder OnePassword.
                        </span>
                    </li>
                    <li>
                        <span class="question">
                            Was wir dir bieten: Mehr als nur einen Job
                        </span>
                        <span class="answer">
                            Du wirst Teil eines jungen, dynamischen Teams, das
                            sich kontinuierlich weiterentwickelt. Freu dich auf
                            den Austausch mit Experten in ihrem Bereich,
                            spannende Projekte und Produkte, die eine ganze
                            Branche revolutionieren – und das alles in einem
                            Umfeld, das Wert auf eine positive und fördernde
                            Atmosphäre legt. Du findest hier immer Raum für
                            innovative Ideen und schnelle Weiterentwicklung.
                        </span>
                    </li>
                    <li>
                        <span class="question">
                            Worauf wir Wert legen: Deine Persönlichkeit zählt!
                        </span>
                        <span class="answer">
                            Transparenz ist unser Motto – ob im
                            Projektmanagement oder im CRM über Monday.com. Du
                            übernimmst Verantwortung, hältst Deadlines ein und
                            formulierst deine Bedürfnisse klar und direkt. Wir
                            vertrauen auf dein Wort.
                        </span>
                    </li>
                    <li>
                        <span class="question">
                            Hol dir den Drive und gestalte die Zukunft mit uns!
                        </span>
                        <span class="answer">
                            Schau dir unsere aktuellen Stellen an – und lass uns
                            gemeinsam durchstarten. Wir freuen uns, von dir zu
                            hören!
                        </span>
                    </li>
                </ul>
            </app-bento-item>
        </app-bento>
    </div>
</section>

<section id="testimonials" class="quote customers">
    <div class="container">
        <span class="subtitle">FEEDBACK</span>
        <h2 #reveal>Was sagt unser Team über uns?</h2>
        <app-bento #bento>
            <app-bento-item col="6" row="1" direction="left">
                <div class="quote__customers">
                    <div class="quote__customers-img">
                        <img
                            src="assets/img/team/chris-pp.webp"
                            alt="Christopher Wagner" />
                        <div class="quote__customers-author">
                            <p>Christopher Wagner</p>
                            <small>Chief Technical Officer, Mobility Ads</small>
                        </div>
                    </div>
                    <div class="quote__customers-text">
                        <p>
                            "Die Arbeit bei Mobility Ads war eine unglaubliche
                            Erfahrung - ich hatte die Möglichkeit, zu
                            innovativen Ad-Tech-Lösungen beizutragen, die die
                            Branche neu gestalten. Die Leidenschaft und der
                            kollaborative Geist des Teams machen jeden Tag
                            spannend.“
                        </p>
                        <br />
                    </div>
                </div>
            </app-bento-item>
            <app-bento-item col="6" row="1" direction="right">
                <div class="quote__customers">
                    <div class="quote__customers-img">
                        <img
                            src="assets/img/team/turgut-pp.webp"
                            alt="Turgut Ökten" />
                        <div class="quote__customers-author">
                            <p>Turgut Ökten</p>
                            <small>Head of Content, Mobility Ads</small>
                        </div>
                    </div>
                    <div class="quote__customers-text">
                        <p>
                            “Bei Mobility Ads bin ich ständig gefordert,
                            innovativ zu sein und die Grenzen dessen, was in der
                            digitalen Werbung möglich ist, zu erweitern. Es ist
                            lohnend, Teil eines schnell wachsenden Startups mit
                            einer so klaren Vision für die Zukunft zu sein.”
                        </p>
                        <br />
                    </div>
                </div>
            </app-bento-item>
        </app-bento>
    </div>
</section>

<section class="banner">
    <div class="container">
        <span class="subtitle">Dein Mobility Ads Team</span>
        <h2>
            Starte jetzt durch!
            <br />
            <span class="gradient publisher">
                Wir freuen uns von dir zu hören!
            </span>
        </h2>
        <div class="buttons">
            <button [routerLink]="['/kontakt']" class="button-2 secondary">
                Bewirb dich jetzt gleich!
                <figure class="arrow"></figure>
            </button>
        </div>
    </div>
</section>
