import { Component, Input } from '@angular/core'

@Component({
    selector: 'app-bento-item',
    standalone: true,
    imports: [],
    templateUrl: './bento-item.component.html',
    styleUrl: './bento-item.component.scss',
})
export class BentoItemComponent {
    @Input()
    direction: 'left' | 'middle' | 'right' | 'middle-low' = 'left'

    @Input()
    col: '1' | '2' | '3' | '4' | '5' | '6' | '7' | '8' | '9' | '10' | '11' | '12' = '12'

    @Input()
    row: '1' | '2' | '3' | '4' | '5' | '6' = '3'
}
