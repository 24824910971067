<app-hero page="autohandler"></app-hero>

<section class="quote">
    <div class="container">
        <app-bento #bento>
            <app-bento-item col="12" row="2" direction="middle" class="quote">
                <div class="quote__ceo">
                    <div class="quote__ceo-img">
                        <img
                            src="assets/img/team/fatih-pp.webp"
                            alt="Fatih Özcan" />
                    </div>
                    <div class="quote__ceo-content">
                        <div class="quote__ceo-text">
                            <p>
                                Bei Mobility Ads verstehen wir die
                                Herausforderungen der Autohändler. Unsere
                                Mission ist es, Autohäusern dabei zu helfen,
                                ihre Fahrzeugbestände schneller zu vermarkten
                                und neue Leads über exklusive digitale Kanäle zu
                                gewinnen. Dabei setzen wir auf Transparenz,
                                Innovationskraft und maßgeschneiderte
                                Strategien, um den zukünftigen Erfolg zu
                                sichern.
                            </p>
                        </div>
                        <div class="quote__ceo-author">
                            <span>Fatih Özcan</span>
                            <small>Geschäftsführer, Mobility Ads</small>
                        </div>
                    </div>
                </div>
            </app-bento-item>
        </app-bento>
    </div>
</section>

<section class="problem">
    <div class="container">
        <div class="problem__grid">
            <span class="subtitle">DIGITALE TRANSFORMATION</span>
            <h2 #reveal>Vollgas gegeben, aber das Ziel nicht erreicht?</h2>
            <p>
                Stolz auf Ihr Autohaus? Eigentlich sollte es viel besser laufen.
            </p>
            <p class="question-i">
                <b>Das Problem:</b>
                Top Autos sind verfügbar, aber die Kunden bleiben aus.
            </p>
            <app-bento #bento class="plain">
                <app-bento-item col="8" row="1" direction="left">
                    <div class="col-12">
                        <ul class="qa-list">
                            <li>
                                <span class="question">
                                    Digital aufstellen – Aber wie?
                                </span>
                                <span class="answer">
                                    Die Transformation ins digitale Zeitalter
                                    fällt oft schwer. Ihr Marketing ist noch von
                                    früher. Leider verhindert Ihre schwache
                                    Online-Präsenz, dass Sie Ihre Zielgruppe
                                    erreichen.
                                </span>
                            </li>
                            <li>
                                <span class="question">
                                    Kaum Sichtbarkeit im digitalen Verkehr
                                </span>
                                <span class="answer">
                                    Im Dschungel der großen Plattformen gehen
                                    kleine Autohändler oft unter. Es fehlt die
                                    richtige Sichtbarkeit-Strategie!
                                </span>
                            </li>
                            <li>
                                <span class="question">
                                    Hohe Kosten – Wenig Neukunden?
                                </span>
                                <span class="answer">
                                    Teure Werbekampagnen bleiben oft
                                    wirkungslos. Die Investitionen bringen nicht
                                    die erwarteten Verkäufe.
                                </span>
                            </li>
                            <li>
                                <span class="question">
                                    Volle Ausstellungsräume, aber kaum Umsatz
                                </span>
                                <span class="answer">
                                    Lange Standzeiten kosten bares Geld. Es
                                    fehlt eine klare Strategie, um den
                                    Fahrzeugverkauf zu beschleunigen.
                                </span>
                            </li>
                            <li>
                                <span class="question">
                                    Abhängigkeit von Großportalen?
                                </span>
                                <span class="answer">
                                    Teure Plattformen und hohe Gebühren belasten
                                    die Margen. Sie kämpfen gegen viele andere
                                    an – Magere Buchungen für den ganzen
                                    Aufwand.
                                </span>
                            </li>
                        </ul>
                    </div>
                </app-bento-item>
                <app-bento-item
                    col="4"
                    row="1"
                    direction="right"
                    class="hide-on-mobile">
                    <div
                        class="img-glow"
                        style="
                            background-image: url('assets/img/cars/car-cloudy.webp');
                        "></div>
                </app-bento-item>
            </app-bento>
            <p class="answer-i"><b>Das muss nicht sein!</b></p>
            <div class="d-flex">
                <div class="col-10">
                    <p>
                        Wir bei Mobility Ads verstehen Sie zu 100 %. Deshalb
                        bieten wir Ihnen einen digitalen Full-Service an. Werden
                        Sie einer der Big Player auf dem Automobil-Markt.
                    </p>
                </div>
            </div>
            <button class="button-2 reverse" [routerLink]="['/kontakt']">
                Jetzt digitale Beschleunigung starten
                <figure class="arrow"></figure>
            </button>
        </div>
    </div>
</section>

<section class="info-box">
    <div class="container">
        <div class="autohandler">
            <div class="">
                <h3 class="gradient autohandler">
                    Von offline zu online – zukunftssicher unterwegs!
                </h3>
                <p class="m-0">
                    Mit Mobility Ads die Weichen neu stellen – für mehr
                    Sichtbarkeit, mehr Kunden und Ihren nachhaltigen Erfolg.
                </p>
            </div>
        </div>
    </div>
</section>

<section class="sector">
    <div class="container">
        <div class="sector__grid">
            <span class="subtitle">SICHTBARKEIT MAXIMIEREN</span>
            <h2 #reveal>
                Lassen Sie den Wettbewerb im Rückspiegel – mit Mobility Ads auf
                der Überholspur
            </h2>
            <p class="info-i">
                Was sich die meisten Autohäuser und Autohausbesitzer wünschen?
            </p>
            <div class="sector__grid-right">
                <app-bento #bento class="plain">
                    <app-bento-item col="8" row="1" direction="left">
                        <div class="col-12">
                            <ul class="qa-list">
                                <li>
                                    <span class="question">
                                        Mehr Leads – mehr Umsatz
                                    </span>
                                    <span class="answer">
                                        Erhalten Sie hochwertige Anfragen direkt
                                        auf Ihre digitalen Kanäle und steigern
                                        Sie Ihren Fahrzeugabsatz.
                                    </span>
                                </li>
                                <li>
                                    <span class="question">
                                        Exklusive Präsenz in Top-Leitmedien
                                    </span>
                                    <span class="answer">
                                        Platzieren Sie Ihre Angebote in
                                        reichweitenstarken und relevanten
                                        Portalen, um Ihre Zielgruppe bestmöglich
                                        zu erreichen.
                                    </span>
                                </li>
                                <li>
                                    <span class="question">
                                        Maximale Sichtbarkeit für Ihre Marke
                                    </span>
                                    <span class="answer">
                                        Setzen Sie Ihr Autohaus in Szene – mit
                                        maßgeschneiderten Kampagnen, die Ihre
                                        Marke stärken und Sie von der Konkurrenz
                                        abheben.
                                    </span>
                                </li>
                                <li>
                                    <span class="question">
                                        Einfache Umsetzung & klare Erfolge
                                    </span>
                                    <span class="answer">
                                        Unsere Experten begleiten Sie von Anfang
                                        an und stehen Ihnen bei der
                                        Strategieentwicklung,
                                        Kampagnenoptimierung und Erfolgsmessung
                                        zur Seite.
                                    </span>
                                </li>
                            </ul>
                        </div>
                    </app-bento-item>
                    <app-bento-item
                        col="4"
                        row="1"
                        direction="right"
                        class="hide-on-mobile">
                        <div
                            class="img-glow"
                            style="
                                background-image: url('assets/img/cars/car-rear-mirror.webp');
                            "></div>
                    </app-bento-item>
                </app-bento>
                <hr />
                <button class="button-2 primary" [routerLink]="['/kontakt']">
                    Jetzt digitale Beschleunigung starten
                    <figure class="arrow"></figure>
                </button>
            </div>
        </div>
    </div>
</section>

<section class="online-presence">
    <div class="container">
        <span class="subtitle">ERFOLG, DER VERBINDET</span>
        <h2 #reveal>Ihr Weg zur reichweitenstarken Online-Präsenz</h2>
        <div class="d-flex">
            <div class="col-3">
                <div class="step-box__header">
                    <div class="step-box__icon">
                        <span class="icon">
                            <img
                                src="assets/img/icons/support.svg"
                                alt="Einstieg & Beratung" />
                        </span>
                        <span class="emphasized">Schritt 1</span>
                    </div>
                </div>
                <div class="step-box">
                    <div class="step-box__content">
                        <span class="emphasized">Einstieg & Beratung</span>
                        <p>
                            Kontaktieren Sie uns für eine persönliche Beratung.
                            Gemeinsam entwickeln wir die passende Strategie für
                            Ihr Autohaus.
                        </p>
                    </div>
                </div>
            </div>
            <div class="col-3">
                <div class="step-box__header">
                    <div class="step-box__icon">
                        <span class="icon">
                            <img
                                src="assets/img/icons/access.svg"
                                alt="Anmeldung & Zugang" />
                        </span>
                        <span class="emphasized">Schritt 2</span>
                    </div>
                </div>
                <div class="step-box">
                    <div class="step-box__content">
                        <span class="emphasized">Anmeldung & Zugang</span>
                        <p>
                            Einfach registrieren und sofort auf exklusive
                            Angebote im Mobility Ads Partnerportal zugreifen.
                        </p>
                    </div>
                </div>
            </div>
            <div class="col-3">
                <div class="step-box__header">
                    <div class="step-box__icon">
                        <span class="icon">
                            <img
                                src="assets/img/icons/cursor.svg"
                                alt="Werbeanzeigen starten & Performance steigern" />
                        </span>
                        <span class="emphasized">Schritt 3</span>
                    </div>
                </div>
                <div class="step-box">
                    <div class="step-box__content">
                        <span class="emphasized">
                            Werbeanzeigen starten & Performance steigern
                        </span>
                        <p>
                            Starten Sie Ihre Automobil-Anzeigen, verfolgen Sie
                            Ihre Klickrate live und reagieren Sie flexibel auf
                            Veränderungen.
                        </p>
                    </div>
                </div>
            </div>
            <div class="col-3">
                <div class="step-box__header">
                    <div class="step-box__icon">
                        <span class="icon">
                            <img
                                src="assets/img/icons/chart-up.svg"
                                alt="Ergebnisanalyse & kontinuierliches Wachstum" />
                        </span>
                        <span class="emphasized">Schritt 4</span>
                    </div>
                </div>
                <div class="step-box">
                    <div class="step-box__content">
                        <span class="emphasized">
                            Ergebnisanalyse & kontinuierliches Wachstum
                        </span>
                        <p>
                            Nutzen Sie unsere Tools, um Umsätze zu steigern und
                            Ihren Erfolg kontinuierlich zu optimieren. Wir
                            unterstützen Sie dabei!
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="sector">
    <div class="container">
        <span class="subtitle">DIGITALE POLE-POSITION</span>
        <h2 #reveal>
            Von Null auf Hundert im digitalen Verkauf.
            <br />
            Mit Mobility Ads als Ihr digitaler Umsatzbeschleuniger!
        </h2>
        <p class="answer-i">Ihr Autohandel wirkt ab sofort auch online!</p>
        <p>
            Mobility Ads setzt effektive Online-Strategien ein, die Ihnen Kunden
            bringen.
        </p>
        <app-bento #bento>
            <app-bento-item col="4" row="1" direction="left">
                <div class="bento__item-icon-wrapper">
                    <span class="icon">
                        <img src="assets/img/icons/exclusive.svg" alt="" />
                    </span>
                </div>
                <span class="emphasized">
                    Exklusive Programme für Ihren Fahrzeugbestand
                </span>
                <p>
                    Lagern Sie Ihr Online-Marketing an uns aus und profitieren
                    Sie von unserer Plattform mit großer Reichweite – ideal für
                    die professionelle Vermarktung Ihrer Fahrzeuge.
                </p>
            </app-bento-item>
            <app-bento-item col="4" row="1" direction="middle">
                <div class="bento__item-icon-wrapper">
                    <span class="icon">
                        <img src="assets/img/icons/mentoring.svg" alt="" />
                    </span>
                </div>
                <span class="emphasized">
                    Maßgeschneiderte Lösungen für maximale Präsenz
                </span>
                <p>
                    Unsere Experten erstellen individuelle Inhalte und
                    Vermarktungsseiten für Ihre Fahrzeuge. Sie erhalten
                    technischen Support und effektive Werbemaßnahmen, um
                    sichtbar zu bleiben.
                </p>
            </app-bento-item>
            <app-bento-item col="4" row="1" direction="right">
                <div class="bento__item-icon-wrapper">
                    <span class="icon">
                        <img src="assets/img/icons/target.svg" alt="" />
                    </span>
                </div>
                <span class="emphasized">
                    Wir kennen die Herausforderungen der Branche – und haben
                    Lösungen
                </span>
                <p>
                    Für kleine und mittelständische Autohändler ist es
                    schwierig, im digitalen Markt zu bestehen. Durch die
                    prominente Platzierung Ihrer Angebote auf unserer Plattform
                    erhalten Sie die Reichweite, die Ihnen bisher gefehlt hat.
                </p>
            </app-bento-item>
        </app-bento>
        <hr />
        <button [routerLink]="['/kontakt']" class="button-2 reverse">
            Jetzt digitale Beschleunigung starten!
            <figure class="arrow"></figure>
        </button>
    </div>
</section>

<section id="testimonials" class="quote customers">
    <div class="container">
        <span class="subtitle">FEEDBACK</span>
        <h2 #reveal>Was sagen Kunden über uns?</h2>
        <app-bento #bento>
            <app-bento-item col="4" row="1" direction="left">
                <div class="quote__customers">
                    <div class="quote__customers-img">
                        <img
                            src="assets/img/customers/huy-hoang.webp"
                            alt="Huy Hoang" />
                        <div class="quote__customers-author">
                            <p>Huy Hoang</p>
                            <small>Partner Manager, 6Minutes Media GmbH</small>
                        </div>
                    </div>
                    <div class="quote__customers-text">
                        <p>
                            "Wir freuen uns darüber mit der Mobility-Ads GmbH zu
                            kooperieren, so können wir unseren Nutzern spannende
                            Deals präsentieren und sorgen gleichzeitig für eine
                            stetige Monetarisierung.“
                        </p>
                        <br />
                    </div>
                </div>
            </app-bento-item>
            <app-bento-item col="4" row="1" direction="middle">
                <div class="quote__customers">
                    <div class="quote__customers-img">
                        <img
                            src="assets/img/customers/marc-majewski.webp"
                            alt="Marc Majewski" />
                        <div class="quote__customers-author">
                            <p>Marc Majewski</p>
                            <small>Geschäftsführer, Advanced Store GmbH</small>
                        </div>
                    </div>
                    <div class="quote__customers-text">
                        <p>
                            “Wir konnten durch die Mobility-Ads GmbH unsere
                            Wachstumsstrategie für den Bereich der Mobilität
                            zusätzlich verstärken und beschleunigen.”
                        </p>
                        <br />
                    </div>
                </div>
            </app-bento-item>
            <app-bento-item col="4" row="1" direction="right">
                <div class="quote__customers">
                    <div class="quote__customers-img">
                        <img
                            src="assets/img/customers/julian-melzig.webp"
                            alt="Julian Melzig" />
                        <div class="quote__customers-author">
                            <p>Julian Melzig</p>
                            <small>Geschäftsführer, Wink UG</small>
                        </div>
                    </div>
                    <div class="quote__customers-text">
                        <p>
                            “Durch die Partnerschaft mit der Mobility-Ads GmbH
                            ist es uns möglich die Abhängigkeit zu einzelnen
                            Anbietern zu reduzieren und unseren Nutzern ein noch
                            breiteres Angebot zu präsentieren.”
                        </p>
                        <br />
                    </div>
                </div>
            </app-bento-item>
        </app-bento>
    </div>
</section>

<section class="quote ceo">
    <div class="container">
        <app-bento #bento>
            <app-bento-item col="12" row="2" direction="middle">
                <div class="quote__ceo">
                    <div class="quote__ceo-img">
                        <img
                            src="assets/img/team/fatih-pp.webp"
                            alt="Fatih Özcan" />
                    </div>
                    <div class="quote__ceo-content">
                        <div class="quote__ceo-link hide-on-desktop">
                            <a
                                [routerLink]="['/uber-uns']"
                                class="link">
                                Lernen Sie unser Team kennen
                                <span class="arrow"></span>
                            </a>
                        </div>
                        <div class="quote__ceo-text">
                            <p>
                                "Bei Mobility Ads unterstützen wir Autohändler
                                dabei, ihre Fahrzeuge gezielt online zu
                                platzieren und Käufer direkt anzusprechen.
                                Unsere Plattform bringt den Fahrzeugverkauf auf
                                die nächste Stufe – transparent, effektiv und
                                erfolgsorientiert."
                            </p>
                        </div>
                        <div class="quote__ceo-author">
                            <span>Fatih Özcan</span>
                            <small>Geschäftsführer, Mobility Ads</small>
                        </div>
                        <div class="quote__ceo-link hide-on-mobile">
                            <a
                                [routerLink]="['/uber-uns']"
                                class="link">
                                Lernen Sie unser Team kennen
                                <span class="arrow"></span>
                            </a>
                        </div>
                    </div>
                </div>
            </app-bento-item>
        </app-bento>
    </div>
</section>

<section class="faq">
    <div class="container">
        <div class="d-flex">
            <div class="col-4">
                <span class="subtitle">FAQ</span>
                <h3 #reveal>Offene Fragen finden hier ihre Antworten</h3>
            </div>
            <div class="col-8">
                <ul class="faq-list">
                    @for (faq of faqs; track $index; let i = $index) {
                        <li
                            (click)="onToggleFaq(i)"
                            [class.expanded]="expandedFaqIndex === i">
                            <span class="question">{{ faq.question }}</span>
                            <span class="answer">{{ faq.answer }}</span>
                            <span class="icon"></span>
                        </li>
                    }
                </ul>
            </div>
        </div>
    </div>
</section>

<section class="banner">
    <div class="container">
        <h2>
            Von Null auf Hundert im digitalen Verkauf –
            <br />
            <span class="gradient autohandler">
                Mobility Ads gibt Ihnen Rückenwind
            </span>
        </h2>
        <p>
            Effizient, innovativ, zukunftssicher. Mit Mobility Ads heben Sie
            sich vom Wettbewerb ab und erreichen Ihre Kunden dort, wo sie gerade
            suchen: online.
        </p>
        <div class="buttons">
            <button [routerLink]="['/kontakt']" class="button-2 primary">
                Jetzt digitale Beschleunigung starten!
                <figure class="arrow"></figure>
            </button>
        </div>
    </div>
</section>
