import { Component, ViewEncapsulation, OnInit } from '@angular/core'
import { ActivatedRoute, Router, RouterLink } from '@angular/router'
import { ContactRequest } from '../contact/contact.component'

@Component({
    selector: 'app-contact-success',
    templateUrl: './contact-success.component.html',
    styleUrls: ['./contact-success.component.scss'],
    encapsulation: ViewEncapsulation.None,
    standalone: true,
    imports: [RouterLink],
})
export class ContactSuccessComponent {
    contactRequest: ContactRequest | null = null

    constructor(
        private router: Router
    ) {
        const navigation = this.router.getCurrentNavigation();
        this.contactRequest = navigation?.extras.state?.['contactRequest'];

        if (!this.contactRequest) {
            this.router.navigate(['404']);
        }
    }
}
