import { Component, ViewEncapsulation } from '@angular/core'

@Component({
    selector: 'app-imprint',
    templateUrl: './imprint.component.html',
    styleUrl: './imprint.component.scss',
    encapsulation: ViewEncapsulation.None,
    standalone: true,
    imports: [],
})
export class ImprintComponent {}
