import { Component, ElementRef, PLATFORM_ID, Inject, QueryList, ViewChildren, ViewEncapsulation } from '@angular/core'
import { HeroComponent } from '../../core/components/hero/hero.component'
import { BentoComponent, BentoItem } from '../../core/components/bento/bento.component'
import { BentoItemComponent } from '../../core/components/bento/bento-item/bento-item.component'
import { RouterLink } from '@angular/router'

import { isPlatformBrowser } from '@angular/common'
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import { AnimationUtils } from '../../core/utils/animation.utils'
import { Title } from '@angular/platform-browser'
@Component({
    selector: 'app-publisher',
    templateUrl: './publisher.component.html',
    styleUrl: './publisher.component.scss',
    encapsulation: ViewEncapsulation.None,
    standalone: true,
    imports: [HeroComponent, BentoComponent, BentoItemComponent, RouterLink],
})
export class PublisherComponent {
    @ViewChildren('reveal') revealTexts!: QueryList<ElementRef>
    @ViewChildren('bento', { read: ElementRef }) bentos!: QueryList<ElementRef>

    constructor(
        @Inject(PLATFORM_ID) private platformId: Object,
        private animationUtils: AnimationUtils,
        private title: Title,
    ) {}

    faqs = [
        {
            question: 'Wie finde ich das passende Partnerprogramm für mein Geschäft?',
            answer: 'Unser Mobility Ads-Team unterstützt Sie dabei, die bestmöglichen Programme zu finden, die zu Ihrem Geschäftsbereich passen.',
        },
        {
            question: 'Wie kann ich sicherstellen, dass ich immer die neuesten Angebote erhalte?',
            answer: 'Unser Service hält Sie stets auf dem Laufenden über relevante, neue und exklusive Angebote, die zu Ihrem Portfolio passen.',
        },
        {
            question: 'Welche Vorteile bietet mir die Zusammenarbeit mit Mobility Ads?',
            answer: 'Sie profitieren von unserem Expertenwissen, exklusiven Partnerschaften und maßgeschneiderten Strategien, die Ihre Reichweite monetarisieren und Ihren Umsatz steigern.',
        },
        {
            question: 'Wie unterstützt Mobility Ads Händler bei der Digitalisierung?',
            answer: 'Wir begleiten Sie Schritt für Schritt beim Übergang zur digitalen Vermarktung, von der Auswahl der Kanäle bis zur erfolgreichen Integration in Ihr Geschäft.',
        },
        {
            question: 'Wie analysiere ich die Performance meiner Kampagne?',
            answer: 'Mit unseren Analysewerkzeugen erhalten Sie genaue Performance-Auswertungen Ihrer Kampagnen und können so kontinuierlich Optimierungen vornehmen.',
        },
    ]

    expandedFaqIndex: number | null = null

    onToggleFaq(index: number) {
        this.expandedFaqIndex = this.expandedFaqIndex === index ? null : index
    }

    ngOnInit(): void {
        this.title.setTitle('Traffic monetarisieren – Mobility Ads, Ihr Online-Partner');
    }

    ngAfterViewInit(): void {
        if (isPlatformBrowser(this.platformId)) {
            gsap.registerPlugin(ScrollTrigger)
            this.animationUtils.revealText(this.revealTexts)
            this.animationUtils.animateBento(this.bentos)
            this.animationUtils.slideCar()
        }
    }
}
