import { ApplicationConfig } from '@angular/core'
import {
    InMemoryScrollingFeature,
    InMemoryScrollingOptions,
    provideRouter,
    withInMemoryScrolling,
    withViewTransitions,
} from '@angular/router'
import { routes } from './app.routes'
import { provideHttpClient, withFetch } from '@angular/common/http'

const scrollConfig: InMemoryScrollingOptions = {
    scrollPositionRestoration: 'top',
    anchorScrolling: 'enabled',
}

const inMemoryScrollingFeature: InMemoryScrollingFeature =
    withInMemoryScrolling(scrollConfig)

export const APP_CONFIG: ApplicationConfig = {
    providers: [
        provideHttpClient(withFetch()),
        provideRouter(
            routes,
            inMemoryScrollingFeature
        ),
    ],
}
