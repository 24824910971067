import { Component, ElementRef, QueryList, ViewChildren } from '@angular/core'

import SplitType from 'split-type'
import { gsap } from 'gsap'

@Component({
    template: '',
})
export class AnimationUtils {
    revealText(texts: QueryList<ElementRef>) {
        texts.forEach((revealElem: ElementRef) => {
            const element = revealElem.nativeElement as HTMLElement

            const bg = '#8792a4'
            const fg = '#e0e3e7'

            const text = new SplitType(element, { types: 'words,chars' })

            gsap.from(text.chars, {
                opacity: 0,
                duration: 0.3,
                stagger: 0.03,
                translateY: '-50%',
                ease: 'power2.out',
                scrollTrigger: {
                    trigger: element,
                    start: 'top 150%',
                    end: 'top 20%',
                    scrub: true,
                    markers: false,
                    toggleActions: 'play play reverse reverse',
                },
            })
        })
    }

    moveHelix() {
        gsap.to('.helix', {
            ease: 'power2.easeInOut',
            duration: 3,
            stagger: 0.03,
            translateY: '200%',
            scrollTrigger: {
                trigger: '.hero',
                start: 'top top',
                end: '+=1000px',
                markers: false,
                scrub: true,
            },
        })
    }

    // moveCog() {
    //     gsap.to('.cog', {
    //         ease: 'power2.easeInOut',
    //         duration: 3,
    //         stagger: 0.03,
    //         translateY: '-120%',
    //         scrollTrigger: {
    //             trigger: '.hero',
    //             start: 'top top',
    //             end: '+=1000px',
    //             markers: false,
    //             scrub: true,
    //         },
    //     })
    // }

    // animateSpeedoMeter(preFooter: QueryList<ElementRef>) {
    //     gsap.to('.speedometer', {
    //         translateY: '400%',
    //         scrollTrigger: {
    //             markers: true,
    //             scrub: true,
    //             trigger: '.hero',
    //             start: 'top top',
    //             end: '+=1000px',
    //         },
    //     })

    //     preFooter.forEach((preFooterElem: ElementRef) => {
    //         const element = preFooterElem.nativeElement as HTMLElement
    //         gsap.to('.speedometer__arrow', {
    //             rotate: 180,
    //             scrollTrigger: {
    //                 markers: false,
    //                 scrub: true,
    //                 trigger: '.hero',
    //                 start: 'top top',
    //                 end: element.offsetTop - element.offsetHeight + 'px',
    //             },
    //         })
    //     })
    // }

    animateSpeedoMeter(preFooter: QueryList<ElementRef>) {
        preFooter.forEach((preFooterElem: ElementRef) => {
            const element = preFooterElem.nativeElement as HTMLElement

            gsap.to('.speedometer__button-wrapper', {
                // zoom: '2.25',
                scrollTrigger: {
                    markers: false,
                    scrub: true,
                    trigger: '.hero',
                    start: 'top top',
                    end: '+=1000px',
                },
            })

            gsap.to('.speedometer__arrow', {
                rotate: 60,
                scrollTrigger: {
                    markers: false,
                    scrub: true,
                    trigger: '.hero',
                    start: 'top top',
                    end: '+=1000px',
                },
            })
        })
    }

    animateTeam(team: QueryList<ElementRef>) {
        team.forEach((revealElem: ElementRef) => {
            const element = revealElem.nativeElement as HTMLElement

            gsap.fromTo(
                element.querySelectorAll('li'),
                {
                    opacity: 0,
                    filter: 'blur(5px)',
                    xPercent: -100,
                },
                {
                    ease: 'power2.easeInOut',
                    duration: 3,
                    opacity: 1,
                    filter: 'blur(0)',
                    stagger: 0.03,
                    xPercent: 0,
                    scrollTrigger: {
                        trigger: element,
                        start: 'top bottom',
                        end: 'top center+=200px',
                        markers: false,
                        scrub: true,
                        toggleActions: 'play play none none',
                    },
                },
            )
        })
    }

    animateBento(bentos: QueryList<ElementRef>) {
        bentos.forEach((revealElem: ElementRef) => {
            const element = revealElem.nativeElement as HTMLElement

            gsap.fromTo(
                element.querySelectorAll('app-bento-item[direction="left"]'),
                {
                    opacity: 0,
                    filter: 'blur(5px)',
                    xPercent: -50,
                },
                {
                    ease: 'power2.easeInOut',
                    duration: 3,
                    opacity: 1,
                    filter: 'blur(0)',
                    stagger: 0.03,
                    xPercent: 0,
                    scrollTrigger: {
                        trigger: element,
                        start: 'top bottom',
                        end: 'top center+=200px',
                        markers: false,
                        scrub: true,
                        toggleActions: 'play play none none',
                    },
                },
            )

            gsap.fromTo(
                element.querySelectorAll('app-bento-item[direction="middle"]'),
                {
                    opacity: 0,
                    filter: 'blur(5px)',
                    yPercent: 50,
                },
                {
                    ease: 'power2.easeInOut',
                    duration: 3,
                    opacity: 1,
                    filter: 'blur(0)',
                    stagger: 0.03,
                    yPercent: 0,
                    scrollTrigger: {
                        trigger: element,
                        start: 'top bottom',
                        end: 'top center+=200px',
                        markers: false,
                        scrub: true,
                        toggleActions: 'play play none none',
                    },
                },
            )

            gsap.fromTo(
                element.querySelectorAll(
                    'app-bento-item[direction="middle-low"]',
                ),
                {
                    opacity: 0,
                    filter: 'blur(5px)',
                    yPercent: 10,
                },
                {
                    ease: 'power2.easeInOut',
                    duration: 3,
                    opacity: 1,
                    filter: 'blur(0)',
                    stagger: 0.03,
                    yPercent: 0,
                    scrollTrigger: {
                        trigger: element,
                        start: 'top bottom',
                        end: 'top center+=200px',
                        markers: false,
                        scrub: true,
                        toggleActions: 'play play none none',
                    },
                },
            )

            gsap.fromTo(
                element.querySelector('app-bento-item[direction="right"]'),
                {
                    opacity: 0,
                    filter: 'blur(5px)',
                    xPercent: 50,
                },
                {
                    ease: 'power2.easeInOut',
                    duration: 3,
                    opacity: 1,
                    filter: 'blur(0)',
                    stagger: 0.03,
                    xPercent: 0,
                    scrollTrigger: {
                        trigger: element,
                        start: 'top bottom',
                        end: 'top center+=200px',
                        markers: false,
                        scrub: true,
                        toggleActions: 'play play none none',
                    },
                },
            )
        })
    }

    slideCar() {
        var width = window.innerWidth

        gsap.to('.car', {
            x: width,
            duration: 6,
            ease: 'power2.easeInOut',
            scrollTrigger: {
                trigger: '.hero',
                start: 'top top',
                end: '+=1600px',
                markers: false,
                scrub: true,
            },
        })

        gsap.to('.car-wheel-1 img, .car-wheel-2 img', {
            rotate: 1800,
            duration: 6,
            ease: 'power2.easeInOut',
            scrollTrigger: {
                trigger: '.hero',
                start: 'top top',
                end: '+=1600px',
                markers: false,
                scrub: true,
            },
        })
    }
}
