import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { ContactRequest } from '../../pages/contact/contact.component'
import { environment } from '../../../environments/environment'
@Injectable({
    providedIn: 'root',
})
export class ContactService {
    private contactApiUrl: string = `${environment.apiURL}/channelizer/contact-requests`

    constructor(private http: HttpClient) {}

    sendContactRequest(contactRequest: ContactRequest) {
        return this.http.post(this.contactApiUrl, contactRequest)
    }
}
