<section class="not-found">
    <div class="container">
        <img src="assets/img/shape/404.webp" alt="404 Error! Page not found." />
        <br />
        <br />
        <p>UUPS! DIE VON IHNEN GESUCHTE SEITE KONNTE NICHT GEFUNDEN WERDEN.</p>
        <button class="button-2 primary" [routerLink]="'/'">
            Zurück zur Startseite
            <figure class="arrow"></figure>
        </button>
    </div>
</section>
