import {
    Component,
    ElementRef,
    EventEmitter,
    HostListener,
    inject,
    Inject,
    Output,
    ViewEncapsulation,
} from '@angular/core'
import { DOCUMENT } from '@angular/common'
import { ThemeService } from '../../services/theme.service'
import { RouterLink, RouterLinkActive } from '@angular/router'

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss'],
    encapsulation: ViewEncapsulation.None,
    standalone: true,
    imports: [RouterLink, RouterLinkActive],
})
export class HeaderComponent {
    /**
     * Reference to the window object.
     */
    private window: any

    /**
     * Event emitter for clicks outside the component.
     */
    @Output()
    readonly appClickOutside: EventEmitter<void> = new EventEmitter()

    /**
     * Flag to track if the navbar is open or closed
     */
    isDropdownOpen = false;
    isDarkMode: boolean
    isMenuOpen: boolean = false

    #theme: ThemeService = inject(ThemeService)

    constructor(
        @Inject(DOCUMENT) private document: Document,
        private elementRef: ElementRef
    ) {
        this.window = document.defaultView
        this.isDarkMode = this.#theme.isDarkThemeActive()
    }

    toggleTheme(): void {
        this.isDarkMode = !this.isDarkMode
        this.#theme.toggleTheme()
    }

    toggleMenu(): void {
        this.isMenuOpen = !this.isMenuOpen
    }

    closeMenu(): void {
        if (this.isMenuOpen) {
            this.isMenuOpen = false
        }
    }

    @HostListener('document:click', ['$event'])
    onDocumentClick(event: PointerEvent) {
        const nativeElement = this.elementRef.nativeElement
        const clickedInside = nativeElement.contains(event.target)
        if (!clickedInside) {
            this.appClickOutside.emit()
            this.isDropdownOpen = false
            this.isMenuOpen = false
        }
    }

    toggleDropdown(): void {
        this.isDropdownOpen = !this.isDropdownOpen
    }

    onLinkClick(): void {
        this.toggleDropdown()
    }
}
