import { Component, Input, ViewEncapsulation } from '@angular/core'

@Component({
    selector: 'app-scroller',
    templateUrl: './scroller.component.html',
    styleUrl: './scroller.component.scss',
    encapsulation: ViewEncapsulation.None,
    standalone: true,
})
export class ScrollerComponent {
    @Input()
    speed: 'slow' | 'fast' = 'slow'

    @Input()
    direction: 'left' | 'right' = 'left'

    @Input()
    autoScroll: boolean = true

    @Input()
    isAnimated: boolean = true

    @Input()
    items: ScrollerItem[] = []
}

export interface ScrollerItem {
    src: string
    alt: string
}
