@if (page === 'home') {
    <section class="hero hero-home">
        <!-- <img src="assets/img/shape/speedometer.svg" class="diamond helix" alt=""/> -->
        <!-- <img src="assets/img/shape/shape-tire.webp" class="tire" /> -->
        <!-- <img src="assets/img/shape/shape-cog.webp" class="dice cog" alt=""/> -->

        @if (currentTheme === 'dark') {
            <img
                src="assets/img/hero/home-hero-bg-dark.webp"
                class="hero__image-bg"
                alt="" />
        } @else {
            <img
                src="assets/img/hero/home-hero-bg.webp"
                class="hero__image-bg"
                alt="" />
        }
        <div class="container">
            <div class="hero__main">
                <h1>
                    Mit
                    <span class="speed gradient">
                        <span></span>
                        1.000 PS
                    </span>
                    auf
                    <br />
                    <span>digitalen Straßen werben!</span>
                </h1>
                <p class="muted">
                    Mobility Ads bringt Autohäuser und Publisher ins digitale
                    Rampenlicht. Generieren Sie qualifizierte Leads über
                    Online-Werbeanzeigen. Mehr Umsatz & Leads – auf einen Klick!
                </p>
                <div class="buttons">
                    <!-- <button [routerLink]="['/kontakt']" class="alternate">
                        <span>Jetzt digitale Leads gewinnen</span>
                        <span class="arrow"></span>
                    </button> -->

                    <!-- <button class="button-1" [routerLink]="['/kontakt']">
                    <span class="text">Jetzt digitale Leads gewinnen</span>
                    <span class="arrow"></span>
                  </button> -->

                    <button
                        class="button-2 primary"
                        [routerLink]="['/kontakt']">
                        <span></span>
                        <span></span>
                        <span></span>
                        <span></span>
                        Jetzt digitale Leads gewinnen
                        <figure class="arrow"></figure>
                    </button>
                </div>
                <!-- <div class="speedometer__button-wrapper">
                    <div class="speedometer__wrapper-v2">
                        <figure class="speedometer">
                            <img
                                src="assets/img/shape/speedometer-v2-base.png"
                                class="speedometer__base"
                                alt="" />
                            <img
                                src="assets/img/shape/speedometer-v2-arrow.png"
                                class="speedometer__arrow"
                                alt="" />
                        </figure>
                    </div>
                </div> -->
            </div>
        </div>

        <!-- <div class="vehicle">
            <div class="wrapper">
                <div class="car">
                    <div class="car-body">
                        <img src="assets/img/cars/car-holo-dark.webp" class="only-on-dark" alt=""/>
                        <img src="assets/img/cars/car-holo-light.webp" class="only-on-light" alt=""/>
                    </div>
                </div>
            </div>
        </div> -->

        <div class="container scroller__wrapper">
            <app-scroller [items]="partnerLogosTop"></app-scroller>
            <app-scroller
                [direction]="'right'"
                [items]="partnerLogosBottom"></app-scroller>
        </div>
    </section>
}

@if (page === 'publisher') {
    <section class="hero hero-publisher">
        @if (currentTheme === 'dark') {
            <img
                src="assets/img/hero/autohandler-hero-bg-dark.webp"
                class="hero__image-bg"
                alt="" />
        } @else {
            <img
                src="assets/img/hero/autohandler-hero-bg.webp"
                class="hero__image-bg"
                alt="" />
        }
        <div class="container">
            <div class="hero__main">
                <span class="subtitle">Relevant für Publisher</span>
                <h1>
                    Geben Sie Ihrem Website-Traffic Power und schalten Ihre
                    Werbung in den nächsten Gang.
                </h1>
                <p class="muted">
                    Mit Mobility Ads fahren Sie auf der Überholspur. Als
                    Publisher profitieren Sie von innovativen
                    Monetarisierungsstrategien, exklusiven Partnerschaften und
                    hochwertigen Automotive-Angeboten. Maximieren Sie Ihren
                    Umsatz, einfach und transparent.
                </p>
                <div class="buttons">
                    <!-- <button [routerLink]="['/kontakt']" class="secondary">
                        <span>Jetzt Publisher-Vorteile sichern</span>
                        <span class="arrow"></span>
                    </button> -->
                    <button
                        class="button-2 secondary"
                        [routerLink]="['/kontakt']">
                        <span></span>
                        <span></span>
                        <span></span>
                        <span></span>
                        Jetzt Publisher-Vorteile sichern
                        <figure class="arrow"></figure>
                    </button>
                </div>
            </div>
        </div>

        <div class="container scroller__wrapper">
            <app-scroller [items]="partnerLogosTop"></app-scroller>
        </div>
    </section>
}

@if (page === 'autohandler') {
    <section class="hero hero-autohandler">
        @if (currentTheme === 'dark') {
            <img
                src="assets/img/hero/autohandler-hero-bg-dark.webp"
                class="hero__image-bg"
                alt="" />
        } @else {
            <img
                src="assets/img/hero/autohandler-hero-bg.webp"
                class="hero__image-bg"
                alt="" />
        }
        <div class="container">
            <div class="hero__main">
                <span class="subtitle">
                    Relevant für Autohändler und Autohausbesitzer
                </span>
                <h1>
                    Vom Showroom ins Rampenlicht – Autohäuser werden jetzt
                    digital aktiv.
                </h1>
                <p class="muted">
                    Maximieren Sie Ihren Fahrzeugumschlag – reduzieren Sie
                    Lagerzeiten und Kosten, indem Sie Ihr Autohaus digital und
                    zielgerichtet vermarkten.
                </p>
                <div class="buttons">
                    <!-- <button [routerLink]="['/kontakt']" class="primary">
                        <span>Jetzt digitale Beschleunigung starten</span>
                        <span class="arrow"></span>
                    </button> -->

                    <button
                        class="button-2 primary"
                        [routerLink]="['/kontakt']">
                        <span></span>
                        <span></span>
                        <span></span>
                        <span></span>
                        Jetzt digitale Beschleunigung starten
                        <figure class="arrow"></figure>
                    </button>
                </div>
            </div>
        </div>

        <div class="container scroller__wrapper">
            <app-scroller [items]="partnerLogosBottom"></app-scroller>
        </div>
    </section>
}

@if (page === 'about') {
    <section class="hero hero-about">
        @if (currentTheme === 'dark') {
            <img
                src="assets/img/hero/autohandler-hero-bg-dark.webp"
                class="hero__image-bg"
                alt="" />
        } @else {
            <img
                src="assets/img/hero/autohandler-hero-bg.webp"
                class="hero__image-bg"
                alt="" />
        }
        <div class="container">
            <div class="hero__main">
                <h1>
                    Wir leben Mobilität – digital,
                    <br />
                    <span class="">innovativ und am Puls der Zeit.</span>
                </h1>
                <p class="muted">
                    Als erfahrenes und engagiertes Team bei Mobility Ads setzen
                    wir alles daran, Autohäuser und Partner in der digitalen
                    Welt erfolgreich zu machen.
                </p>
                <div class="buttons">
                    <button
                        [routerLink]="['/kontakt']"
                        class="button-2 reverse">
                        <span></span>
                        <span></span>
                        <span></span>
                        <span></span>
                        Werden Sie Teil unseres Teams
                        <figure class="arrow"></figure>
                    </button>
                </div>
            </div>
        </div>

        <div class="container scroller__wrapper">
            <app-scroller [items]="partnerLogosBottom"></app-scroller>
        </div>
    </section>
}
