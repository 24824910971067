<div class="scroller"
        [attr.data-speed]="speed"
        [attr.data-animated]="isAnimated"
        [attr.data-direction]="direction"
        [attr.data-auto-scroll]="autoScroll">
    <ul class="partner-logos scroller__inner item-count-{{items.length}}">

        @for (item of items; track $index) {
            <li class="item{{'-' + ($index + 1)}}"><img [src]="item.src" [alt]="item.alt"/></li>
        }
    </ul>
</div>
