import { Component, ViewEncapsulation } from '@angular/core'
import { HeaderComponent } from './core/components/header/header.component'
import { FooterComponent } from './core/components/footer/footer.component'
import {
    NavigationEnd,
    NavigationStart,
    Router,
    RouterOutlet,
} from '@angular/router'
import { RouterModule } from '@angular/router'
import { filter } from 'rxjs'
import { AnimationUtils } from './core/utils/animation.utils'
import Lenis from 'lenis'
@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    imports: [RouterOutlet, HeaderComponent, FooterComponent, RouterModule],
    encapsulation: ViewEncapsulation.None,
    providers: [AnimationUtils],
    standalone: true,
})
export class AppComponent {
    title = 'Mobility Ads'

    constructor(private router: Router) {
        if (typeof window !== 'undefined') {
            const lenis = new Lenis()
            this.initializeLenis(lenis)

            router.events
                .pipe(filter((event) => event instanceof NavigationStart))
                .subscribe((_) => {
                    lenis.stop()
                })

            router.events
                .pipe(filter((event) => event instanceof NavigationEnd))
                .subscribe((_) => {
                    lenis.start()
                })
        }
    }

    initializeLenis(lenis: Lenis) {
        const raf = (time: number) => {
            lenis.raf(time)
            requestAnimationFrame(raf)
        }

        requestAnimationFrame(raf)
    }
}
