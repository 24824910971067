import { Component, ElementRef, PLATFORM_ID, Inject, QueryList, ViewChildren, ViewEncapsulation } from '@angular/core'
import { Meta, Title } from '@angular/platform-browser'
import { HeroComponent } from '../../core/components/hero/hero.component'
import { BentoComponent } from '../../core/components/bento/bento.component'
import { BentoItemComponent } from '../../core/components/bento/bento-item/bento-item.component'
import { RouterLink } from '@angular/router'

import { isPlatformBrowser } from '@angular/common'
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import { AnimationUtils } from '../../core/utils/animation.utils'

@Component({
    selector: 'app-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.scss'],
    encapsulation: ViewEncapsulation.None,
    standalone: true,
    imports: [HeroComponent, BentoComponent, BentoItemComponent, RouterLink],
})
export class HomeComponent {
    @ViewChildren('reveal') revealTexts!: QueryList<ElementRef>
    @ViewChildren('bento', { read: ElementRef }) bentos!: QueryList<ElementRef>
    @ViewChildren('preFooter', { read: ElementRef }) preFooter!: QueryList<ElementRef>

    constructor(
        @Inject(PLATFORM_ID) private platformId: Object,
        private animationUtils: AnimationUtils,
        private title: Title,
    ) {}

    ngOnInit(): void {
        this.title.setTitle('Mobility Ads – Digitales Werben für Autohändler und Publisher');
    }

    ngAfterViewInit(): void {
        if (isPlatformBrowser(this.platformId)) {
            gsap.registerPlugin(ScrollTrigger)
            this.animationUtils.revealText(this.revealTexts)
            this.animationUtils.animateBento(this.bentos)
            // this.animationUtils.slideCar();
            // this.animationUtils.moveHelix();
            // this.animationUtils.moveCog();
            // this.animationUtils.animateSpeedoMeter(this.preFooter);
        }
    }
}
