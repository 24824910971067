<footer>
    <div class="footer__left">
        <p class="muted mb-0">
            © 2024 Mobility Ads GmbH.
        </p>
    </div>
    <div class="footer__right">
        <a [routerLink]="['/datenschutz']">Datenschutz</a>
        <a [routerLink]="['/agb']">AGB</a>
        <a [routerLink]="['/impressum']">Impressum</a>
        <span onclick="CCM.openWidget();return false;">Cookie-Einstellungen</span>
    </div>
</footer>
