import { Routes } from '@angular/router'
import { HomeComponent } from './pages/home/home.component'
import { ContactComponent } from './pages/contact/contact.component'
import { AboutComponent } from './pages/about/about.component'
import { NotFoundComponent } from './pages/not-found/not-found.component'
import { ImprintComponent } from './pages/imprint/imprint.component'
import { ProtectionComponent } from './pages/protection/protection.component'
import { AutohandlerComponent } from './pages/autohandler/autohandler.component'
import { PublisherComponent } from './pages/publisher/publisher.component'
import { AgbComponent } from './pages/agb/agb.component'
import { ContactSuccessComponent } from './pages/contact-success/contact-success.component'

export const routes: Routes = [
    { path: '', component: HomeComponent, pathMatch: 'full' },
    { path: 'uber-uns', component: AboutComponent },
    { path: 'kontakt', component: ContactComponent },
    { path: 'kontakt-erfolgreich', component: ContactSuccessComponent },
    { path: 'publisher', component: PublisherComponent },
    { path: 'autohandler', component: AutohandlerComponent },
    { path: 'impressum', component: ImprintComponent },
    { path: 'agb', component: AgbComponent },
    { path: 'datenschutz', component: ProtectionComponent },
    { path: '**', component: NotFoundComponent },
]
