import { Component, ElementRef, PLATFORM_ID, Inject, QueryList, ViewChildren, ViewEncapsulation } from '@angular/core'
import { HeroComponent } from '../../core/components/hero/hero.component'
import { BentoComponent, BentoItem } from '../../core/components/bento/bento.component'
import { BentoItemComponent } from '../../core/components/bento/bento-item/bento-item.component'
import { RouterLink } from '@angular/router'

import { isPlatformBrowser } from '@angular/common'
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import { AnimationUtils } from '../../core/utils/animation.utils'
import { Title } from '@angular/platform-browser'
@Component({
    selector: 'app-autohandler',
    templateUrl: './autohandler.component.html',
    styleUrl: './autohandler.component.scss',
    encapsulation: ViewEncapsulation.None,
    standalone: true,
    imports: [HeroComponent, BentoComponent, BentoItemComponent, RouterLink],
})
export class AutohandlerComponent {
    @ViewChildren('reveal') revealTexts!: QueryList<ElementRef>
    @ViewChildren('bento', { read: ElementRef }) bentos!: QueryList<ElementRef>

    constructor(
        @Inject(PLATFORM_ID) private platformId: Object,
        private animationUtils: AnimationUtils,
        private title: Title,
    ) {}

    faqs = [
        {
            question: 'Welche Kosten kommen auf mich zu, wenn ich Mobility Ads nutze?',
            answer: 'Unsere Preismodelle sind transparent und erfolgsorientiert. Sie zahlen nur für echte Leads, Kunden, die auf die Kampagne positiv reagiert haben. Die Parameter werden individuell vereinbart. Dafür bieten wir maßgeschneiderte Lösungen, die zu Ihrem Budget passen.',
        },
        {
            question: 'Ist die Nutzung von Mobility Ads kompliziert?',
            answer: 'Unsere Plattform ist benutzerfreundlich, und unser Expertenteam begleitet Sie Schritt für Schritt – von der Registrierung bis zur erfolgreichen Kampagne.',
        },
        {
            question: 'Wie unterstützt mich Mobility Ads nach dem Start der Kampagne?',
            answer: 'Unsere Experten stehen Ihnen während der gesamten Kampagne zur Seite, analysieren Ergebnisse kontinuierlich und passen Strategien an, um das Maximum aus Ihrer Reichweite herauszuholen.',
        },
        {
            question: 'Wie hilft mir Mobility Ads, meine Fahrzeugbestände schneller zu verkaufen?',
            answer: 'Wir setzen auf gezieltes Online-Marketing und eine maßgeschneiderte Strategie, die Ihre Zielgruppe direkt anspricht. Das bedeutet mehr qualifizierte Anfragen, kürzere Standzeiten und einen höheren Umsatz.',
        },
    ]

    expandedFaqIndex: number | null = null

    onToggleFaq(index: number) {
        this.expandedFaqIndex = this.expandedFaqIndex === index ? null : index
    }

    ngOnInit(): void {
        this.title.setTitle('Online-Vertrieb für Autohäuser – Mobility Ads, Ihr Online-Partner');
    }

    ngAfterViewInit(): void {
        if (isPlatformBrowser(this.platformId)) {
            gsap.registerPlugin(ScrollTrigger)
            this.animationUtils.revealText(this.revealTexts)
            this.animationUtils.animateBento(this.bentos)
            this.animationUtils.slideCar()
        }
    }
}
